import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "100",
  height: "127",
  viewBox: "0 0 100 127",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}
const _hoisted_2 = ["fill"]
const _hoisted_3 = ["fill"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _createElementVNode("path", {
      d: "M50 127C51.4911 127.002 52.9333 126.459 54.0625 125.47C55.9437 123.838 100 85.0709 100 50.8C100 37.327 94.7322 24.4058 85.3553 14.879C75.9785 5.35213 63.2608 0 50 0C36.7392 0 24.0215 5.35213 14.6447 14.879C5.26784 24.4058 0 37.327 0 50.8C0 85.0709 44.0563 123.838 45.9375 125.47C47.0667 126.459 48.5089 127.002 50 127Z",
      fill: _ctx.primaryColor
    }, null, 8, _hoisted_2),
    _createElementVNode("path", {
      d: "M50 76.2C54.9445 76.2 59.778 74.7103 63.8893 71.9193C68.0005 69.1283 71.2048 65.1614 73.097 60.5202C74.9892 55.8789 75.4843 50.7718 74.5196 45.8447C73.555 40.9176 71.174 36.3917 67.6777 32.8395C64.1814 29.2872 59.7268 26.8681 54.8773 25.8881C50.0277 24.908 45.0011 25.411 40.4329 27.3335C35.8648 29.2559 31.9603 32.5115 29.2133 36.6885C26.4662 40.8655 25 45.7764 25 50.8C25.0066 57.5344 27.6427 63.9911 32.3296 68.7531C37.0166 73.515 43.3716 76.1933 50 76.2Z",
      fill: _ctx.secondaryColor
    }, null, 8, _hoisted_3)
  ]))
}