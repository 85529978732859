import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString } from "vue"
import _imports_0 from '../../assets/shared/failure.svg'


const _hoisted_1 = { class: "flex flex-col min-h-screen bg-gray-100 overflow-hidden" }
const _hoisted_2 = {
  key: 0,
  class: "fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
}
const _hoisted_3 = {
  key: 1,
  class: "flex flex-grow max-md:flex-col"
}
const _hoisted_4 = { class: "flex flex-col w-1/5 max-md:ml-0 max-md:w-full bg-primary-purple" }
const _hoisted_5 = { class: "flex flex-col mt-14 flex-grow" }
const _hoisted_6 = { class: "flex flex-col w-full md:w-4/5 justify-start pt-12" }
const _hoisted_7 = { class: "flex flex-col items-start w-full gap-3 px-6 sm:px-24 text-left" }
const _hoisted_8 = { class: "flex justify-center mb-4" }
const _hoisted_9 = {
  key: 0,
  role: "alert",
  class: "flex gap-2 w-full justify-center p-2.5 mt-6 text-sm leading-5 text-center text-red-900 bg-red-50 rounded-lg max-md:flex-wrap",
  style: {"max-height":"80px","overflow-y":"auto"}
}
const _hoisted_10 = { class: "flex flex-col gap-4 mb-20 max-w-[80%] md:max-w-[70%] lg:max-w-[60%]" }
const _hoisted_11 = { class: "flex flex-wrap justify-center mt-auto items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_StepsList = _resolveComponent("StepsList")!
  const _component_ProviderAccountCard = _resolveComponent("ProviderAccountCard")!
  const _component_LeadFieldMapping = _resolveComponent("LeadFieldMapping")!
  const _component_ContactFieldMapping = _resolveComponent("ContactFieldMapping")!
  const _component_CreateNewActivityToggle = _resolveComponent("CreateNewActivityToggle")!
  const _component_CreateNewLeadOrContactToggle = _resolveComponent("CreateNewLeadOrContactToggle")!
  const _component_AddAttendeeToCampaignOrListToggle = _resolveComponent("AddAttendeeToCampaignOrListToggle")!
  const _component_FooterSection = _resolveComponent("FooterSection")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isLoading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _cache[10] || (_cache[10] = [
          _createElementVNode("div", { class: "animate-spin rounded-full h-32 w-32 border-t-4 border-b-4 border-primary-purple" }, null, -1)
        ])))
      : (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("aside", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _cache[11] || (_cache[11] = _createElementVNode("div", { class: "flex flex-col ml-10 text-left" }, [
                _createElementVNode("span", { class: "text-white text-3xl font-semibold leading-9" }, [
                  _createTextVNode(" Get Started "),
                  _createElementVNode("br")
                ])
              ], -1)),
              _createVNode(_component_StepsList, { currentStep: 4 })
            ])
          ]),
          _createElementVNode("main", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _cache[13] || (_cache[13] = _createElementVNode("div", { class: "flex flex-col mb-6" }, [
                _createElementVNode("span", { class: "text-3xl leading-9 font-semibold text-neutral-800 font-inter" }, "Track Results in Salesforce")
              ], -1)),
              _createElementVNode("div", _hoisted_8, [
                (_ctx.errorMessage)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                      _cache[12] || (_cache[12] = _createElementVNode("div", { class: "flex-shrink-0 w-6 h-6 flex items-center justify-center rounded-full" }, [
                        _createElementVNode("img", {
                          src: _imports_0,
                          alt: "Error Icon",
                          class: "w-4 h-4"
                        })
                      ], -1)),
                      _createElementVNode("p", null, _toDisplayString(_ctx.errorMessage), 1)
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _cache[14] || (_cache[14] = _createElementVNode("div", { class: "flex flex-col" }, [
                _createElementVNode("span", { class: "text-sm leading-5 font-medium text-neutral-800" }, "Sync'd Salesforce Account")
              ], -1)),
              _createVNode(_component_ProviderAccountCard, {
                accountName: _ctx.providerAccountName,
                accountEmail: _ctx.providerAccountEmail
              }, null, 8, ["accountName", "accountEmail"]),
              _createElementVNode("div", _hoisted_10, [
                _createElementVNode("div", null, [
                  _createVNode(_component_LeadFieldMapping, {
                    leadMappingFields: _ctx.leadMappingFields,
                    leadFirstName: _ctx.salesforceData.leadFirstName.value,
                    leadLastName: _ctx.salesforceData.leadLastName.value,
                    leadCompany: _ctx.salesforceData.leadCompany.value,
                    leadTitle: _ctx.salesforceData.leadTitle.value,
                    "onUpdate:leadFirstName": _cache[0] || (_cache[0] = ($event: any) => (
                  _ctx.salesforceData.leadFirstName.value = $event
                )),
                    "onUpdate:leadLastName": _cache[1] || (_cache[1] = ($event: any) => (
                  _ctx.salesforceData.leadLastName.value = $event
                )),
                    "onUpdate:leadCompany": _cache[2] || (_cache[2] = ($event: any) => (_ctx.salesforceData.leadCompany.value = $event)),
                    "onUpdate:leadTitle": _cache[3] || (_cache[3] = ($event: any) => (_ctx.salesforceData.leadTitle.value = $event))
                  }, null, 8, ["leadMappingFields", "leadFirstName", "leadLastName", "leadCompany", "leadTitle"])
                ]),
                _createElementVNode("div", null, [
                  _createVNode(_component_ContactFieldMapping, {
                    contactMappingFields: _ctx.contactMappingFields,
                    contactFirstName: _ctx.salesforceData.contactFirstName.value,
                    contactLastName: _ctx.salesforceData.contactLastName.value,
                    contactTitle: _ctx.salesforceData.contactTitle.value,
                    "onUpdate:contactFirstName": _cache[4] || (_cache[4] = ($event: any) => (
                  _ctx.salesforceData.contactFirstName.value = $event
                )),
                    "onUpdate:contactLastName": _cache[5] || (_cache[5] = ($event: any) => (
                  _ctx.salesforceData.contactLastName.value = $event
                )),
                    "onUpdate:contactTitle": _cache[6] || (_cache[6] = ($event: any) => (
                  _ctx.salesforceData.contactTitle.value = $event
                ))
                  }, null, 8, ["contactMappingFields", "contactFirstName", "contactLastName", "contactTitle"])
                ]),
                _createElementVNode("div", null, [
                  _createVNode(_component_CreateNewActivityToggle, {
                    createNewActivity: _ctx.createActivity,
                    "onUpdate:createNewActivity": _cache[7] || (_cache[7] = ($event: any) => (_ctx.createActivity = $event))
                  }, null, 8, ["createNewActivity"])
                ]),
                _createElementVNode("div", null, [
                  _createVNode(_component_CreateNewLeadOrContactToggle, {
                    type: "salesforce",
                    createNewLeadOrContact: _ctx.createLeadOrContact,
                    "onUpdate:createNewLeadOrContact": _cache[8] || (_cache[8] = ($event: any) => (_ctx.createLeadOrContact = $event))
                  }, null, 8, ["createNewLeadOrContact"])
                ]),
                _createElementVNode("div", null, [
                  _createVNode(_component_AddAttendeeToCampaignOrListToggle, {
                    ref: "addAttendeeToCampaignOrListToggleRef",
                    type: "salesforce",
                    programId: _ctx.programId,
                    campaignOrList: _ctx.salesforceCampaign,
                    addAttendeeToCampaignOrList: _ctx.addAttendeeToCampaignOrList,
                    handleErrorMessage: _ctx.handleErrorMessage,
                    "onUpdate:addAttendeeToCampaignOrList": _cache[9] || (_cache[9] = ($event: any) => (
                  _ctx.addAttendeeToCampaignOrList = $event
                ))
                  }, null, 8, ["programId", "campaignOrList", "addAttendeeToCampaignOrList", "handleErrorMessage"])
                ])
              ])
            ]),
            _createElementVNode("footer", _hoisted_11, [
              _createVNode(_component_FooterSection, {
                isDisabled: _ctx.isDisabled,
                onNext: _ctx.goNext
              }, null, 8, ["isDisabled", "onNext"])
            ])
          ])
        ]))
  ]))
}