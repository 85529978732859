import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col max-w-max border border-solid border-primary-purple bg-white rounded-lg p-0.5" }
const _hoisted_2 = { class: "flex flex-col border border-solid border-gray-300 rounded-lg p-4" }
const _hoisted_3 = { class: "flex flex-wrap gap-3" }
const _hoisted_4 = { class: "flex flex-col" }
const _hoisted_5 = { class: "flex items-center justify-center w-8 h-8 bg-blue-500 text-white rounded-full" }
const _hoisted_6 = { class: "flex flex-col text-left" }
const _hoisted_7 = { class: "text-sm leading-5 font-medium text-gray-900" }
const _hoisted_8 = { class: "text-sm leading-5 font-normal text-gray-500" }
const _hoisted_9 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.initials), 1)
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.accountName), 1),
          _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.accountEmail), 1)
        ]),
        _createElementVNode("div", {
          innerHTML: _ctx.HOLLOW_CHECK_CIRCLE,
          class: "text-primary-purple"
        }, null, 8, _hoisted_9)
      ])
    ])
  ]))
}