import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "100%",
  height: "100%",
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}
const _hoisted_2 = ["stroke"]
const _hoisted_3 = ["stroke"]
const _hoisted_4 = ["stroke"]
const _hoisted_5 = ["stroke"]
const _hoisted_6 = ["stroke"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _createElementVNode("circle", {
      cx: "12",
      cy: "12",
      r: "10",
      stroke: _ctx.primaryColor,
      "stroke-width": "2"
    }, null, 8, _hoisted_2),
    _createElementVNode("path", {
      d: "M12 2C9.34784 2 6.8043 3.05357 4.92893 4.92893C3.05357 6.8043 2 9.34784 2 12C2 14.6522 3.05357 17.1957 4.92893 19.0711C6.8043 20.9464 9.34784 22 12 22C14.6522 22 17.1957 20.9464 19.0711 19.0711C20.9464 17.1957 22 14.6522 22 12C22 9.34784 20.9464 6.8043 19.0711 4.92893C17.1957 3.05357 14.6522 2 12 2Z",
      stroke: _ctx.primaryColor,
      "stroke-width": "2"
    }, null, 8, _hoisted_3),
    _createElementVNode("path", {
      d: "M2 12H22",
      stroke: _ctx.primaryColor,
      "stroke-width": "2"
    }, null, 8, _hoisted_4),
    _createElementVNode("path", {
      d: "M12 2C14.25 4.75 15.5 8.5 15.5 12C15.5 15.5 14.25 19.25 12 22",
      stroke: _ctx.primaryColor,
      "stroke-width": "2"
    }, null, 8, _hoisted_5),
    _createElementVNode("path", {
      d: "M12 2C9.75 4.75 8.5 8.5 8.5 12C8.5 15.5 9.75 19.25 12 22",
      stroke: _ctx.primaryColor,
      "stroke-width": "2"
    }, null, 8, _hoisted_6)
  ]))
}