<template>
  <div
    v-if="isVisible"
    class="fixed inset-0 flex bg-black bg-opacity-50 items-center justify-center z-50"
  >
    <div class="bg-white rounded-lg shadow-lg p-6 max-w-md w-full relative">
      <button
        class="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
        @click="$emit('update:isVisible', false)"
      >
        ✕
      </button>
      <div class="flex flex-col gap-3">
        <div class="flex flex-col gap-1">
          <span class="text-2xl leading-8 font-medium text-gray-800"
            >Create New {{ campaignOrListText(type) }}</span
          >
        </div>
        <div class="flex justify-center">
          <div
            v-if="errorMessage"
            role="alert"
            class="flex gap-2 w-1/2 mb-4 justify-center p-2.5 mt-6 text-sm leading-5 text-center text-red-900 bg-red-50 rounded-lg max-md:flex-wrap"
            style="max-height: 80px; overflow-y: auto"
          >
            <!-- Icon Container -->
            <div
              class="flex-shrink-0 w-6 h-6 flex items-center justify-center rounded-full"
            >
              <!-- Custom Image Icon -->
              <img
                src="@/assets/shared/failure.svg"
                alt="Error Icon"
                class="w-4 h-4"
              />
            </div>
            <p>{{ errorMessage }}</p>
          </div>
        </div>
        <div class="flex flex-col w-full">
          <BaseInput
            id="campaignName"
            v-model="newCampaignOrListName"
            type="text"
            :placeholder="`${campaignOrListText(type)} Name`"
            className="px-3.5 py-2.5 w-full border border-gray-300 rounded-md text-sm font-normal leading-5 whitespace-nowrap"
            required
          />
        </div>
        <div class="flex flex-row gap-2 justify-end items-center">
          <BaseButton
            type="button"
            class="flex max-w-max flex-col justify-center items-center px-2 py-3 w-full bg-white border border-gray-300 text-black rounded-md text-md font-medium leading-none text-center"
            @click="$emit('update:isVisible', false)"
          >
            Cancel
          </BaseButton>
          <BaseButton
            type="button"
            class="flex max-w-max flex-col justify-center items-center px-2 py-3 w-full bg-primary-purple text-white rounded-md text-md font-medium leading-none text-center"
            @click="createNewCampaignOrList"
            :disabled="isLoading"
          >
            {{ isLoading ? "Submitting..." : "Submit" }}
          </BaseButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from "vue";
import BaseButton from "@/components/shared/BaseButton.vue";
import BaseInput from "@/components/shared/BaseInput.vue";
import { gql } from "@apollo/client/core";
import { useMutation } from "@vue/apollo-composable";

const CREATE_SALESFORCE_CAMPAIGN = gql`
  mutation createSalesforceCampaign($input: CreateSalesforceCampaignInput!) {
    createSalesforceCampaign(input: $input) {
      success
      error
    }
  }
`;

const CREATE_HUBSPOT_LIST = gql`
  mutation createHubspotList($input: CreateHubspotListInput!) {
    createHubspotList(input: $input) {
      success
      error
    }
  }
`;
export default defineComponent({
  name: "CreateNewCampaignOrListModal",
  components: {
    BaseButton,
    BaseInput,
  },
  props: {
    type: {
      type: String,
      required: true,
    },
    isVisible: {
      type: Boolean,
      required: true,
    },
    programId: {
      type: String,
      required: true,
    },
    handleNewCampaignOrListCreated: {
      type: Function,
      required: true,
    },
  },
  emits: ["update:isVisible"],
  setup(props, { emit }) {
    const newCampaignOrListName = ref<string>("");
    const errorMessage = ref<string>("");
    const {
      mutate: createSalesforceCampaign,
      onDone: createSalesforceCampaignDone,
      onError: createSalesforceCampaignError,
      loading: isLoadingCreateSalesforceCampaign,
    } = useMutation(CREATE_SALESFORCE_CAMPAIGN);
    const {
      mutate: createHubspotList,
      onDone: createHubspotListDone,
      onError: createHubspotListError,
      loading: isLoadingCreateHubspotList,
    } = useMutation(CREATE_HUBSPOT_LIST);

    const createNewCampaignOrList = () => {
      if (props.type === "salesforce") {
        createNewSalesforceCampaign();
      } else {
        createNewHubspotList();
      }
    };

    const createNewHubspotList = () => {
      if (!inputIsValid()) {
        return;
      }
      const input = {
        name: newCampaignOrListName.value,
      };
      createHubspotList({
        input: {
          programId: props.programId,
          listParams: input,
        },
      });

      createHubspotListDone((result) => {
        if (result) {
          if (result.data.createHubspotList.success) {
            emit("update:isVisible", false);
            props.handleNewCampaignOrListCreated(newCampaignOrListName.value);
            newCampaignOrListName.value = "";
            errorMessage.value = "";
          } else {
            handleErrorMessage(result.data.createHubspotList.error);
          }
        }
      });

      createHubspotListError((error) => {
        if (error) {
          handleErrorMessage(error.message);
        }
      });
    };

    const createNewSalesforceCampaign = () => {
      if (!inputIsValid()) {
        return;
      }
      const input = {
        name: newCampaignOrListName.value,
      };
      createSalesforceCampaign({
        input: {
          programId: props.programId,
          campaignParams: input,
        },
      });

      createSalesforceCampaignDone((result) => {
        if (result) {
          if (result.data.createSalesforceCampaign.success) {
            emit("update:isVisible", false);
            props.handleNewCampaignOrListCreated(newCampaignOrListName.value);
            newCampaignOrListName.value = "";
            errorMessage.value = "";
          } else {
            handleErrorMessage(result.data.createSalesforceCampaign.error);
          }
        }
      });

      createSalesforceCampaignError((error) => {
        if (error) {
          handleErrorMessage(error.message);
        }
      });
    };

    const inputIsValid = () => {
      if (!newCampaignOrListName.value) {
        handleErrorMessage("Name is required");
        return false;
      }
      return true;
    };

    const handleErrorMessage = (message: string) => {
      errorMessage.value = message;
      setTimeout(() => {
        errorMessage.value = "";
      }, 3000);
    };

    const isLoading = computed(() => {
      if (props.type === "salesforce") {
        return isLoadingCreateSalesforceCampaign.value;
      } else {
        return isLoadingCreateHubspotList.value;
      }
    });

    const campaignOrListText = (type: string) => {
      if (type === "salesforce") {
        return "Campaign";
      } else {
        return "List";
      }
    };

    return {
      createNewCampaignOrList,
      newCampaignOrListName,
      errorMessage,
      isLoading,
      campaignOrListText,
    };
  },
});
</script>
