<template>
  <div
    class="flex flex-col max-w-max border border-solid border-primary-purple bg-white rounded-lg p-0.5"
  >
    <div
      class="flex flex-col border border-solid border-gray-300 rounded-lg p-4"
    >
      <div class="flex flex-wrap gap-3">
        <div class="flex flex-col">
          <div
            class="flex items-center justify-center w-8 h-8 bg-blue-500 text-white rounded-full"
          >
            {{ initials }}
          </div>
        </div>
        <div class="flex flex-col text-left">
          <span class="text-sm leading-5 font-medium text-gray-900">{{
            accountName
          }}</span>
          <span class="text-sm leading-5 font-normal text-gray-500">{{
            accountEmail
          }}</span>
        </div>
        <div v-html="HOLLOW_CHECK_CIRCLE" class="text-primary-purple"></div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";
import { HOLLOW_CHECK_CIRCLE } from "@/assets/svg/shared/svg-constants";

export default defineComponent({
  name: "ProviderAccountCard",
  props: {
    accountName: {
      type: String,
      required: true,
    },
    accountEmail: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const initials = computed(() => {
      if (props.accountName) {
        const words = props.accountName.trim().split(" ");
        return words.map((word) => word[0].toUpperCase()).join("");
      }
      return "";
    });

    return {
      initials,
      HOLLOW_CHECK_CIRCLE,
    };
  },
});
</script>
