import {
  CreateExpertProgram,
  DirectoryButtonStyles,
} from "@/types/user-store-types";
import { defineStore } from "pinia";
import { PaymentMethod } from "@/types/dashboard-types";
import { Program } from "@/types/dashboard-types";
// Define the UserState interface
interface UserState {
  token: string | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  title: string | null;
  profilePicture: string | null;
  companyId: string | null;
  companyName: string | null;
  companyWebsite: string | null;
  createExpertProgram: CreateExpertProgram | null;
  accountBalanceId: string | null;
  availableBalance: number | null;
  pendingBalance: number | null;
  paymentMethod: PaymentMethod | null;
  selectedProgram: Program | null;
  updateInvoiceHistory: boolean | null;
  updateAccountBalance: boolean | null;
}

export const useUserStore = defineStore("user", {
  state: (): UserState => ({
    token: null,
    email: null,
    firstName: null,
    lastName: null,
    title: null,
    profilePicture: null,
    companyName: null,
    companyWebsite: null,
    companyId: null,
    createExpertProgram: null,
    availableBalance: null,
    pendingBalance: null,
    paymentMethod: null,
    accountBalanceId: null,
    updateInvoiceHistory: false,
    updateAccountBalance: false,
    selectedProgram: null,
  }),
  getters: {
    isLoggedIn: (state) => !!state.token,
  },
  actions: {
    setToken(token: string) {
      this.token = token;
    },
    setEmail(email: string) {
      this.email = email;
    },
    setFirstName(firstName: string) {
      this.firstName = firstName;
    },
    setLastName(lastName: string) {
      this.lastName = lastName;
    },
    setTitle(title: string) {
      this.title = title;
    },
    setProfilePicture(profilePicture: string) {
      this.profilePicture = profilePicture;
    },
    setCompanyName(companyName: string) {
      this.companyName = companyName;
    },
    setCompanyWebsite(companyWebsite: string) {
      this.companyWebsite = companyWebsite;
    },
    setCompanyId(companyId: string) {
      this.companyId = companyId;
    },
    setCreateExpertProgram(createExpertProgram: CreateExpertProgram) {
      this.createExpertProgram = createExpertProgram;
    },
    setAccountBalance(availableBalance: number) {
      this.availableBalance = availableBalance;
    },
    setPendingBalance(pendingBalance: number) {
      this.pendingBalance = pendingBalance;
    },
    setPaymentMethod(paymentMethod: PaymentMethod) {
      this.paymentMethod = paymentMethod;
    },
    setSelectedProgram(selectedProgram: Program | null) {
      this.selectedProgram = selectedProgram;
    },
    setAccountBalanceId(accountBalanceId: string) {
      this.accountBalanceId = accountBalanceId;
    },
    setInvoiceHistory(value: boolean | null) {
      this.updateInvoiceHistory = value;
    },
    setUpdateAccountBalance(value: boolean | null) {
      this.updateAccountBalance = value;
    },
    updateCompanyId(companyId: string) {
      if (this.createExpertProgram) {
        this.createExpertProgram.companyId = companyId;
      } else {
        console.error("createExpertProgram is null");
      }
    },
    updateDirectoryCreated(value: boolean) {
      if (this.createExpertProgram) {
        this.createExpertProgram.directoryCreated = value;
      } else {
        console.error("createExpertProgram is null");
      }
    },
    updateWidgetCreated(value: boolean) {
      if (this.createExpertProgram) {
        this.createExpertProgram.widgetCreated = value;
      } else {
        console.error("createExpertProgram is null");
      }
    },
    updateCreatedWidgetIds(widgetId: string) {
      if (this.createExpertProgram) {
        this.createExpertProgram.createdWidgetIds.push(widgetId);
      } else {
        console.error("createExpertProgram is null");
      }
    },
    updateCrmSynced(value: boolean) {
      if (this.createExpertProgram) {
        this.createExpertProgram.crmSynced = value;
      } else {
        console.error("createExpertProgram is null");
      }
    },
    updateDirectoryButtonStyles(directoryButtonStyles: DirectoryButtonStyles) {
      if (this.createExpertProgram) {
        this.createExpertProgram.directoryButtonStyles = directoryButtonStyles;
      } else {
        console.error("createExpertProgram is null");
      }
    },
    clearUpdateExpertProgram() {
      this.createExpertProgram = null;
    },
    clearStore() {
      this.token = null;
      this.email = null;
      this.firstName = null;
      this.lastName = null;
      this.profilePicture = null;
      this.companyName = null;
      this.companyWebsite = null;
      this.companyId = null;
      this.createExpertProgram = null;
      this.availableBalance = 0;
      this.pendingBalance = 0;
      this.paymentMethod = null;
      this.accountBalanceId = null;
      this.updateInvoiceHistory = false;
      this.updateAccountBalance = false;
      this.selectedProgram = null;
    },
  },
  persist: true,
});
