<template>
  <div
    class="flex flex-col p-4 gap-4 border border-solid border-gray-300 bg-white rounded-lg"
  >
    <div
      v-if="isLoadingSalesforce"
      class="fixed inset-0 bg-black bg-opacity-25 flex justify-center items-center z-50"
    >
      <!-- Spinner -->
      <div
        class="animate-spin rounded-full h-32 w-32 border-t-4 border-b-4 border-primary-purple"
      ></div>
    </div>
    <div class="flex flex-row justify-between items-center gap-4">
      <!-- Text -->
      <div class="flex flex-col gap-3">
        <span
          class="text-gray-800 font-sans text-lg font-semibold leading-6 tracking-tight"
          >{{ title(type) }}</span
        >
      </div>
      <!-- Toggle -->
      <div class="flex flex-col">
        <div class="flex flex-col justify-center">
          <button
            class="flex items-center w-12 h-6 rounded-xl transition-colors duration-300"
            :class="{
              'bg-violet-600': addAttendeeToCampaignOrList,
              'bg-gray-200': !addAttendeeToCampaignOrList,
            }"
            @click="toggleAddAttendeeToCampaignOrList"
            aria-label="Toggle button"
          >
            <span
              class="flex self-stretch my-auto w-5 h-5 bg-white rounded-full transition-transform duration-300 transform"
              :class="{
                'translate-x-6': addAttendeeToCampaignOrList,
                'translate-x-0.5': !addAttendeeToCampaignOrList,
              }"
            ></span>
          </button>
        </div>
      </div>
    </div>

    <div class="flex flex-row gap-2 justify-between items-center">
      <BaseSearchableDropdown
        v-model="selectedCampaignOrListName"
        :options="campaignOrListNames"
        :placeholder="addAttendeeToCampaignOrListPlaceholderText(type)"
        class="w-32 md:w-64"
        @change="handleCampaignOrListSelection"
      />
      <span class="text-gray-600 font-sans text-sm font-medium leading-5"
        >Or</span
      >
      <BaseButton
        type="button"
        class="flex max-w-max flex-col justify-center items-center px-2 py-3 w-full bg-indigo-50 text-primary-purple rounded-md text-md font-medium leading-none text-center"
        @click="createNewCampaignOrList"
      >
        <span>{{ buttonText(type) }}</span>
      </BaseButton>
    </div>

    <CreateNewCampaignOrListModal
      :programId="programId"
      :type="type"
      :isVisible="isCreateNewCampaignOrListModalVisible"
      :handleNewCampaignOrListCreated="handleNewCampaignOrListCreated"
      @update:isVisible="isCreateNewCampaignOrListModalVisible = $event"
    />
  </div>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  onMounted,
  PropType,
  ref,
  watch,
} from "vue";
import BaseButton from "@/components/shared/BaseButton.vue";
import CreateNewCampaignOrListModal from "./CreateNewCampaignOrListModal.vue";
import { gql } from "@apollo/client/core";
import { useQuery } from "@vue/apollo-composable";
import { CampaignOrList } from "@/types/integration-types";
import BaseSearchableDropdown from "@/components/shared/BaseSearchableDropdown.vue";

const FETCH_SALESFORCE_CAMPAIGNS = gql`
  query fetchSalesforceCampaigns($programId: ID!) {
    fetchSalesforceCampaigns(programId: $programId) {
      id
      name
    }
  }
`;

const FETCH_HUBSPOT_LISTS = gql`
  query hubspotLists($programId: ID!) {
    hubspotLists(programId: $programId) {
      id
      name
    }
  }
`;

export default defineComponent({
  name: "AddAttendeeToCampaignOrListToggle",
  components: {
    BaseButton,
    CreateNewCampaignOrListModal,
    BaseSearchableDropdown,
  },
  props: {
    type: {
      type: String,
      required: true,
    },
    campaignOrList: {
      type: Object as PropType<CampaignOrList | null>,
      required: true,
    },
    addAttendeeToCampaignOrList: {
      type: Boolean,
      required: true,
    },
    programId: {
      type: String,
      required: true,
    },
    handleErrorMessage: {
      type: Function,
      required: true,
    },
  },
  emits: ["update:addAttendeeToCampaignOrList"],
  setup(props, { emit }) {
    const isCreateNewCampaignOrListModalVisible = ref<boolean>(false);
    const localCampaignOrList = ref<CampaignOrList | null>(
      props.campaignOrList
    );
    const newlyCreatedCampaignOrList = ref<CampaignOrList | null>(null);
    const campaignsOrListsList = ref<CampaignOrList[]>([]);
    const isLoadingSalesforce = ref<boolean>(false);

    const toggleAddAttendeeToCampaignOrList = () => {
      emit(
        "update:addAttendeeToCampaignOrList",
        !props.addAttendeeToCampaignOrList
      );
    };

    const createNewCampaignOrList = () => {
      isCreateNewCampaignOrListModalVisible.value = true;
    };

    const fetchHubspotLists = (selectList?: string) => {
      isLoadingSalesforce.value = true;
      const {
        onResult: hubspotListsQueryResult,
        onError: hubspotListsQueryError,
        stop,
      } = useQuery(
        FETCH_HUBSPOT_LISTS,
        {
          programId: props.programId,
        },
        { fetchPolicy: "network-only" }
      );

      hubspotListsQueryResult((result) => {
        if (result) {
          if (result?.data?.hubspotLists) {
            campaignsOrListsList.value = result.data.hubspotLists;
          }
          if (selectList) {
            localCampaignOrList.value = campaignsOrListsList.value.find(
              (campaign) => campaign.name === selectList
            ) as CampaignOrList;
            newlyCreatedCampaignOrList.value = localCampaignOrList.value;
            emit("update:addAttendeeToCampaignOrList", true);
          }
          isLoadingSalesforce.value = false;
          stop();
        }
      });

      hubspotListsQueryError((error) => {
        if (error) {
          isLoadingSalesforce.value = false;
          props.handleErrorMessage(error.message);
          stop();
        }
      });
    };

    const fetchSalesforceCampaigns = (selectCampaign?: string) => {
      isLoadingSalesforce.value = true;
      const {
        onResult: salesforceCampaignsQueryResult,
        onError: salesforceCampaignsQueryError,
        stop,
      } = useQuery(
        FETCH_SALESFORCE_CAMPAIGNS,
        {
          programId: props.programId,
        },
        { fetchPolicy: "network-only" }
      );

      salesforceCampaignsQueryResult((result) => {
        if (result) {
          if (result?.data?.fetchSalesforceCampaigns) {
            campaignsOrListsList.value = result.data.fetchSalesforceCampaigns;
          }
          if (selectCampaign) {
            localCampaignOrList.value = campaignsOrListsList.value.find(
              (campaign) => campaign.name === selectCampaign
            ) as CampaignOrList;
            newlyCreatedCampaignOrList.value = localCampaignOrList.value;
            emit("update:addAttendeeToCampaignOrList", true);
          }
          isLoadingSalesforce.value = false;
          stop();
        }
      });

      salesforceCampaignsQueryError((error) => {
        if (error) {
          isLoadingSalesforce.value = false;
          props.handleErrorMessage(error.message);
          stop();
        }
      });
    };

    const handleNewCampaignOrListCreated = (campaignName: string) => {
      if (props.type === "salesforce") {
        fetchSalesforceCampaigns(campaignName);
      } else {
        isLoadingSalesforce.value = true;
        setTimeout(() => {
          fetchHubspotLists(campaignName);
        }, 1000);
      }
    };

    watch(
      [() => props.campaignOrList?.id, () => campaignsOrListsList.value],
      ([newCampaignId, campaigns]) => {
        if (newCampaignId && campaigns.length > 0) {
          localCampaignOrList.value = campaigns.find(
            (campaign) => campaign.id === newCampaignId
          ) as CampaignOrList;
          if (
            newlyCreatedCampaignOrList.value &&
            newlyCreatedCampaignOrList.value?.id !==
              localCampaignOrList.value?.id
          ) {
            localCampaignOrList.value = newlyCreatedCampaignOrList.value;
          }
        }
      }
    );

    const handleCampaignOrListSelection = (campaignOrListName: string) => {
      localCampaignOrList.value =
        campaignsOrListsList.value.find((c) => c.name === campaignOrListName) ||
        null;
      emit("update:addAttendeeToCampaignOrList", true);
    };

    const title = (type: string) => {
      if (type === "salesforce") {
        return "Add Attendee to Campaign";
      } else if (type === "hubspot") {
        return "Add Attendee to List";
      }
    };

    const buttonText = (type: string) => {
      if (type === "salesforce") {
        return "Create New Campaign";
      } else if (type === "hubspot") {
        return "Create New List";
      }
    };

    const addAttendeeToCampaignOrListPlaceholderText = (type: string) => {
      if (type === "salesforce") {
        return "Select campaign";
      } else if (type === "hubspot") {
        return "Select list";
      }
    };

    const campaignOrListNames = computed(() =>
      campaignsOrListsList.value.map((campaignOrList) => campaignOrList.name)
    );

    const selectedCampaignOrListName = computed({
      get: () => localCampaignOrList.value?.name || "",
      set: (value: string) => handleCampaignOrListSelection(value),
    });

    onMounted(() => {
      if (props.type === "salesforce") {
        fetchSalesforceCampaigns();
      } else {
        fetchHubspotLists();
      }
    });

    return {
      toggleAddAttendeeToCampaignOrList,
      localCampaignOrList,
      createNewCampaignOrList,
      isCreateNewCampaignOrListModalVisible,
      campaignsOrListsList,
      handleNewCampaignOrListCreated,
      isLoadingSalesforce,
      selectedCampaignOrListName,
      campaignOrListNames,
      title,
      buttonText,
      addAttendeeToCampaignOrListPlaceholderText,
      handleCampaignOrListSelection,
    };
  },
});
</script>
