<template>
  <div
    class="flex flex-col gap-4 pl-4 pr-4 pb-4 w-full lg:pr-20 lg:w-[40vw] max-w-[500px]"
  >
    <div class="flex flex-col">
      <p
        v-if="!attendeeDetailsError"
        class="text-gray-900 font-sans text-xl font-bold leading-7 text-left"
      >
        Enter Details
      </p>
      <p
        v-if="attendeeDetailsError"
        class="p-2.5 text-sm leading-5 text-center text-red-900 bg-red-50 rounded-lg text-left"
      >
        Failed to fetch attendee details, please refresh to try again.
      </p>
    </div>
    <!-- name -->
    <div class="flex flex-col text-left">
      <BaseLabel
        forAttr="firstName"
        text="First Name"
        class="text-gray-800 text-sm font-medium leading-5 font-inter"
      />
      <BaseInput
        id="firstName"
        v-model="firstName"
        type="text"
        @input="handleFirstNameInput()"
        placeholder=""
        :className="{
          'flex-grow px-3.5 py-2.5 mt-1 text-gray-800 text-sm font-medium leading-5 font-inter whitespace-nowrap bg-white rounded-md border border-gray-300 border-solid shadow-sm max-md:pr-5 w-full focus:outline-none': true,
          'border-red-500': firstNameWarning,
          'border-gray-300': !firstNameWarning,
        }"
      />
      <span
        class="text-xs mt-1 text-gray-400 text-left"
        :class="{ 'text-red-500': firstNameWarning }"
      >
        {{ firstNameWarningMessage }}
      </span>
    </div>
    <div class="flex flex-col text-left">
      <BaseLabel
        forAttr="lastName"
        text="Last Name"
        class="text-gray-800 text-sm font-medium leading-5 font-inter"
      />
      <BaseInput
        id="lastName"
        v-model="lastName"
        type="text"
        @input="handleLastNameInput()"
        placeholder=""
        :className="{
          'flex-grow px-3.5 py-2.5 mt-1 text-gray-800 text-sm font-medium leading-5 font-inter whitespace-nowrap bg-white rounded-md border border-gray-300 border-solid shadow-sm max-md:pr-5 w-full focus:outline-none': true,
          'border-red-500': lastNameWarning,
          'border-gray-300': !lastNameWarning,
        }"
      />
      <span
        class="text-xs mt-1 text-gray-400 text-left"
        :class="{ 'text-red-500': lastNameWarning }"
      >
        {{ lastNameWarningMessage }}
      </span>
    </div>
    <!-- title -->
    <div class="flex flex-col text-left">
      <BaseLabel
        forAttr="title"
        text="Title"
        class="text-gray-800 text-sm font-medium leading-5 font-inter"
      />
      <BaseInput
        id="title"
        v-model="title"
        type="text"
        @input="handleTitleInput()"
        placeholder=""
        :className="{
          'flex-grow px-3.5 py-2.5 mt-1 text-gray-800 text-sm font-medium leading-5 font-inter whitespace-nowrap bg-white rounded-md border border-gray-300 border-solid shadow-sm max-md:pr-5 w-full focus:outline-none': true,
          'border-red-500': titleWarning,
          'border-gray-300': !titleWarning,
        }"
      />
      <span
        class="text-xs mt-1 text-gray-400 text-left"
        :class="{ 'text-red-500': titleWarning }"
      >
        {{ titleWarningMessage }}
      </span>
    </div>
    <!-- company -->
    <div class="flex flex-col text-left">
      <BaseLabel
        forAttr="company"
        text="Company"
        class="text-gray-800 text-sm font-medium leading-5 font-inter"
      />
      <BaseInput
        id="company"
        v-model="company"
        type="text"
        @input="handleCompanyInput()"
        placeholder=""
        :className="{
          'flex-grow px-3.5 py-2.5 mt-1 text-gray-800 text-sm font-medium leading-5 font-inter whitespace-nowrap bg-white rounded-md border border-gray-300 border-solid shadow-sm max-md:pr-5 w-full focus:outline-none': true,
          'border-red-500': companyWarning,
          'border-gray-300': !companyWarning,
        }"
      />
      <span
        class="text-xs mt-1 text-gray-400 text-left"
        :class="{ 'text-red-500': companyWarning }"
      >
        {{ companyWarningMessage }}
      </span>
    </div>
    <!-- email -->
    <div class="flex flex-col text-left">
      <BaseLabel
        forAttr="email"
        text="Email"
        class="text-gray-800 text-sm font-medium leading-5 font-inter text-left"
      />
      <BaseInput
        id="email"
        v-model="email"
        type="email"
        @input="handleEmailInput()"
        placeholder="Enter email address"
        :className="{
          'flex-grow px-3.5 py-2.5 mt-1 text-gray-800 text-sm font-medium leading-5 font-inter whitespace-nowrap bg-white rounded-md border border-gray-300 border-solid shadow-sm max-md:pr-5 w-full focus:outline-none': true,
          'border-red-500': emailWarning,
          'border-gray-300': !emailWarning,
        }"
      />
      <span
        class="text-xs mt-1 text-gray-400 text-left"
        :class="{ 'text-red-500': emailWarning }"
      >
        {{ emailWarningMessage }}
      </span>
    </div>
    <!-- linkedin url -->
    <div class="flex flex-col text-left">
      <BaseLabel
        forAttr="linkedinURL"
        text="Linkedin URL"
        class="text-gray-800 text-sm font-medium leading-5 font-inter text-left"
      />
      <BaseInput
        id="linkedinUrl"
        v-model="linkedinUrl"
        type="text"
        @input="handleLinkedinUrlInput()"
        placeholder="https://www.linkedin.com/in/johndoe_32"
        :className="{
          'flex-grow px-3.5 py-2.5 mt-1 text-gray-800 text-sm font-medium leading-5 font-inter whitespace-nowrap bg-white rounded-md border border-gray-300 border-solid shadow-sm max-md:pr-5 w-full focus:outline-none': true,
          'border-red-500': linkedinUrlWarning,
          'border-gray-300': !linkedinUrlWarning,
        }"
      />
      <span
        class="text-xs mt-1 text-gray-400 text-left"
        :class="{ 'text-red-500': linkedinUrlWarning }"
      >
        {{ linkedinUrlWarningMessage }}
      </span>
    </div>
    <!-- extra helpful information -->
    <!-- Note area -->
    <div class="flex flex-col">
      <BaseLabel
        forAttr="note"
        text="Please share anything that will help prepare for our meeting."
        class="text-gray-800 text-sm font-medium leading-5 font-inter text-left"
      />
      <textarea
        v-model="note"
        rows="4"
        placeholder=""
        @input="handleNoteInput($event)"
        class="w-full border rounded px-2 py-1 focus:outline-none"
        :class="{
          'border-red-500': noteWarning,
          'border-gray-300': !noteWarning,
        }"
      ></textarea>
      <span
        class="text-xs mt-1 text-gray-400 text-left"
        :class="{ 'text-red-500': noteWarning }"
      >
        {{ note.length }} / 512
      </span>
    </div>

    <!-- disclaimer -->
    <div class="flex flex-col text-left">
      <span class="font-sans text-gray-500 text-xs font-normal leading-4"
        >By proceeding you confirm that you have read and agree to
        <a
          href="https://www.slashexperts.com/terms"
          target="_blank"
          rel="noopener noreferrer"
          class="text-primary-purple"
        >
          terms of use
        </a>
        and
        <a
          href="https://www.slashexperts.com/privacy"
          target="_blank"
          rel="noopener noreferrer"
          class="text-primary-purple"
        >
          privacy policy.
        </a>
      </span>
    </div>

    <!-- button -->
    <div class="flex flex-col">
      <BaseButton
        type="button"
        @click="handleScheduleMeeting()"
        class="max-w-max px-4 py-2 text-xs sm:text-sm font-medium leading-none text-white border border-gray-300 rounded-md bg-primary-purple"
      >
        Schedule Event
      </BaseButton>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import BaseLabel from "@/components/shared/BaseLabel.vue";
import BaseButton from "@/components/shared/BaseButton.vue";
import BaseInput from "@/components/shared/BaseInput.vue";
import { useAttendeeStore } from "@/stores/attendee";
import { gql } from "@apollo/client/core";
import { onMounted } from "vue";
import { useRoute } from "vue-router";
import { useQuery } from "@vue/apollo-composable";

const GET_ATTENDEE = gql`
  query getAttendee($uuid: String!) {
    getAttendee(uuid: $uuid) {
      firstName
      lastName
      title
      company
      email
      linkedinUrl
      topic
    }
  }
`;

export default defineComponent({
  name: "AttendeeInformation",
  components: {
    BaseLabel,
    BaseInput,
    BaseButton,
  },
  setup() {
    const attendeeStore = useAttendeeStore();
    const route = useRoute();
    const attendeeDetailsError = ref(false);
    const firstName = ref("");
    const firstNameWarning = ref(false);
    const firstNameWarningMessage = ref("");
    const lastName = ref("");
    const lastNameWarning = ref(false);
    const lastNameWarningMessage = ref("");
    const email = ref("");
    const emailWarning = ref(false);
    const emailWarningMessage = ref("");
    const note = ref("");
    const noteWarning = ref(false);
    const title = ref("");
    const titleWarning = ref(false);
    const titleWarningMessage = ref("");
    const company = ref("");
    const companyWarning = ref(false);
    const companyWarningMessage = ref("");
    const linkedinUrl = ref("");
    const linkedinUrlWarning = ref(false);
    const linkedinUrlWarningMessage = ref("");

    onMounted(() => {
      const attendeeUuid = route.query.attendee;
      if (attendeeUuid) {
        getAttendee(attendeeUuid as string);
      }
    });

    const handleFirstNameInput = () => {
      if (firstNameWarning.value) {
        firstNameWarning.value = false;
        firstNameWarningMessage.value = "";
      }
    };

    const handleLastNameInput = () => {
      if (lastNameWarning.value) {
        lastNameWarning.value = false;
        lastNameWarningMessage.value = "";
      }
    };

    const handleEmailInput = () => {
      if (emailWarning.value) {
        emailWarning.value = false;
        emailWarningMessage.value = "";
      }
    };

    const validEmail = (email: string) => {
      const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
      return pattern.test(email);
    };

    const storeAttendeeDetails = () => {
      attendeeStore.setBookMeetingFirstName(firstName.value);
      attendeeStore.setBookMeetingLastName(lastName.value);
      attendeeStore.setBookMeetingTitle(title.value);
      attendeeStore.setBookMeetingCompany(company.value);
      attendeeStore.setBookMeetingEmail(email.value);
      attendeeStore.setBookMeetingNotes(note.value);
      attendeeStore.setBookMeetingLinkedinUrl(linkedinUrl.value);
    };

    const handleScheduleMeeting = () => {
      console.log("schedule meeting");
      if (firstName.value === "") {
        firstNameWarning.value = true;
        firstNameWarningMessage.value = "First name is required.";
        return;
      }
      if (lastName.value === "") {
        lastNameWarning.value = true;
        lastNameWarningMessage.value = "Last name is required.";
        return;
      }
      if (title.value === "") {
        titleWarning.value = true;
        titleWarningMessage.value = "Title is required.";
        return;
      }
      if (company.value === "") {
        companyWarning.value = true;
        companyWarningMessage.value = "Company is required.";
        return;
      }
      if (email.value === "") {
        emailWarning.value = true;
        emailWarningMessage.value = "Email is required.";
        return;
      }
      if (!validEmail(email.value)) {
        emailWarning.value = true;
        emailWarningMessage.value = "Email address is invalid.";
        return;
      }
      if (!validLinkedinUrl()) {
        linkedinUrlWarning.value = true;
        return;
      }

      storeAttendeeDetails();
    };

    const validLinkedinUrl = (): boolean => {
      try {
        let url = linkedinUrl.value;
        if (!url.toLowerCase().includes("linkedin.com/in/")) {
          linkedinUrlWarningMessage.value =
            "Linkedin URL missing linkedin.com/in/";
          return false;
        }

        url = url.endsWith("/") ? url.slice(0, -1) : url;
        const parts = url.split("/in/");
        if (parts.length > 1 && /^[a-zA-Z0-9-]+$/.test(parts[1])) {
          return true;
        } else {
          linkedinUrlWarningMessage.value =
            "Invalid LinkedIn profile username.";
          return false;
        }
      } catch (error) {
        linkedinUrlWarningMessage.value = "Invalid LinkedIn URL format.";
        return false;
      }
    };

    const handleNoteInput = (event: Event) => {
      const target = event.target as HTMLInputElement;
      if (target.value.length > 512) {
        noteWarning.value = true;
        setTimeout(() => {
          noteWarning.value = false;
        }, 1000);
      } else {
        noteWarning.value = false;
      }
      note.value = target.value.slice(0, 512);
    };

    const handleTitleInput = () => {
      if (titleWarning.value) {
        titleWarning.value = false;
        titleWarningMessage.value = "";
      }
    };

    const handleCompanyInput = () => {
      if (companyWarning.value) {
        companyWarning.value = false;
        companyWarningMessage.value = "";
      }
    };

    const handleLinkedinUrlInput = () => {
      if (linkedinUrlWarning.value) {
        linkedinUrlWarning.value = false;
        linkedinUrlWarningMessage.value = "";
      }
    };

    const getAttendee = (uuid: string) => {
      const { onResult, onError } = useQuery(
        GET_ATTENDEE,
        {
          uuid,
        },
        { fetchPolicy: "network-only" }
      );

      onResult((result) => {
        if (result.data && result.data.getAttendee) {
          firstName.value = result.data.getAttendee.firstName;
          lastName.value = result.data.getAttendee.lastName;
          title.value = result.data.getAttendee.title;
          company.value = result.data.getAttendee.company;
          email.value = result.data.getAttendee.email;
          linkedinUrl.value = result.data.getAttendee.linkedinUrl;
          note.value = result.data.getAttendee.topic;
        }
      });

      onError((error) => {
        if (error) {
          attendeeDetailsError.value = true;
        }
      });
    };

    return {
      firstName,
      firstNameWarning,
      firstNameWarningMessage,
      handleFirstNameInput,
      lastName,
      lastNameWarning,
      lastNameWarningMessage,
      handleLastNameInput,
      email,
      emailWarning,
      emailWarningMessage,
      handleEmailInput,
      note,
      noteWarning,
      handleScheduleMeeting,
      handleNoteInput,
      title,
      titleWarning,
      titleWarningMessage,
      handleTitleInput,
      company,
      companyWarning,
      companyWarningMessage,
      handleCompanyInput,
      attendeeDetailsError,
      linkedinUrl,
      linkedinUrlWarning,
      linkedinUrlWarningMessage,
      handleLinkedinUrlInput,
    };
  },
});
</script>
