import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex-1 p-4 border border-solid border-gray-300 bg-white rounded-lg" }
const _hoisted_2 = { class: "flex flex-row justify-between items-center gap-4" }
const _hoisted_3 = { class: "flex flex-col gap-3" }
const _hoisted_4 = { class: "text-gray-800 font-sans text-lg font-semibold leading-6 tracking-tight" }
const _hoisted_5 = { class: "flex flex-col" }
const _hoisted_6 = { class: "flex flex-col justify-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.title(_ctx.type)), 1),
        _cache[1] || (_cache[1] = _createElementVNode("span", { class: "text-gray-600 font-sans text-sm font-medium leading-5" }, " If an Attendee doesn't exist in Salesforce based on their email address yet but there's a matched Company with the same domain then create a Contact. If there's no Account yet, create a Lead. ", -1))
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("button", {
            class: _normalizeClass(["flex items-center w-12 h-6 rounded-xl transition-colors duration-300", {
              'bg-violet-600': _ctx.createNewLeadOrContact,
              'bg-gray-200': !_ctx.createNewLeadOrContact,
            }]),
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleCreateNewLeadOrContact && _ctx.toggleCreateNewLeadOrContact(...args))),
            "aria-label": "Toggle button"
          }, [
            _createElementVNode("span", {
              class: _normalizeClass(["flex self-stretch my-auto w-5 h-5 bg-white rounded-full transition-transform duration-300 transform", {
                'translate-x-6': _ctx.createNewLeadOrContact,
                'translate-x-0.5': !_ctx.createNewLeadOrContact,
              }])
            }, null, 2)
          ], 2)
        ])
      ])
    ])
  ]))
}