import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment } from "vue"
import _imports_0 from '@/assets/shared/linkedin-icon.svg'
import _imports_1 from '@/assets/expert-program/directory/calendar-badge.svg'


const _hoisted_1 = ["src"]
const _hoisted_2 = { class: "flex flex-row items-center gap-2" }
const _hoisted_3 = { class: "flex flex-row gap-1 items-center" }
const _hoisted_4 = {
  key: 0,
  class: "relative flex flex-col items-center group"
}
const _hoisted_5 = {
  key: 1,
  class: "relative flex flex-col rounded-md p-0.5 items-center bg-violet-100 group"
}
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = {
  key: 2,
  class: "relative flex flex-col rounded-md p-0.5 items-center bg-violet-100 group"
}
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = { class: "flex flex-col gap-2 items-end my-2" }
const _hoisted_10 = { class: "flex flex-row gap-2 items-center" }
const _hoisted_11 = { class: "flex flex-row gap-1" }
const _hoisted_12 = { class: "flex flex-row gap-2 items-center" }
const _hoisted_13 = { class: "flex flex-row gap-1" }
const _hoisted_14 = { class: "overflow-x-auto overflow-y-hidden scrollbar-thin" }
const _hoisted_15 = { class: "flex flex-row w-full mx-auto items-center justify-center gap-1" }
const _hoisted_16 = ["onClick"]
const _hoisted_17 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("article", {
    class: _normalizeClass(["flex flex-col grow shrink p-0.5 sm:p-2 bg-white rounded-lg min-w-[10.5rem] max-w-[19rem] sm:max-w-[22rem] sm:min-w-[22rem] overflow-hidden", {
      'items-center justify-center text-center': _ctx.isCentered,
      'items-start text-left': !_ctx.isCentered,
    }]),
    style: _normalizeStyle({
      fontFamily: _ctx.fontFamily,
      fontSize: _ctx.fontSize + 'px',
      // height: !isGridUneven ? computedHeight : 'auto',
    })
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["flex gap-2.5 w-full", {
        'items-start': !_ctx.isCentered,
        'justify-center items-center': _ctx.isCentered,
        [_ctx.profileImageAndNameLayoutClass]: true,
      }])
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(["flex items-center justify-center overflow-hidden flex-shrink-0", _ctx.profileImageClass])
      }, [
        _createElementVNode("img", {
          src: _ctx.profile.imageUrl,
          alt: "",
          class: "object-cover w-16 h-16 flex-shrink-0",
          style: _normalizeStyle({
            transform: !_ctx.enableBookMeeting ? 'scale(1.2)' : '', // Scale up small images to prevent rounded corners from showing gaps
          })
        }, null, 12, _hoisted_1)
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass(["flex flex-col w-full", {
          'items-start': !_ctx.isCentered,
          'justify-center items-center': _ctx.isCentered,
        }])
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(["flex flex-row w-full", {
            'justify-center': _ctx.isCentered,
          }])
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(["flex flex-row justify-between", { 'w-full': !_ctx.isCentered, 'gap-2': _ctx.isCentered }])
          }, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("span", {
                class: _normalizeClass(["text-xs font-semibold leading-loose text-zinc-800", { 'text-center': _ctx.isCentered }]),
                style: _normalizeStyle({
                  fontSize: _ctx.fontSize + 'px',
                  fontWeight: _ctx.boldness,
                })
              }, _toDisplayString(_ctx.expertName(_ctx.profile)), 7),
              _createElementVNode("img", {
                src: _imports_0,
                alt: "",
                class: "object-cover shrink-0 w-6 h-6 cursor-pointer",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleLinkedinClick(_ctx.profile.linkedinUrl)))
              })
            ]),
            _createElementVNode("div", _hoisted_3, [
              (_ctx.availableInNext2Hours(_ctx.profile.soonestAvailabilities))
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, _cache[2] || (_cache[2] = [
                    _createElementVNode("img", {
                      src: _imports_1,
                      alt: "",
                      class: "w-5 h-5 object-contain"
                    }, null, -1),
                    _createElementVNode("div", { class: "absolute left-1/2 -translate-x-[80%] top-full mt-2 -ml-4 w-max max-w-xs px-2 py-1 text-xs text-white bg-gray-900 rounded-md opacity-0 group-hover:opacity-100 transition-opacity shadow-lg z-50 pointer-events-none" }, " Available in next 2 hours ", -1)
                  ])))
                : _createCommentVNode("", true),
              (_ctx.profile.anyPastMeetings)
                ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                    _createElementVNode("div", { innerHTML: _ctx.FIRE_ICON }, null, 8, _hoisted_6),
                    _cache[3] || (_cache[3] = _createElementVNode("div", { class: "absolute left-1/2 -translate-x-[80%] top-full mt-2 -ml-4 w-max max-w-xs px-2 py-1 text-xs text-white bg-gray-900 rounded-md opacity-0 group-hover:opacity-100 transition-opacity shadow-lg z-50 pointer-events-none" }, " Recent Meetings ", -1))
                  ]))
                : _createCommentVNode("", true),
              (_ctx.top3MeetingsExperts?.includes(_ctx.profile))
                ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                    _createElementVNode("div", { innerHTML: _ctx.TROPHY_ICON }, null, 8, _hoisted_8),
                    _cache[4] || (_cache[4] = _createElementVNode("div", { class: "absolute left-1/2 -translate-x-1/2 top-full mt-2 -ml-4 w-max max-w-xs px-2 py-1 text-xs text-white bg-gray-900 rounded-md opacity-0 group-hover:opacity-100 transition-opacity shadow-lg z-50 pointer-events-none" }, " Top 3 ", -1))
                  ]))
                : _createCommentVNode("", true)
            ])
          ], 2)
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(["flex flex-col items-center w-full max-w-full", {
            'justify-center': _ctx.isCentered,
          }])
        }, [
          _createElementVNode("span", {
            class: _normalizeClass(["text-xs font-semibold text-zinc-800 break-words overflow-hidden whitespace-normal w-full max-h-[45px] leading-[1.2] line-clamp-2", { 'text-center': _ctx.isCentered }]),
            style: _normalizeStyle({
              fontSize: _ctx.fontSize + 'px',
              fontWeight: _ctx.boldness,
            })
          }, _toDisplayString(_ctx.expertTitleAndCompanyName(_ctx.profile)), 7)
        ], 2)
      ], 2)
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(["flex flex-col justify-end w-full gap-3 mt-2", {
        'items-center': _ctx.isCentered,
        'items-start': !_ctx.isCentered,
        'mt-auto': !_ctx.isGridUneven,
      }])
    }, [
      _createElementVNode("div", _hoisted_9, [
        _createElementVNode("div", _hoisted_10, [
          _cache[5] || (_cache[5] = _createElementVNode("span", { class: "font-bold" }, "Expertise", -1)),
          _createElementVNode("div", _hoisted_11, [
            (_openBlock(), _createElementBlock(_Fragment, null, _renderList(5, (index) => {
              return _createElementVNode("div", {
                key: index,
                class: "w-7 h-2",
                style: _normalizeStyle({
                backgroundColor:
                  index <= _ctx.profile.userProgramProfile[0].expertise
                    ? _ctx.backgroundColor
                    : '#D1D5DB',
              })
              }, null, 4)
            }), 64))
          ])
        ]),
        _createElementVNode("div", _hoisted_12, [
          _cache[6] || (_cache[6] = _createElementVNode("span", { class: "font-bold" }, "Experience", -1)),
          _createElementVNode("div", _hoisted_13, [
            (_openBlock(), _createElementBlock(_Fragment, null, _renderList(5, (index) => {
              return _createElementVNode("div", {
                key: index,
                class: "w-7 h-2",
                style: _normalizeStyle({
                backgroundColor:
                  index <= _ctx.profile.userProgramProfile[0].experience
                    ? _ctx.backgroundColor
                    : '#D1D5DB',
              })
              }, null, 4)
            }), 64))
          ])
        ])
      ]),
      _createElementVNode("div", {
        class: _normalizeClass(["w-full flex gap-1", {
          'flex-col': _ctx.isCentered,
          'flex-row': !_ctx.isCentered,
        }])
      }, [
        (_ctx.getCountryCode(_ctx.profile.country))
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(["relative w-fit px-1.5 py-0.5 my-auto rounded-md bg-slate-50 flex-shrink-0 group", {
            'left-1/2 translate-x-[-40%]': _ctx.isCentered,
          }])
            }, [
              _createElementVNode("span", {
                class: _normalizeClass(`fi fi-${_ctx.getCountryCodeForFlagIcon(_ctx.profile.country)}`)
              }, null, 2),
              _createElementVNode("div", {
                class: _normalizeClass(["absolute left-1/2 top-full mt-1 -ml-4 w-max max-w-xs px-2 py-1 text-xs text-white bg-gray-900 rounded-md opacity-0 group-hover:opacity-100 transition-opacity shadow-lg z-50 pointer-events-none", {
              '-translate-x-1/2': _ctx.isCentered,
              'translate-x-[10%]': !_ctx.isCentered,
            }])
              }, " Location: " + _toDisplayString(_ctx.profile.country), 3)
            ], 2))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_14, [
          _createElementVNode("div", {
            class: _normalizeClass(["flex flex-row gap-1 items-center min-w-min whitespace-nowrap", {
              'justify-center': _ctx.isCentered,
            }])
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.profile.spokenLanguages, (language, index) => {
              return (_openBlock(), _createElementBlock("div", {
                key: index,
                class: "flex-shrink-0"
              }, [
                _createElementVNode("span", {
                  class: "px-1.5 py-0.5 whitespace-nowrap rounded-md bg-slate-50 inline-block",
                  style: _normalizeStyle({ fontWeight: _ctx.boldness })
                }, _toDisplayString(language), 5)
              ]))
            }), 128))
          ], 2)
        ])
      ], 2),
      _createElementVNode("div", _hoisted_15, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.profile.soonestAvailabilities, (availability) => {
          return (_openBlock(), _createElementBlock("div", {
            key: availability.startTime,
            class: "flex flex-col px-2 py-1 rounded-md text-xxs cursor-pointer",
            style: _normalizeStyle({
            backgroundColor: _ctx.backgroundColor,
            color: _ctx.textColor,
          }),
            onClick: ($event: any) => (_ctx.openBookAMeetingView(availability.startTime))
          }, _toDisplayString(_ctx.formatDateTime(availability.startTime)), 13, _hoisted_16))
        }), 128))
      ]),
      _createElementVNode("div", {
        class: _normalizeClass(["w-full", { 'flex justify-center': _ctx.isCentered }])
      }, [
        _createElementVNode("button", {
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleBookMeeting(_ctx.profile.uuid))),
          class: "overflow-hidden gap-2 w-full py-1.5 pr-2.5 pl-2.5 mt-2.5 font-medium rounded shadow-sm",
          disabled: !_ctx.enableBookMeeting,
          style: _normalizeStyle({
            backgroundColor: _ctx.backgroundColor,
            color: _ctx.textColor,
          })
        }, " Find More Meeting Times ", 12, _hoisted_17)
      ], 2)
    ], 2)
  ], 6))
}