<template>
  <article
    class="flex flex-col grow shrink p-0.5 sm:p-2 bg-white rounded-lg min-w-[10.5rem] max-w-[19rem] sm:max-w-[22rem] sm:min-w-[22rem] overflow-hidden"
    :class="{
      'items-center justify-center text-center': isCentered,
      'items-start text-left': !isCentered,
    }"
    :style="{
      fontFamily: fontFamily,
      fontSize: fontSize + 'px',
      // height: !isGridUneven ? computedHeight : 'auto',
    }"
  >
    <div
      class="flex gap-2.5 w-full"
      :class="{
        'items-start': !isCentered,
        'justify-center items-center': isCentered,
        [profileImageAndNameLayoutClass]: true,
      }"
    >
      <div
        class="flex items-center justify-center overflow-hidden flex-shrink-0"
        :class="profileImageClass"
      >
        <img
          :src="profile.imageUrl"
          alt=""
          class="object-cover w-16 h-16 flex-shrink-0"
          :style="{
            transform: !enableBookMeeting ? 'scale(1.2)' : '', // Scale up small images to prevent rounded corners from showing gaps
          }"
        />
      </div>

      <div
        class="flex flex-col w-full"
        :class="{
          'items-start': !isCentered,
          'justify-center items-center': isCentered,
        }"
      >
        <div
          class="flex flex-row w-full"
          :class="{
            'justify-center': isCentered,
          }"
        >
          <div
            class="flex flex-row justify-between"
            :class="{ 'w-full': !isCentered, 'gap-2': isCentered }"
          >
            <div class="flex flex-row items-center gap-2">
              <span
                class="text-xs font-semibold leading-loose text-zinc-800"
                :style="{
                  fontSize: fontSize + 'px',
                  fontWeight: boldness,
                }"
                :class="{ 'text-center': isCentered }"
              >
                {{ expertName(profile) }}
              </span>
              <img
                src="@/assets/shared/linkedin-icon.svg"
                alt=""
                class="object-cover shrink-0 w-6 h-6 cursor-pointer"
                @click="handleLinkedinClick(profile.linkedinUrl)"
              />
            </div>
            <div class="flex flex-row gap-1 items-center">
              <div
                v-if="availableInNext2Hours(profile.soonestAvailabilities)"
                class="relative flex flex-col items-center group"
              >
                <img
                  src="@/assets/expert-program/directory/calendar-badge.svg"
                  alt=""
                  class="w-5 h-5 object-contain"
                />
                <!-- Tooltip -->
                <div
                  class="absolute left-1/2 -translate-x-[80%] top-full mt-2 -ml-4 w-max max-w-xs px-2 py-1 text-xs text-white bg-gray-900 rounded-md opacity-0 group-hover:opacity-100 transition-opacity shadow-lg z-50 pointer-events-none"
                >
                  Available in next 2 hours
                </div>
              </div>
              <div
                v-if="profile.anyPastMeetings"
                class="relative flex flex-col rounded-md p-0.5 items-center bg-violet-100 group"
              >
                <div v-html="FIRE_ICON"></div>
                <!-- Tooltip -->
                <div
                  class="absolute left-1/2 -translate-x-[80%] top-full mt-2 -ml-4 w-max max-w-xs px-2 py-1 text-xs text-white bg-gray-900 rounded-md opacity-0 group-hover:opacity-100 transition-opacity shadow-lg z-50 pointer-events-none"
                >
                  Recent Meetings
                </div>
              </div>
              <div
                v-if="top3MeetingsExperts?.includes(profile)"
                class="relative flex flex-col rounded-md p-0.5 items-center bg-violet-100 group"
              >
                <div v-html="TROPHY_ICON"></div>

                <!-- Tooltip -->
                <div
                  class="absolute left-1/2 -translate-x-1/2 top-full mt-2 -ml-4 w-max max-w-xs px-2 py-1 text-xs text-white bg-gray-900 rounded-md opacity-0 group-hover:opacity-100 transition-opacity shadow-lg z-50 pointer-events-none"
                >
                  Top 3
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="flex flex-col items-center w-full max-w-full"
          :class="{
            'justify-center': isCentered,
          }"
        >
          <span
            class="text-xs font-semibold text-zinc-800 break-words overflow-hidden whitespace-normal w-full max-h-[45px] leading-[1.2] line-clamp-2"
            :style="{
              fontSize: fontSize + 'px',
              fontWeight: boldness,
            }"
            :class="{ 'text-center': isCentered }"
          >
            {{ expertTitleAndCompanyName(profile) }}
          </span>
        </div>
      </div>
    </div>
    <div
      class="flex flex-col justify-end w-full gap-3 mt-2"
      :class="{
        'items-center': isCentered,
        'items-start': !isCentered,
        'mt-auto': !isGridUneven,
      }"
    >
      <div class="flex flex-col gap-2 items-end my-2">
        <div class="flex flex-row gap-2 items-center">
          <span class="font-bold">Expertise</span>
          <div class="flex flex-row gap-1">
            <div
              v-for="index in 5"
              :key="index"
              class="w-7 h-2"
              :style="{
                backgroundColor:
                  index <= profile.userProgramProfile[0].expertise
                    ? backgroundColor
                    : '#D1D5DB',
              }"
            ></div>
          </div>
        </div>
        <div class="flex flex-row gap-2 items-center">
          <span class="font-bold">Experience</span>
          <div class="flex flex-row gap-1">
            <div
              v-for="index in 5"
              :key="index"
              class="w-7 h-2"
              :style="{
                backgroundColor:
                  index <= profile.userProgramProfile[0].experience
                    ? backgroundColor
                    : '#D1D5DB',
              }"
            ></div>
          </div>
        </div>
      </div>
      <div
        class="w-full flex gap-1"
        :class="{
          'flex-col': isCentered,
          'flex-row': !isCentered,
        }"
      >
        <div
          v-if="getCountryCode(profile.country)"
          class="relative w-fit px-1.5 py-0.5 my-auto rounded-md bg-slate-50 flex-shrink-0 group"
          :class="{
            'left-1/2 translate-x-[-40%]': isCentered,
          }"
        >
          <span
            :class="`fi fi-${getCountryCodeForFlagIcon(profile.country)}`"
          ></span>
          <div
            class="absolute left-1/2 top-full mt-1 -ml-4 w-max max-w-xs px-2 py-1 text-xs text-white bg-gray-900 rounded-md opacity-0 group-hover:opacity-100 transition-opacity shadow-lg z-50 pointer-events-none"
            :class="{
              '-translate-x-1/2': isCentered,
              'translate-x-[10%]': !isCentered,
            }"
          >
            Location: {{ profile.country }}
          </div>
        </div>

        <div class="overflow-x-auto overflow-y-hidden scrollbar-thin">
          <div
            class="flex flex-row gap-1 items-center min-w-min whitespace-nowrap"
            :class="{
              'justify-center': isCentered,
            }"
          >
            <div
              v-for="(language, index) in profile.spokenLanguages"
              :key="index"
              class="flex-shrink-0"
            >
              <span
                class="px-1.5 py-0.5 whitespace-nowrap rounded-md bg-slate-50 inline-block"
                :style="{ fontWeight: boldness }"
              >
                {{ language }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div
        class="flex flex-row w-full mx-auto items-center justify-center gap-1"
      >
        <div
          v-for="availability in profile.soonestAvailabilities"
          :key="availability.startTime"
          class="flex flex-col px-2 py-1 rounded-md text-xxs cursor-pointer"
          :style="{
            backgroundColor: backgroundColor,
            color: textColor,
          }"
          @click="openBookAMeetingView(availability.startTime)"
        >
          {{ formatDateTime(availability.startTime) }}
        </div>
      </div>

      <div class="w-full" :class="{ 'flex justify-center': isCentered }">
        <button
          @click="handleBookMeeting(profile.uuid)"
          class="overflow-hidden gap-2 w-full py-1.5 pr-2.5 pl-2.5 mt-2.5 font-medium rounded shadow-sm"
          :disabled="!enableBookMeeting"
          :style="{
            backgroundColor: backgroundColor,
            color: textColor,
          }"
        >
          Find More Meeting Times
        </button>
      </div>
    </div>
  </article>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  getCurrentInstance,
  PropType,
  ref,
} from "vue";
import { PostHog } from "posthog-js";
import { getCountryCode } from "@/utils/countryUtils";
import { FIRE_ICON, TROPHY_ICON } from "@/assets/svg/shared/svg-constants";
import "/node_modules/flag-icons/css/flag-icons.min.css";
import { SoonestAvailability } from "@/types/directory-types";

interface UserProgramProfile {
  expertise: number;
  experience: number;
}

interface Profile {
  id: string;
  uuid: string;
  firstName: string;
  lastName: string;
  email: string;
  title: string;
  companyName: string;
  imageUrl: string;
  linkedinUrl: string;
  country: string;
  anyPastMeetings: boolean;
  meetingsCount: number;
  spokenLanguages: string[];
  soonestAvailabilities: SoonestAvailability[];
  userProgramProfile: UserProgramProfile[];
}

export default defineComponent({
  name: "ProfileCard",
  components: {
    // FlagIcon,
  },
  props: {
    profile: {
      type: Object as PropType<Profile>,
      required: true,
    },
    profileImageClass: {
      type: String,
      default: "",
    },
    profileImageAndNameLayoutClass: {
      type: String,
      default: "",
    },
    isCentered: {
      type: Boolean,
      default: false,
    },
    isGridUneven: {
      type: Boolean,
      default: false,
    },
    fontFamily: {
      type: String,
      default: "",
    },
    boldness: {
      type: String,
      default: "",
    },
    textColor: {
      type: String,
      default: "",
    },
    backgroundColor: {
      type: String,
      default: "",
    },
    fontSize: {
      type: String,
      default: "",
    },
    programUuid: {
      type: String,
      default: "",
    },
    enableBookMeeting: {
      type: Boolean,
      default: false,
    },
    programLogo: {
      type: String,
      default: "",
    },
    top3MeetingsExperts: {
      type: Array as PropType<Profile[]>,
      required: false,
    },
  },
  setup(props) {
    const baseHeight = 240;
    const isSeeMoreModalOpen = ref(false);
    const posthog = (getCurrentInstance()?.proxy as { $posthog: PostHog })
      ?.$posthog;

    const truncateText = (text: string, maxLength = 180) => {
      if (text.length > maxLength) {
        return text.slice(0, maxLength) + "...";
      }
      return text;
    };

    const isTextTruncated = (text: string, maxLength = 180) => {
      return text.length > maxLength;
    };

    const imageUrl = (profile: Profile) => {
      return profile.imageUrl;
    };

    const expertName = (profile: Profile) => {
      return `${profile.firstName} ${profile.lastName}`;
    };

    const handleBookMeeting = (expertId: string) => {
      if (posthog) {
        try {
          posthog.capture("Attendee_User_Clicks_In_Directory", {
            email: props.profile.email,
            program_uuid: props.programUuid,
          });
          const url = `${process.env.VUE_APP_BASE_URL}company/expert-meeting/book-meeting?expert=${expertId}&program=${props.programUuid}&logo=${props.programLogo}`;
          window.open(url, "_blank");
        } catch (error) {
          console.error("Error capturing posthog event: ", error);
        }
      } else {
        console.error("PostHog is not initialized");
      }
    };

    const handleLinkedinClick = (linkedinProfile: string) => {
      if (linkedinProfile) {
        if (
          !linkedinProfile.startsWith("http://") &&
          !linkedinProfile.startsWith("https://")
        ) {
          linkedinProfile = `https://${linkedinProfile}`;
        }

        window.open(linkedinProfile, "_blank");
      }
    };

    const handleSeeMore = () => {
      isSeeMoreModalOpen.value = true;
    };

    const computedHeight = computed(() => {
      let extraHeight = 0;
      if (props.isCentered) {
        extraHeight += 40;
      }
      if (props.profileImageAndNameLayoutClass === "flex-col") {
        extraHeight += 40;
      }
      if (props.fontSize === "12") {
        extraHeight += 20;
      } else if (props.fontSize === "14") {
        extraHeight += 25;
      } else if (props.fontSize === "16") {
        extraHeight += 30;
      } else if (props.fontSize === "18") {
        extraHeight += 35;
      } else if (props.fontSize === "20") {
        extraHeight += 45;
      }
      return `${baseHeight + extraHeight}px`;
    });

    const expertTitleAndCompanyName = (profile: Profile) => {
      if (profile.title && profile.companyName) {
        return `${profile.title}, ${profile.companyName}`;
      } else if (profile.title) {
        return profile.title;
      } else if (profile.companyName) {
        return profile.companyName;
      }
      return "";
    };

    const getCountryCodeForFlagIcon = (country: string) => {
      const countryCode = getCountryCode(country) || "US";
      return countryCode.toLowerCase();
    };

    const formatDateTime = (dateTimeString: string): string => {
      const date = new Date(dateTimeString);

      const month = date.toLocaleString("en-US", { month: "short" });
      const day = date.getDate();

      let formattedStart = new Intl.DateTimeFormat("en-US", {
        hour: "numeric",
        minute: "2-digit",
        hour12: true,
      }).format(date);

      if (formattedStart.endsWith(" AM") || formattedStart.endsWith(" PM")) {
        formattedStart = formattedStart.slice(0, -3);
      }

      const endDate = new Date(date.getTime() + 30 * 60000);
      const endTime = endDate.toLocaleString("en-US", {
        hour: "numeric",
        minute: "2-digit",
        hour12: true,
      });

      return `${month} ${day} | ${formattedStart}-${endTime}`;
    };

    const openBookAMeetingView = (startTime: string) => {
      if (!props.enableBookMeeting) return;

      const date = startTime.split(" ")[0];
      const time = startTime.split(" ")[1];
      const url = `${process.env.VUE_APP_BASE_URL}company/expert-meeting/book-meeting?expert=${props.profile.uuid}&program=${props.programUuid}&logo=${props.programLogo}&startTime=${time}&date=${date}`;
      window.open(url, "_blank");
    };

    const availableInNext2Hours = (
      soonestAvailabilities: SoonestAvailability[]
    ) => {
      if (!soonestAvailabilities?.length) return false;

      const now = new Date();
      const twoHoursFromNow = new Date(now.getTime() + 2 * 60 * 60 * 1000);
      return soonestAvailabilities.some(
        (availability) => new Date(availability.startTime) <= twoHoursFromNow
      );
    };

    return {
      truncateText,
      imageUrl,
      computedHeight,
      expertName,
      isSeeMoreModalOpen,
      isTextTruncated,
      handleSeeMore,
      handleBookMeeting,
      handleLinkedinClick,
      expertTitleAndCompanyName,
      getCountryCode,
      openBookAMeetingView,
      getCountryCodeForFlagIcon,
      formatDateTime,
      availableInNext2Hours,
      FIRE_ICON,
      TROPHY_ICON,
    };
  },
});
</script>
