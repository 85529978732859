import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, withKeys as _withKeys, normalizeClass as _normalizeClass } from "vue"
import _imports_0 from '@/assets/shared/failure.svg'


const _hoisted_1 = { class: "bg-white min-h-screen flex flex-col md:flex-row items-center md:items-start" }
const _hoisted_2 = { class: "flex flex-col max-sm:items-center xs:border xs:border-gray-200 max-w-[400px] py-2" }
const _hoisted_3 = { class: "flex flex-row w-full items-center justify-between px-4" }
const _hoisted_4 = { class: "flex flex-col" }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = {
  key: 0,
  class: "flex flex-col items-center gap-4"
}
const _hoisted_7 = { class: "flex flex-col px-4" }
const _hoisted_8 = { class: "flex flex-col items-end" }
const _hoisted_9 = { class: "flex flex-row items-center gap-2 px-4" }
const _hoisted_10 = { class: "flex items-center w-full px-4 mb-8" }
const _hoisted_11 = { class: "relative w-full" }
const _hoisted_12 = ["innerHTML"]
const _hoisted_13 = {
  key: 0,
  class: "absolute left-0 right-0 mt-1 bg-white border border-gray-200 z-10 max-h-40 overflow-y-auto"
}
const _hoisted_14 = ["onClick"]
const _hoisted_15 = {
  key: 1,
  class: "absolute left-0 right-0 mt-1 bg-white border border-gray-200 z-10 max-h-40 overflow-y-auto"
}
const _hoisted_16 = ["onClick"]
const _hoisted_17 = { class: "flex flex-col w-full text-left gap-2 mb-8" }
const _hoisted_18 = { class: "flex flex-row px-4 items-center gap-2" }
const _hoisted_19 = ["innerHTML"]
const _hoisted_20 = { class: "flex flex-col items-start px-4 gap-2" }
const _hoisted_21 = {
  key: 0,
  class: "flex flex-col w-full text-left rounded-md px-4"
}
const _hoisted_22 = { class: "text-gray-500 text-sm font-normal leading-5" }
const _hoisted_23 = { class: "flex flex-col px-4" }
const _hoisted_24 = { class: "relative" }
const _hoisted_25 = { class: "absolute rounded-md inset-0 w-full h-full pointer-events-none overflow-hidden" }
const _hoisted_26 = ["innerHTML"]
const _hoisted_27 = { class: "flex flex-row justify-between" }
const _hoisted_28 = ["innerHTML"]
const _hoisted_29 = { class: "flex flex-col items-center" }
const _hoisted_30 = { class: "flex flex-col items-center w-full" }
const _hoisted_31 = {
  key: 0,
  class: "fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
}
const _hoisted_32 = {
  key: 1,
  class: "flex justify-center mb-4"
}
const _hoisted_33 = {
  role: "alert",
  class: "flex gap-2 w-1/2 justify-center p-2.5 mt-6 text-sm leading-5 text-center text-red-900 bg-red-50 rounded-lg max-md:flex-wrap",
  style: {"max-height":"80px","overflow-y":"auto"}
}
const _hoisted_34 = { class: "flex flex-wrap items-center md:px-1 text-xs whitespace-nowrap text-zinc-700" }
const _hoisted_35 = {
  key: 0,
  class: "flex flex-col gap-1 text-base justify-center items-center self-stretch py-2.5 pr-4 pl-4 mx-auto text-violet-600 rounded-md"
}
const _hoisted_36 = { key: 1 }
const _hoisted_37 = {
  key: 0,
  class: "flex gap-2 bg-gray-100 p-0.5 md:p-6 justify-center w-full max-md:max-w-full"
}
const _hoisted_38 = { key: 1 }
const _hoisted_39 = { class: "flex flex-col items-center mt-2" }
const _hoisted_40 = {
  key: 2,
  class: "flex flex-col items-center justify-center"
}
const _hoisted_41 = { class: "w-10 h-10 flex justify-center items-center" }
const _hoisted_42 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_BaseInput = _resolveComponent("BaseInput")!
  const _component_ProfileCard = _resolveComponent("ProfileCard")!

  return (_openBlock(), _createElementBlock("main", _hoisted_1, [
    _createElementVNode("section", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[2] || (_cache[2] = _createElementVNode("div", { class: "flex flex-col" }, [
          _createElementVNode("span", { class: "text-gray-800 text-lg leading-7 font-medium" }, "Filters")
        ], -1)),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", {
            innerHTML: _ctx.HAMBURGER_MENU_ICON,
            class: "w-5 h-5",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showFilters = !_ctx.showFilters))
          }, null, 8, _hoisted_5)
        ])
      ]),
      (_ctx.showFilters)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, [
                _createVNode(_component_BaseButton, {
                  class: "text-lg leading-7 font-medium",
                  onClick: _ctx.clearAllFilters,
                  style: _normalizeStyle({ color: _ctx.backgroundColor })
                }, {
                  default: _withCtx(() => _cache[3] || (_cache[3] = [
                    _createTextVNode(" Clear all ")
                  ])),
                  _: 1
                }, 8, ["onClick", "style"])
              ])
            ]),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.searchBars, (searchConfig, index) => {
              return (_openBlock(), _createElementBlock("div", {
                key: index,
                class: "mt-4 flex flex-col w-full gap-3"
              }, [
                _createElementVNode("div", _hoisted_9, [
                  (searchConfig.icon)
                    ? (_openBlock(), _createBlock(_resolveDynamicComponent(searchConfig.icon), {
                        key: 0,
                        class: "w-5 h-5",
                        primaryColor: _ctx.backgroundColor
                      }, null, 8, ["primaryColor"]))
                    : _createCommentVNode("", true),
                  _createElementVNode("span", {
                    class: "text-base font-medium leading-5 tracking-wide",
                    style: _normalizeStyle({ color: _ctx.backgroundColor })
                  }, _toDisplayString(searchConfig.label), 5)
                ]),
                _createElementVNode("div", _hoisted_10, [
                  _createElementVNode("div", _hoisted_11, [
                    _createVNode(_component_BaseButton, {
                      class: "absolute left-3 top-1/2 -translate-y-1/2 text-gray-500 focus:outline-none",
                      type: "button",
                      onClick: ($event: any) => (_ctx.filterBySearch(searchConfig.id))
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", {
                          innerHTML: _ctx.SEARCH_ICON,
                          class: "w-5 h-5"
                        }, null, 8, _hoisted_12)
                      ]),
                      _: 2
                    }, 1032, ["onClick"]),
                    _createVNode(_component_BaseInput, {
                      id: searchConfig.id,
                      type: "text",
                      placeholder: searchConfig.placeholder,
                      className: "w-full pl-10 pr-24 py-2 border border-gray-300 rounded-lg focus:outline-none",
                      modelValue: searchConfig.modelValue.value,
                      "onUpdate:modelValue": ($event: any) => ((searchConfig.modelValue.value) = $event),
                      onInput: ($event: any) => (_ctx.handleInput(searchConfig)),
                      onKeyup: _withKeys(($event: any) => (_ctx.filterBySearch(searchConfig.id)), ["enter"])
                    }, null, 8, ["id", "placeholder", "modelValue", "onUpdate:modelValue", "onInput", "onKeyup"]),
                    _createVNode(_component_BaseButton, {
                      class: "absolute right-1 top-1/2 -translate-y-1/2 px-2 py-1 rounded-md hover:bg-primary-purple/90 hover:text-white focus:outline-none",
                      style: _normalizeStyle({
                  color: _ctx.textColor,
                  backgroundColor: _ctx.backgroundColor,
                }),
                      type: "button",
                      onClick: ($event: any) => (_ctx.filterBySearch(searchConfig.id))
                    }, {
                      default: _withCtx(() => _cache[4] || (_cache[4] = [
                        _createTextVNode(" Search ")
                      ])),
                      _: 2
                    }, 1032, ["style", "onClick"]),
                    (
                  searchConfig.id === 'language' &&
                  searchConfig.modelValue.value &&
                  _ctx.showLanguageSuggestions
                )
                      ? (_openBlock(), _createElementBlock("ul", _hoisted_13, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filterLanguages(
                    searchConfig.modelValue.value
                  ), (language) => {
                            return (_openBlock(), _createElementBlock("li", {
                              key: language,
                              onClick: ($event: any) => (_ctx.selectLanguage(searchConfig, language)),
                              class: "px-4 py-2 hover:bg-gray-100 cursor-pointer"
                            }, _toDisplayString(language), 9, _hoisted_14))
                          }), 128))
                        ]))
                      : _createCommentVNode("", true),
                    (
                  searchConfig.id === 'location' &&
                  searchConfig.modelValue.value &&
                  _ctx.showLocationSuggestions
                )
                      ? (_openBlock(), _createElementBlock("ul", _hoisted_15, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filterCountries(
                    searchConfig.modelValue.value
                  ), (country) => {
                            return (_openBlock(), _createElementBlock("li", {
                              key: country,
                              onClick: ($event: any) => (_ctx.selectCountry(searchConfig, country)),
                              class: "px-4 py-2 hover:bg-gray-100 cursor-pointer"
                            }, _toDisplayString(country), 9, _hoisted_16))
                          }), 128))
                        ]))
                      : _createCommentVNode("", true)
                  ])
                ]),
                _cache[5] || (_cache[5] = _createElementVNode("hr", { class: "border-t border-gray-200" }, null, -1))
              ]))
            }), 128)),
            _createElementVNode("div", _hoisted_17, [
              _createElementVNode("div", _hoisted_18, [
                _createElementVNode("div", {
                  innerHTML: _ctx.SECONDARY_CALENDAR_ICON,
                  class: "w-5 h-5",
                  style: _normalizeStyle({ color: _ctx.backgroundColor })
                }, null, 12, _hoisted_19),
                _createElementVNode("span", {
                  class: "text-base leading-5 tracking-wide",
                  style: _normalizeStyle({ color: _ctx.backgroundColor })
                }, " Soonest availability ", 4)
              ]),
              _createElementVNode("div", _hoisted_20, [
                _createElementVNode("span", {
                  class: "text-sm font-medium cursor-pointer leading-5 tracking-wide rounded-full px-2 py-1 bg-gray-100",
                  style: _normalizeStyle({
                color: _ctx.filterBySoonestAvailability ? _ctx.textColor : '#9CA3AF',
                backgroundColor: _ctx.filterBySoonestAvailability
                  ? _ctx.backgroundColor
                  : '#E5E7EB',
              }),
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.applySoonestAvailabilityFilter()))
                }, " ASAP (Within next 2 hrs) ", 4)
              ]),
              (_ctx.profiles.length > 0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                    _createElementVNode("p", _hoisted_22, [
                      _cache[6] || (_cache[6] = _createTextVNode(" All times listed in ")),
                      _createElementVNode("span", null, _toDisplayString(_ctx.getCurrentTimeZoneInfo().timeZone), 1),
                      _cache[7] || (_cache[7] = _createTextVNode(" timezone. "))
                    ])
                  ]))
                : _createCommentVNode("", true),
              _cache[8] || (_cache[8] = _createElementVNode("hr", { class: "border-t border-gray-200 mt-8" }, null, -1))
            ]),
            _createElementVNode("div", _hoisted_23, [
              _createElementVNode("div", _hoisted_24, [
                _createElementVNode("div", _hoisted_25, [
                  _createElementVNode("div", {
                    innerHTML: _ctx.JOIN_EXPERT_PROGRAM_BACKGROUND,
                    class: "w-full h-full",
                    style: _normalizeStyle({
                  color: `color-mix(in srgb, ${_ctx.backgroundColor} 80%, black 20%)`,
                  backgroundColor: _ctx.backgroundColor,
                })
                  }, null, 12, _hoisted_26)
                ]),
                _createElementVNode("div", {
                  class: "relative flex flex-col gap-2 px-4 py-2 rounded-md text-left justify-between bg-no-repeat bg-cover",
                  style: _normalizeStyle({ color: _ctx.textColor })
                }, [
                  _createElementVNode("div", _hoisted_27, [
                    _cache[9] || (_cache[9] = _createElementVNode("span", { class: "text-2xl leading-9 font-medium" }, "Are you an Expert?", -1)),
                    _createElementVNode("div", {
                      innerHTML: _ctx.DOLLAR_ICON,
                      class: "w-8 h-8",
                      style: _normalizeStyle({ color: _ctx.textColor })
                    }, null, 12, _hoisted_28)
                  ]),
                  _cache[11] || (_cache[11] = _createElementVNode("div", { class: "flex flex-col" }, [
                    _createElementVNode("span", { class: "text-sm leading-5 font-medium" }, "Earn with your expertise")
                  ], -1)),
                  _createElementVNode("div", _hoisted_29, [
                    _createVNode(_component_BaseButton, {
                      type: "button",
                      onClick: _ctx.navigateToExpertProgramOverviewPage,
                      class: "px-4 py-2 w-full border border-gray-300 rounded-md bg-transparent text-xs sm:text-sm font-medium leading-5 bg-white",
                      style: _normalizeStyle({ color: _ctx.backgroundColor })
                    }, {
                      default: _withCtx(() => _cache[10] || (_cache[10] = [
                        _createTextVNode(" Join our Expert Program ")
                      ])),
                      _: 1
                    }, 8, ["onClick", "style"])
                  ])
                ], 4)
              ])
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("section", _hoisted_30, [
      (_ctx.isLoading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_31, _cache[12] || (_cache[12] = [
            _createElementVNode("div", { class: "animate-spin rounded-full h-32 w-32 border-t-4 border-b-4 border-primary-purple" }, null, -1)
          ])))
        : _createCommentVNode("", true),
      (_ctx.errorMessage)
        ? (_openBlock(), _createElementBlock("div", _hoisted_32, [
            _createElementVNode("div", _hoisted_33, [
              _cache[13] || (_cache[13] = _createElementVNode("div", { class: "flex-shrink-0 w-6 h-6 flex items-center justify-center rounded-full" }, [
                _createElementVNode("img", {
                  src: _imports_0,
                  alt: "Error Icon",
                  class: "w-4 h-4"
                })
              ], -1)),
              _createElementVNode("p", null, _toDisplayString(_ctx.errorMessage), 1)
            ])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_34, [
        (_ctx.isNoExpertsAvailable())
          ? (_openBlock(), _createElementBlock("div", _hoisted_35, [
              _createElementVNode("span", {
                style: _normalizeStyle({ color: _ctx.backgroundColor })
              }, _toDisplayString(_ctx.noExpertAvailableText()), 5)
            ]))
          : _createCommentVNode("", true),
        (_ctx.profiles.length > 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_36, [
              (_ctx.isGridUneven)
                ? (_openBlock(), _createElementBlock("div", _hoisted_37, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columns, (n) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: n,
                        class: "space-y-3"
                      }, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.profiles.filter(
                  (_, index) => index % _ctx.columns === n - 1
                ), (profile) => {
                          return (_openBlock(), _createBlock(_component_ProfileCard, {
                            key: profile.id,
                            profile: profile,
                            isCentered: _ctx.profileCenterAligned,
                            isGridUneven: _ctx.isGridUneven,
                            profileImageClass: _ctx.profileImageClass,
                            profileImageAndNameLayoutClass: _ctx.profileImageAndNameLayoutClass,
                            fontFamily: _ctx.fontFamily,
                            boldness: _ctx.boldness,
                            textColor: _ctx.textColor,
                            backgroundColor: _ctx.backgroundColor,
                            fontSize: _ctx.fontSize,
                            enableBookMeeting: true,
                            programLogo: _ctx.programLogo,
                            top3MeetingsExperts: _ctx.top3MeetingsExperts,
                            programUuid: _ctx.programUuid as string,
                            class: "flex-shrink-0"
                          }, null, 8, ["profile", "isCentered", "isGridUneven", "profileImageClass", "profileImageAndNameLayoutClass", "fontFamily", "boldness", "textColor", "backgroundColor", "fontSize", "programLogo", "top3MeetingsExperts", "programUuid"]))
                        }), 128))
                      ]))
                    }), 128))
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_38, [
                    _createElementVNode("div", {
                      class: _normalizeClass(["flex flex-wrap bg-gray-100 p-0.5 md:p-6 justify-center w-full max-md:max-w-full", _ctx.profileCardsGap])
                    }, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.profiles, (profile) => {
                        return (_openBlock(), _createBlock(_component_ProfileCard, {
                          key: profile.id,
                          profile: profile,
                          isCentered: _ctx.profileCenterAligned,
                          isGridUneven: _ctx.isGridUneven,
                          profileImageClass: _ctx.profileImageClass,
                          profileImageAndNameLayoutClass: _ctx.profileImageAndNameLayoutClass,
                          fontFamily: _ctx.fontFamily,
                          boldness: _ctx.boldness,
                          textColor: _ctx.textColor,
                          backgroundColor: _ctx.backgroundColor,
                          fontSize: _ctx.fontSize,
                          enableBookMeeting: true,
                          programLogo: _ctx.programLogo,
                          top3MeetingsExperts: _ctx.top3MeetingsExperts,
                          programUuid: _ctx.programUuid as string,
                          class: "flex-shrink-0"
                        }, null, 8, ["profile", "isCentered", "isGridUneven", "profileImageClass", "profileImageAndNameLayoutClass", "fontFamily", "boldness", "textColor", "backgroundColor", "fontSize", "programLogo", "top3MeetingsExperts", "programUuid"]))
                      }), 128))
                    ], 2)
                  ]))
            ]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_39, [
        (_ctx.hasMoreExperts)
          ? (_openBlock(), _createBlock(_component_BaseButton, {
              key: 0,
              type: "button",
              onClick: _ctx.loadMoreExperts,
              class: "flex overflow-hidden flex-wrap gap-3 justify-center items-center self-stretch py-2.5 pr-4 pl-4 my-auto text-violet-600 rounded-md max-md:max-w-full"
            }, {
              default: _withCtx(() => _cache[14] || (_cache[14] = [
                _createElementVNode("span", { class: "shadow-sm hover:shadow-md transition-shadow duration-200 ease-in-out p-2 rounded-md" }, " Load More Experts ", -1)
              ])),
              _: 1
            }, 8, ["onClick"]))
          : _createCommentVNode("", true)
      ]),
      (_ctx.profiles.length > 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_40, [
            _cache[15] || (_cache[15] = _createElementVNode("div", { class: "flex flex-row justify-center" }, [
              _createElementVNode("p", { class: "text-gray-500 text-sm font-normal leading-5 text-shadow-md" }, [
                _createTextVNode(" Powered by "),
                _createElementVNode("span", { class: "underline" }, "SlashExperts.com")
              ])
            ], -1)),
            _createElementVNode("div", _hoisted_41, [
              _createElementVNode("div", { innerHTML: _ctx.COMPANY_LOGO }, null, 8, _hoisted_42)
            ])
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}