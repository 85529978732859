import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "flex flex-col" }
const _hoisted_2 = {
  key: 0,
  class: "fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
}
const _hoisted_3 = { class: "flex flex-wrap w-full p-6 gap-4" }
const _hoisted_4 = { class: "text-gray-900 font-inter text-lg font-medium leading-6 text-left break-words line-clamp-4" }
const _hoisted_5 = { class: "flex flex-col gap-3" }
const _hoisted_6 = { class: "text-gray-500 font-inter line-clamp-2 text-sm font-normal leading-5 text-left break-words" }
const _hoisted_7 = { class: "flex flex-col" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseButton = _resolveComponent("BaseButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.companyProgramsQueryLoading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _cache[0] || (_cache[0] = [
          _createElementVNode("div", { class: "animate-spin rounded-full h-32 w-32 border-t-4 border-b-4 border-primary-purple" }, null, -1)
        ])))
      : _createCommentVNode("", true),
    _cache[2] || (_cache[2] = _createElementVNode("div", { class: "flex flex-col w-full p-6" }, [
      _createElementVNode("span", { class: "text-black text-3xl font-semibold leading-9 text-left" }, "Programs")
    ], -1)),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.companyPrograms, (program, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: program.id,
          class: "flex flex-col flex-1 justify-between gap-4 rounded-lg border border-gray-300 p-4 h-50 max-w-[220px]"
        }, [
          _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.truncateText(program.name, 25)), 1),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.truncateText(program.description || "", 40)), 1),
            _createElementVNode("div", _hoisted_7, [
              _createVNode(_component_BaseButton, {
                type: "button",
                onClick: ($event: any) => (_ctx.handleViewProgram(index)),
                class: "flex flex-col justify-center items-center p-3 w-full bg-violet-100 text-primary-purple rounded-md text-sm font-medium leading-none text-center"
              }, {
                default: _withCtx(() => _cache[1] || (_cache[1] = [
                  _createTextVNode(" View Detail ")
                ])),
                _: 2
              }, 1032, ["onClick"])
            ])
          ])
        ]))
      }), 128))
    ])
  ]))
}