<template>
  <div
    v-if="isRequestsLoading || isLoading"
    class="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
  >
    <!-- Spinner -->
    <div
      class="animate-spin rounded-full h-32 w-32 border-t-4 border-b-4 border-primary-purple"
    ></div>
  </div>
  <table class="min-w-full border border-gray-300 mb-8">
    <thead class="justify-between">
      <tr class="border-b border-gray-300">
        <th
          class="px-4 py-2 text-left text-gray-500 text-xs font-medium tracking-wider leading-4"
        >
          Name
        </th>
        <th
          class="px-4 py-2 text-left text-gray-500 text-xs font-medium tracking-wider leading-4"
        >
          Email
        </th>
        <th
          class="px-4 py-2 text-left text-gray-500 text-xs font-medium tracking-wider leading-4"
        >
          Total Thank You Payouts
        </th>
        <th
          class="px-4 py-2 text-left text-gray-500 text-xs font-medium tracking-wider leading-4"
        >
          Status
        </th>
        <th
          class="px-4 py-2 text-center text-gray-500 text-xs font-medium tracking-wider leading-4"
        >
          Actions
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="(row, index) in requestsRows"
        :key="row.id"
        class="hover:bg-gray-50 text-left"
      >
        <td
          class="px-4 py-2 text-gray-900 text-sm font-medium leading-5 border-b"
        >
          <div class="flex flex-col">
            <span>{{ row.user.firstName + " " + row.user.lastName }}</span>
          </div>
        </td>
        <td
          class="px-4 py-2 text-gray-900 text-sm font-medium leading-5 border-b"
        >
          <div class="flex flex-col">
            <span>{{ row.user.email }}</span>
          </div>
        </td>
        <td class="px-4 py-2 font-medium text-xs leading-4 border-b">--</td>
        <td class="px-4 py-2 font-medium text-xs leading-4 border-b">
          <span class="px-2 rounded-lg text-gray-800 bg-orange-100"
            >Applied</span
          >
        </td>
        <td class="flex justify-center px-4 py-2 border-b">
          <BaseButton
            type="button"
            class="flex justify-center text-xs items-center py-2 px-4 rounded-md bg-indigo-100 text-primary-purple"
            style="width: auto"
            @click="handleReviewEntry(index)"
          >
            Approve
          </BaseButton>
        </td>
      </tr>
      <tr
        v-for="(row, index) in expertsRows"
        :key="row.id"
        class="hover:bg-gray-50 text-left"
        :class="{
          'opacity-50': shouldDisable(row.joinProgramRequest?.[0]?.status),
        }"
      >
        <td
          class="px-4 py-2 text-gray-900 text-sm font-medium leading-5 border-b"
        >
          <div class="flex flex-col">
            <span>{{ row.firstName }} {{ row.lastName }}</span>
          </div>
        </td>
        <td
          class="px-4 py-2 text-gray-900 text-sm font-medium leading-5 border-b"
        >
          <div class="flex flex-col">
            <span>{{ row.email }}</span>
          </div>
        </td>
        <td
          class="px-4 py-2 text-gray-900 text-sm font-medium leading-5 border-b"
        >
          <div class="flex flex-col">
            <span>${{ row.credits }}</span>
          </div>
        </td>
        <td class="px-4 py-2 font-medium text-xs leading-4 border-b">
          <span
            class="px-2 rounded-lg"
            :class="
              getClassForStatus(row.joinProgramRequest?.[0]?.status, row.active)
            "
            >{{
              expertStatusText(row.joinProgramRequest?.[0]?.status, row.active)
            }}</span
          >
        </td>
        <td
          class="px-4 py-2 text-gray-500 text-sm font-normal leading-5 border-b"
        >
          <div class="flex justify-center relative">
            <BaseButton
              type="button"
              class="dropdown-menu"
              style="width: auto"
              :disabled="shouldDisable(row.joinProgramRequest?.[0]?.status)"
              @click="toggleDropdown(row.id)"
            >
              <div v-html="DOTS_VERTICAL_ICON" class="ml-3 text-gray-800"></div>
            </BaseButton>
            <div
              v-if="isDropdownOpen === row.id"
              class="absolute left-0 top-full w-32 bg-white rounded shadow-lg border border-gray-200 z-20"
            >
              <BaseButton
                v-if="
                  status(row.joinProgramRequest?.[0]?.status) !== 'Interested'
                "
                type="button"
                @click="handleDelete(index, 'expert')"
                class="block w-full text-left px-4 py-2 text-gray-700 hover:bg-gray-100"
              >
                {{ expertButtonText(row.joinProgramRequest?.[0]?.status) }}
              </BaseButton>
              <BaseButton
                v-if="
                  status(row.joinProgramRequest?.[0]?.status) === 'Interested'
                "
                type="button"
                @click="handleSendInvitation(index)"
                class="block w-full text-left px-4 py-2 text-gray-700 hover:bg-gray-100"
              >
                Send Invite
              </BaseButton>
            </div>
          </div>
        </td>
      </tr>
      <tr
        v-for="row in invitationsRows"
        :key="row.id"
        :class="{ 'opacity-50': row.status === 'expired' }"
        class="hover:bg-gray-50 text-left"
      >
        <td
          class="px-4 py-2 text-gray-900 text-sm font-medium leading-5 border-b"
        >
          --
        </td>
        <td
          class="px-4 py-2 text-gray-900 text-sm font-medium leading-5 border-b"
        >
          {{ row.userEmail }}
        </td>
        <td
          class="px-4 py-2 text-gray-900 text-sm font-medium leading-5 border-b"
        >
          --
        </td>
        <td
          class="px-4 py-2 text-gray-900 text-sm font-medium leading-5 border-b"
        >
          <span
            class="px-2 rounded-lg"
            :class="{
              'text-green-800 bg-green-100': row.status === 'accepted',
              'text-red-800 bg-red-100': row.status === 'expired',
              'text-gray-600 bg-gray-200': row.status === 'pending',
            }"
            >{{ invitationStatus(row.status) }}</span
          >
        </td>
        <td
          class="px-4 py-2 text-gray-500 text-sm font-normal leading-5 border-b relative flex justify-center"
        >
          <BaseButton
            type="button"
            class="flex justify-center items-center dropdown-menu"
            style="width: auto"
            :disabled="
              expireInvitationLoading ||
              sendInvitationLoading ||
              row.status === 'expired' ||
              row.status === 'accepted'
            "
            @click="toggleDropdown(row.id)"
          >
            <div v-html="DOTS_VERTICAL_ICON" class="ml-3 text-gray-800"></div>
          </BaseButton>
          <div
            v-if="isDropdownOpen === row.id"
            class="absolute left-0 top-full w-32 bg-white rounded shadow-lg border border-gray-200 z-20"
          >
            <button
              @click="handleDelete(row.id, 'invitation')"
              class="block w-full text-left px-4 py-2 text-gray-700 hover:bg-gray-100"
            >
              Delete
            </button>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
  <ReviewEntryModal
    v-if="showReviewEntryModal"
    :isOpen="showReviewEntryModal"
    :onClose="() => (showReviewEntryModal = false)"
    :expert="requestsRows[selectedRequestIndex]?.user"
    :requestId="requestsRows[selectedRequestIndex]?.id"
    @refreshRequests="refreshExperts"
  />
</template>

<script lang="ts">
import { ApolloError, gql } from "@apollo/client/core";
import { useMutation, useQuery } from "@vue/apollo-composable";
import { defineComponent, onBeforeUnmount, onMounted, ref } from "vue";
import { Expert, Invitation } from "@/types/expert-programs-types";
import { useUserStore } from "@/stores/user";
import { DOTS_VERTICAL_ICON } from "@/assets/svg/shared/svg-constants";
import BaseButton from "@/components/shared/BaseButton.vue";
import { RequestRow } from "@/types/dashboard-types";
import ReviewEntryModal from "./ReviewEntryModal.vue";

const LIST_REQUESTS_QUERY = gql`
  query getCompanyJoinProgramRequest($status: Int!) {
    getCompanyJoinProgramRequest(status: $status) {
      id
      status
      createdAt
      user {
        id
        firstName
        lastName
        email
        uuid
        active
      }
    }
  }
`;

const LIST_INVITED_EXPERTS_QUERY = gql`
  query listProgramExperts($programId: ID!) {
    listProgramExperts(programId: $programId) {
      experts {
        id
        firstName
        lastName
        email
        active
        credits(programId: $programId)
        joinProgramRequest(programId: $programId) {
          id
          status
        }
      }
      invitations {
        id
        userEmail
        status
      }
    }
  }
`;

const SUSPEND_EXPERT_MUTATION = gql`
  mutation updateJoinProgramRequest($input: UpdateJoinProgramRequestInput!) {
    updateJoinProgramRequest(input: $input) {
      errors
    }
  }
`;

const EXPIRE_INVITATION_MUTATION = gql`
  mutation expireInvitation($input: ExpireInvitationInput!) {
    expireInvitation(input: $input) {
      success
      error
    }
  }
`;

const SEND_INVITATION_MUTATION = gql`
  mutation createInvitation($input: CreateInvitationInput!) {
    createInvitation(input: $input) {
      successMessage
      errors
    }
  }
`;

export default defineComponent({
  name: "ExpertsList",
  components: {
    BaseButton,
    ReviewEntryModal,
  },
  props: {
    handleError: {
      type: Function,
      required: true,
    },
    openProfileUuid: {
      type: String,
      required: false,
    },
  },
  setup(props) {
    const expertsRows = ref<Expert[]>([]);
    const invitationsRows = ref<Invitation[]>([]);
    const isDropdownOpen = ref<string | null>(null);
    const errorMessage = ref("");
    const isLoading = ref(false);
    const isRequestsLoading = ref(false);
    const userStore = useUserStore();
    const requestsRows = ref<RequestRow[]>([]);
    const showReviewEntryModal = ref<boolean>(false);
    const selectedRequestIndex = ref<number>(0);

    const {
      mutate: suspendExpert,
      onDone: suspendExpertDone,
      onError: suspendExpertError,
      loading: suspendExpertLoading,
    } = useMutation(SUSPEND_EXPERT_MUTATION);

    const {
      mutate: expireInvitation,
      onDone: expireInvitationDone,
      onError: expireInvitationError,
      loading: expireInvitationLoading,
    } = useMutation(EXPIRE_INVITATION_MUTATION);

    const {
      mutate: sendInvitation,
      onDone: sendInvitationDone,
      onError: sendInvitationError,
      loading: sendInvitationLoading,
    } = useMutation(SEND_INVITATION_MUTATION);

    const fetchRequests = () => {
      isRequestsLoading.value = true;
      const { onResult: onResultRequests, onError: onErrorRequests } = useQuery(
        LIST_REQUESTS_QUERY,
        { status: 0 },
        { fetchPolicy: "network-only" }
      );

      onResultRequests((result) => {
        if (result) {
          if (result.data) {
            requestsRows.value = result.data.getCompanyJoinProgramRequest;
            checkAutoProfileSelection();
          }
          isRequestsLoading.value = false;
        }
      });

      onErrorRequests((error) => {
        if (error) {
          props.handleError(error.message);
          isRequestsLoading.value = false;
        }
      });
    };

    const checkAutoProfileSelection = () => {
      if (props.openProfileUuid) {
        const profile = requestsRows.value.find(
          (row) => row.user.uuid === props.openProfileUuid
        );
        if (profile) {
          handleReviewEntry(requestsRows.value.indexOf(profile));
        }
      }
    };

    const convertToDate = (date: string) => {
      return new Date(date).toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    };

    const handleReviewEntry = (index: number) => {
      selectedRequestIndex.value = index;
      showReviewEntryModal.value = true;
    };

    const invitationStatus = (status: string) => {
      return status === "accepted"
        ? "Accepted"
        : status === "expired"
        ? "Expired"
        : "Invited";
    };

    const toggleDropdown = (id: string) => {
      isDropdownOpen.value = isDropdownOpen.value === id ? null : id;
    };

    const handleDelete = (id: string | number, type: string) => {
      isDropdownOpen.value = null;
      if (type === "expert") {
        handleSuspendExpert(id as number);
      } else {
        handleDeleteInvitation(id as string);
      }
    };

    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      if (!target.closest(".dropdown-menu")) {
        isDropdownOpen.value = null;
      }
    };

    const setStatus = (status: string) => {
      if (status === "archived") {
        return "approved";
      } else {
        return "archived";
      }
    };

    const handleSuspendExpert = (id: number) => {
      const modifyRequest = expertsRows.value[id].joinProgramRequest;
      suspendExpert({
        input: {
          requestParams: {
            requestId: modifyRequest[0].id,
            status: setStatus(modifyRequest[0].status),
          },
        },
      });

      suspendExpertDone((response) => {
        if (response) {
          if (response?.errors) {
            props.handleError("Failed to delete expert.");
          } else if (
            response?.data?.updateJoinProgramRequest?.errors?.length > 0
          ) {
            props.handleError("Failed to delete expert.");
          } else {
            fetchExperts();
          }
        }
      });

      suspendExpertError((error: ApolloError) => {
        if (error) {
          props.handleError(error.message);
        }
      });
    };

    const handleDeleteInvitation = (id: string) => {
      expireInvitation({ input: { id: id } });

      expireInvitationDone((response) => {
        if (response) {
          if (response.data.expireInvitation.success) {
            fetchExperts();
          } else {
            props.handleError(response.data.expireInvitation.error);
          }
        }
      });

      expireInvitationError((error: ApolloError) => {
        if (error) {
          props.handleError(error.message);
        }
      });
    };

    const handleSendInvitation = (id: number) => {
      sendInvitation({
        input: {
          inviteParams: {
            emails: [expertsRows.value[id].email],
            programId: userStore.selectedProgram?.id,
          },
          inviteType: "experts",
        },
      });

      sendInvitationDone((response) => {
        if (response) {
          if (response.errors) {
            props.handleError(response.errors.join(", "));
          } else if (response.data.createInvitation.errors.length > 0) {
            props.handleError(response.data.createInvitation.errors.join(", "));
          } else {
            fetchExperts();
          }
        }
      });

      sendInvitationError((error: ApolloError) => {
        if (error) {
          props.handleError(error.message);
        }
      });
    };

    const fetchExperts = () => {
      isLoading.value = true;
      const { onResult: onResultExperts, onError: onErrorExperts } = useQuery(
        LIST_INVITED_EXPERTS_QUERY,
        {
          programId: userStore.selectedProgram?.id,
        },
        { fetchPolicy: "network-only" }
      );

      onResultExperts((result) => {
        if (result) {
          if (result.data) {
            expertsRows.value = result.data.listProgramExperts.experts.filter(
              (expert: Expert) =>
                expert.joinProgramRequest?.[0]?.status !== "pending"
            );
            invitationsRows.value = result.data.listProgramExperts.invitations;
          }
          isLoading.value = false;
        }
      });

      onErrorExperts((error) => {
        if (error) {
          props.handleError(error.message);
          isLoading.value = false;
        }
      });
    };

    const shouldDisable = (joinProgramrequestStatus: string) => {
      return joinProgramrequestStatus === "rejected";
    };

    const expertStatusText = (
      joinRequestStatus: string,
      expertActive: boolean
    ) => {
      if (joinRequestStatus === "approved") {
        return expertActive ? "Active" : "Hidden - Calendar Disconnected";
      } else {
        return status(joinRequestStatus);
      }
    };

    const status = (status: string) => {
      if (status === "approved") {
        return "Active";
      } else if (status === "rejected") {
        return "Rejected";
      } else if (status === "pending") {
        return "Pending";
      } else if (status === "archived") {
        return "Inactive";
      } else {
        return "Interested";
      }
    };

    const getClassForStatus = (
      joinProgramRequestStatus: string,
      expertActive: boolean
    ) => {
      if (joinProgramRequestStatus === "approved") {
        return expertActive
          ? "text-green-800 bg-green-100"
          : "text-blue-800 bg-blue-100";
      } else if (
        joinProgramRequestStatus === "rejected" ||
        joinProgramRequestStatus === "archived"
      ) {
        return "text-red-800 bg-red-100";
      } else if (joinProgramRequestStatus === "pending") {
        return "text-gray-800 bg-gray-100";
      } else if (status(joinProgramRequestStatus) === "Interested") {
        return "text-yellow-800 bg-yellow-100";
      } else {
        return "";
      }
    };

    const expertButtonText = (status: string) => {
      if (status === "archived") {
        return "Reactivate";
      } else {
        return "Delete";
      }
    };

    const refreshExperts = () => {
      fetchExperts();
      fetchRequests();
    };

    onBeforeUnmount(() => {
      document.removeEventListener("click", handleClickOutside);
    });

    onMounted(() => {
      document.addEventListener("click", handleClickOutside);
      refreshExperts();
    });

    return {
      expertsRows,
      invitationsRows,
      errorMessage,
      suspendExpertLoading,
      expireInvitationLoading,
      sendInvitationLoading,
      expertButtonText,
      toggleDropdown,
      handleDelete,
      handleSendInvitation,
      invitationStatus,
      shouldDisable,
      getClassForStatus,
      DOTS_VERTICAL_ICON,
      isDropdownOpen,
      status,
      refreshExperts,
      isLoading,
      showReviewEntryModal,
      selectedRequestIndex,
      requestsRows,
      fetchRequests,
      handleReviewEntry,
      convertToDate,
      expertStatusText,
      isRequestsLoading,
    };
  },
});
</script>
