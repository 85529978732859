<template>
  <div
    class="flex flex-col text-left gap-3 border border-gray-300 border-solid px-4 py-6 rounded-md"
  >
    <img
      src="../../../../assets/shared/hubspot-logo.svg"
      alt="Hubspot Logo"
      class="w-20 h-auto mb-2"
    />
    <div class="flex flex-col gap-1">
      <span class="text-gray-900 text-xl font-semibold leading-7 tracking-wide"
        >Connect Hubspot</span
      >
      <span class="text-gray-500 text-sm font-normal leading-5 tracking-wide"
        >Connect Slash Expert to a Hubspot admin<br />
        and begin enriching your records.</span
      >
    </div>
    <div class="flex flex-col w-full">
      <BaseButton
        :disabled="loading"
        @click="connectHubspot()"
        type="button"
        class="flex flex-col justify-center items-center p-2 w-full rounded-lg text-sm font-medium leading-none text-center"
        :class="{
          'text-primary-purple bg-violet-200': hubspotSynced,
          'text-white bg-primary-purple': !hubspotSynced,
        }"
      >
        <span v-if="loading">Loading...</span>
        <span v-else>
          <span v-if="!hubspotSynced">Connect Now</span>
          <span v-else>View Details</span>
        </span>
      </BaseButton>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import BaseButton from "@/components/shared/BaseButton.vue";

export default defineComponent({
  name: "HubspotCard",
  components: { BaseButton },
  props: {
    hubspotSynced: {
      type: Boolean,
      required: true,
    },
    connectHubspot: {
      type: Function,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },
});
</script>
