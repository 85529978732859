<template>
  <main
    class="bg-white min-h-screen flex flex-col md:flex-row items-center md:items-start"
  >
    <section
      class="flex flex-col max-sm:items-center xs:border xs:border-gray-200 max-w-[400px] py-2"
    >
      <div class="flex flex-row w-full items-center justify-between px-4">
        <div class="flex flex-col">
          <span class="text-gray-800 text-lg leading-7 font-medium"
            >Filters</span
          >
        </div>
        <div class="flex flex-col">
          <div
            v-html="HAMBURGER_MENU_ICON"
            class="w-5 h-5"
            @click="showFilters = !showFilters"
          ></div>
        </div>
      </div>
      <div v-if="showFilters" class="flex flex-col items-center gap-4">
        <div class="flex flex-col px-4">
          <div class="flex flex-col items-end">
            <BaseButton
              class="text-lg leading-7 font-medium"
              @click="clearAllFilters"
              :style="{ color: backgroundColor }"
            >
              Clear all
            </BaseButton>
          </div>
        </div>

        <!-- search bar -->
        <div
          v-for="(searchConfig, index) in searchBars"
          :key="index"
          class="mt-4 flex flex-col w-full gap-3"
        >
          <div class="flex flex-row items-center gap-2 px-4">
            <component
              v-if="searchConfig.icon"
              :is="searchConfig.icon"
              class="w-5 h-5"
              :primaryColor="backgroundColor"
            />
            <span
              class="text-base font-medium leading-5 tracking-wide"
              :style="{ color: backgroundColor }"
            >
              {{ searchConfig.label }}
            </span>
          </div>
          <div class="flex items-center w-full px-4 mb-8">
            <div class="relative w-full">
              <BaseButton
                class="absolute left-3 top-1/2 -translate-y-1/2 text-gray-500 focus:outline-none"
                type="button"
                @click="filterBySearch(searchConfig.id)"
              >
                <div v-html="SEARCH_ICON" class="w-5 h-5"></div>
              </BaseButton>
              <BaseInput
                :id="searchConfig.id"
                type="text"
                :placeholder="searchConfig.placeholder"
                className="w-full pl-10 pr-24 py-2 border border-gray-300 rounded-lg focus:outline-none"
                v-model="searchConfig.modelValue.value"
                @input="handleInput(searchConfig)"
                @keyup.enter="filterBySearch(searchConfig.id)"
              />
              <BaseButton
                class="absolute right-1 top-1/2 -translate-y-1/2 px-2 py-1 rounded-md hover:bg-primary-purple/90 hover:text-white focus:outline-none"
                :style="{
                  color: textColor,
                  backgroundColor: backgroundColor,
                }"
                type="button"
                @click="filterBySearch(searchConfig.id)"
              >
                Search
              </BaseButton>

              <ul
                v-if="
                  searchConfig.id === 'language' &&
                  searchConfig.modelValue.value &&
                  showLanguageSuggestions
                "
                class="absolute left-0 right-0 mt-1 bg-white border border-gray-200 z-10 max-h-40 overflow-y-auto"
              >
                <li
                  v-for="language in filterLanguages(
                    searchConfig.modelValue.value
                  )"
                  :key="language"
                  @click="selectLanguage(searchConfig, language)"
                  class="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                >
                  {{ language }}
                </li>
              </ul>

              <ul
                v-if="
                  searchConfig.id === 'location' &&
                  searchConfig.modelValue.value &&
                  showLocationSuggestions
                "
                class="absolute left-0 right-0 mt-1 bg-white border border-gray-200 z-10 max-h-40 overflow-y-auto"
              >
                <li
                  v-for="country in filterCountries(
                    searchConfig.modelValue.value
                  )"
                  :key="country"
                  @click="selectCountry(searchConfig, country)"
                  class="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                >
                  {{ country }}
                </li>
              </ul>
            </div>
          </div>
          <hr class="border-t border-gray-200" />
        </div>

        <!-- soonest availability filter -->
        <div class="flex flex-col w-full text-left gap-2 mb-8">
          <div class="flex flex-row px-4 items-center gap-2">
            <div
              v-html="SECONDARY_CALENDAR_ICON"
              class="w-5 h-5"
              :style="{ color: backgroundColor }"
            ></div>
            <span
              class="text-base leading-5 tracking-wide"
              :style="{ color: backgroundColor }"
            >
              Soonest availability
            </span>
          </div>
          <div class="flex flex-col items-start px-4 gap-2">
            <span
              class="text-sm font-medium cursor-pointer leading-5 tracking-wide rounded-full px-2 py-1 bg-gray-100"
              :style="{
                color: filterBySoonestAvailability ? textColor : '#9CA3AF',
                backgroundColor: filterBySoonestAvailability
                  ? backgroundColor
                  : '#E5E7EB',
              }"
              @click="applySoonestAvailabilityFilter()"
            >
              ASAP (Within next 2 hrs)
            </span>
          </div>
          <div
            v-if="profiles.length > 0"
            class="flex flex-col w-full text-left rounded-md px-4"
          >
            <p class="text-gray-500 text-sm font-normal leading-5">
              All times listed in
              <span>{{ getCurrentTimeZoneInfo().timeZone }} </span>
              timezone.
            </p>
          </div>

          <hr class="border-t border-gray-200 mt-8" />
        </div>

        <!-- join our expert program area -->
        <div class="flex flex-col px-4">
          <div class="relative">
            <div
              class="absolute rounded-md inset-0 w-full h-full pointer-events-none overflow-hidden"
            >
              <div
                v-html="JOIN_EXPERT_PROGRAM_BACKGROUND"
                class="w-full h-full"
                :style="{
                  color: `color-mix(in srgb, ${backgroundColor} 80%, black 20%)`,
                  backgroundColor: backgroundColor,
                }"
              ></div>
            </div>
            <div
              class="relative flex flex-col gap-2 px-4 py-2 rounded-md text-left justify-between bg-no-repeat bg-cover"
              :style="{ color: textColor }"
            >
              <div class="flex flex-row justify-between">
                <span class="text-2xl leading-9 font-medium"
                  >Are you an Expert?</span
                >
                <div
                  v-html="DOLLAR_ICON"
                  class="w-8 h-8"
                  :style="{ color: textColor }"
                ></div>
              </div>
              <div class="flex flex-col">
                <span class="text-sm leading-5 font-medium"
                  >Earn with your expertise</span
                >
              </div>
              <div class="flex flex-col items-center">
                <BaseButton
                  type="button"
                  @click="navigateToExpertProgramOverviewPage"
                  class="px-4 py-2 w-full border border-gray-300 rounded-md bg-transparent text-xs sm:text-sm font-medium leading-5 bg-white"
                  :style="{ color: backgroundColor }"
                >
                  Join our Expert Program
                </BaseButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="flex flex-col items-center w-full">
      <div
        v-if="isLoading"
        class="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
      >
        <!-- Spinner -->
        <div
          class="animate-spin rounded-full h-32 w-32 border-t-4 border-b-4 border-primary-purple"
        ></div>
      </div>
      <div v-if="errorMessage" class="flex justify-center mb-4">
        <div
          role="alert"
          class="flex gap-2 w-1/2 justify-center p-2.5 mt-6 text-sm leading-5 text-center text-red-900 bg-red-50 rounded-lg max-md:flex-wrap"
          style="max-height: 80px; overflow-y: auto"
        >
          <div
            class="flex-shrink-0 w-6 h-6 flex items-center justify-center rounded-full"
          >
            <img
              src="@/assets/shared/failure.svg"
              alt="Error Icon"
              class="w-4 h-4"
            />
          </div>
          <p>{{ errorMessage }}</p>
        </div>
      </div>
      <div
        class="flex flex-wrap items-center md:px-1 text-xs whitespace-nowrap text-zinc-700"
      >
        <div
          v-if="isNoExpertsAvailable()"
          class="flex flex-col gap-1 text-base justify-center items-center self-stretch py-2.5 pr-4 pl-4 mx-auto text-violet-600 rounded-md"
        >
          <span :style="{ color: backgroundColor }">{{
            noExpertAvailableText()
          }}</span>
        </div>
        <div v-if="profiles.length > 0">
          <div
            v-if="isGridUneven"
            class="flex gap-2 bg-gray-100 p-0.5 md:p-6 justify-center w-full max-md:max-w-full"
          >
            <div v-for="n in columns" :key="n" class="space-y-3">
              <!-- Loop over the profiles array and place them in the respective column div based on index -->
              <ProfileCard
                v-for="profile in profiles.filter(
                  (_, index) => index % columns === n - 1
                )"
                :key="profile.id"
                :profile="profile"
                :isCentered="profileCenterAligned"
                :isGridUneven="isGridUneven"
                :profileImageClass="profileImageClass"
                :profileImageAndNameLayoutClass="profileImageAndNameLayoutClass"
                :fontFamily="fontFamily"
                :boldness="boldness"
                :textColor="textColor"
                :backgroundColor="backgroundColor"
                :fontSize="fontSize"
                :enableBookMeeting="true"
                :programLogo="programLogo"
                :top3MeetingsExperts="top3MeetingsExperts"
                :programUuid="programUuid as string"
                class="flex-shrink-0"
              />
            </div>
          </div>
          <div v-else>
            <div
              class="flex flex-wrap bg-gray-100 p-0.5 md:p-6 justify-center w-full max-md:max-w-full"
              :class="profileCardsGap"
            >
              <ProfileCard
                v-for="profile in profiles"
                :key="profile.id"
                :profile="profile"
                :isCentered="profileCenterAligned"
                :isGridUneven="isGridUneven"
                :profileImageClass="profileImageClass"
                :profileImageAndNameLayoutClass="profileImageAndNameLayoutClass"
                :fontFamily="fontFamily"
                :boldness="boldness"
                :textColor="textColor"
                :backgroundColor="backgroundColor"
                :fontSize="fontSize"
                :enableBookMeeting="true"
                :programLogo="programLogo"
                :top3MeetingsExperts="top3MeetingsExperts"
                :programUuid="programUuid as string"
                class="flex-shrink-0"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-col items-center mt-2">
        <BaseButton
          type="button"
          v-if="hasMoreExperts"
          @click="loadMoreExperts"
          class="flex overflow-hidden flex-wrap gap-3 justify-center items-center self-stretch py-2.5 pr-4 pl-4 my-auto text-violet-600 rounded-md max-md:max-w-full"
        >
          <span
            class="shadow-sm hover:shadow-md transition-shadow duration-200 ease-in-out p-2 rounded-md"
          >
            Load More Experts
          </span>
        </BaseButton>
      </div>
      <div
        v-if="profiles.length > 0"
        class="flex flex-col items-center justify-center"
      >
        <div class="flex flex-row justify-center">
          <p class="text-gray-500 text-sm font-normal leading-5 text-shadow-md">
            Powered by <span class="underline">SlashExperts.com</span>
          </p>
        </div>
        <div class="w-10 h-10 flex justify-center items-center">
          <div v-html="COMPANY_LOGO"></div>
        </div>
      </div>
    </section>
  </main>
</template>

<script lang="ts">
import { ApolloError, gql } from "@apollo/client/core";
import { useMutation, useQuery } from "@vue/apollo-composable";
import { defineComponent, onBeforeUnmount, onMounted, ref, watch } from "vue";
import { useRoute } from "vue-router";
import ProfileCard from "@/components/expert-program/directory/ProfileCard.vue";
import { EXPERTS_DIRECTORY_PAGE_SIZE } from "@/data/constants";
import BaseButton from "@/components/shared/BaseButton.vue";
import { googleFontFamiliesLink } from "@/data/expert-program/font-families";
import { allCountries } from "country-region-data";
import BaseInput from "@/components/shared/BaseInput.vue";
import {
  SEARCH_ICON,
  DOLLAR_ICON,
  JOIN_EXPERT_PROGRAM_BACKGROUND,
  HAMBURGER_MENU_ICON,
  COMPANY_LOGO,
  SECONDARY_CALENDAR_ICON,
} from "@/assets/svg/shared/svg-constants";
import LocationSvg from "@/components/common/LocationSvg.vue";
import LanguageSvg from "@/components/common/LanguageSvg.vue";
import TitleSvg from "@/components/common/TitleSvg.vue";
import { spokenLanguages } from "@/data/spokenLanguages";
import type {
  SearchBarConfig,
  SoonestAvailability,
} from "@/types/directory-types";
import { getCurrentTimeZoneInfo } from "@/utils/dateAndTimeUtils";

const GET_EXPERTS = gql`
  query getExperts(
    $companyId: ID!
    $programUuid: String!
    $first: Int!
    $origin: String!
    $searchQuery: String
    $searchBy: [String!]
    $attendeeTimezone: String!
  ) {
    getCompanyExperts(
      companyId: $companyId
      programUuid: $programUuid
      first: $first
      origin: $origin
      searchQuery: $searchQuery
      searchBy: $searchBy
    ) {
      edges {
        node {
          id
          email
          firstName
          lastName
          profile_picture
          linkedin_profile
          country
          uuid
          title
          active
          companyName
          spokenLanguages
          meetingsCount
          anyPastMeetings
          calendarActive
          soonestAvailabilities(attendeeTimezone: $attendeeTimezone) {
            startTime
            endTime
          }
          userProgramProfiles(programUuid: $programUuid) {
            expertise
            experience
          }
        }
      }
      pageInfo {
        hasNextPage
      }
    }
  }
`;

const GET_DIRECTORY = gql`
  query getDirectory($companyId: ID!, $origin: String!) {
    getDirectoryDetail(companyId: $companyId, origin: $origin) {
      id
      style
      programLogo
      directoryCosmetic {
        fontFamily
        bold
        textColor
        backgroundColor
        fontSize
      }
    }
  }
`;

const VISIT_MUTATION = gql`
  mutation updateDirectoryVisit($input: VisitInput!) {
    updateDirectoryVisit(input: $input) {
      success
    }
  }
`;

interface UserProgramProfile {
  expertise: number;
  experience: number;
}

interface Profile {
  id: string;
  uuid: string;
  firstName: string;
  lastName: string;
  email: string;
  title: string;
  companyName: string;
  imageUrl: string;
  linkedinUrl: string;
  country: string;
  meetingsCount: number;
  spokenLanguages: string[];
  anyPastMeetings: boolean;
  soonestAvailabilities: SoonestAvailability[];
  userProgramProfile: UserProgramProfile[];
}

interface Edge {
  node: {
    id: string;
    email: string;
    firstName: string;
    lastName: string;
    country: string;
    profile_picture: string;
    linkedin_profile: string;
    title: string;
    companyName: string;
    uuid: string;
    meetingsCount: number;
    anyPastMeetings: boolean;
    spokenLanguages: string[];
    calendarActive: boolean;
    soonestAvailabilities: SoonestAvailability[];
    userProgramProfiles: UserProgramProfile[];
  };
}

interface profileCardCosmetics {
  backgroundColor: string;
  bold: string;
  fontFamily: string;
  fontSize: string;
  textColor: string;
}

export default defineComponent({
  name: "ExpertsDirectory",
  components: {
    ProfileCard,
    BaseButton,
    BaseInput,
  },
  mounted() {
    const link = document.createElement("link");
    link.href = googleFontFamiliesLink;
    link.rel = "stylesheet";
    document.head.appendChild(link);
  },
  setup() {
    const route = useRoute();
    const companyId = ref(route.query.company_id);
    const programUuid = ref(route.query.program);
    const profiles = ref<Profile[]>([]);
    const errorMessage = ref<string>("");
    const numberOfExperts = ref(EXPERTS_DIRECTORY_PAGE_SIZE);
    const hasMoreExperts = ref(false);
    const fontFamily = ref<string>("");
    const boldness = ref<string>("");
    const textColor = ref<string>("");
    const backgroundColor = ref<string>("#6A48F3");
    const fontSize = ref<string>("");
    const profileImageClass = ref<string>("");
    const profileCardsGap = ref<string>("");
    const profileImageAndNameLayoutClass = ref<string>("");
    const profileCenterAligned = ref(false);
    const columns = ref(5);
    const showFilters = ref(false);
    const isGridUneven = ref(false);
    const origin = document.location.ancestorOrigins[0] || "";
    const programLogo = ref("");
    const countriesList = ref(allCountries.map((country) => country[0]));
    const top3MeetingsExperts = ref<Profile[]>([]);
    const searchBy = ref("");
    const searchQuery = ref("");
    const filterBySoonestAvailability = ref(false);
    const showLanguageSuggestions = ref(false);
    const showLocationSuggestions = ref(false);
    const searchBars: SearchBarConfig[] = [
      {
        id: "location",
        label: "Location",
        placeholder: "Location",
        modelValue: ref(""),
        icon: LocationSvg,
        suggestions: [] as string[],
      },
      {
        id: "language",
        label: "Language",
        placeholder: "Language",
        modelValue: ref(""),
        icon: LanguageSvg,
        suggestions: [] as string[],
      },
      {
        id: "title",
        label: "Title",
        placeholder: "Title",
        modelValue: ref(""),
        icon: TitleSvg,
        suggestions: [] as string[],
      },
    ];

    const {
      mutate: updateDirectoryVisit,
      onDone: updateDirectoryVisitDone,
      onError: updateDirectoryVisitError,
    } = useMutation(VISIT_MUTATION);

    const {
      result: queryExpertsResult,
      loading: isLoading,
      error: queryExpertsError,
      refetch: refetchExperts,
    } = useQuery(GET_EXPERTS, {
      companyId: companyId.value,
      programUuid: programUuid.value,
      first: numberOfExperts.value,
      origin: origin,
      searchQuery: searchQuery.value,
      searchBy: searchBy.value,
      attendeeTimezone: getCurrentTimeZoneInfo().timeZone,
    });

    const { result: directoryResult, error: directoryError } = useQuery(
      GET_DIRECTORY,
      {
        companyId: companyId.value,
        origin: origin,
      }
    );

    watch(
      () => directoryResult.value,
      (newValue) => {
        if (newValue && newValue.getDirectoryDetail) {
          programLogo.value = newValue.getDirectoryDetail.programLogo;
          applyCardTemplate(newValue.getDirectoryDetail.style);
          applyCardStyles(newValue.getDirectoryDetail.directoryCosmetic);
        }
      }
    );

    watch(
      () => directoryError.value,
      (newError) => {
        if (newError) {
          console.log("Directory error is ", newError);
          errorMessage.value = newError.message;
        }
      }
    );

    watch(
      () => queryExpertsResult.value,
      (newValue) => {
        if (newValue) {
          if (newValue?.getCompanyExperts?.edges) {
            profiles.value = newValue.getCompanyExperts.edges.reduce(
              (profiles: Profile[], { node }: Edge) => {
                if (node.calendarActive) {
                  profiles.push({
                    id: node.id,
                    firstName: node.firstName,
                    lastName: node.lastName,
                    email: node.email,
                    imageUrl: node.profile_picture,
                    linkedinUrl: node.linkedin_profile,
                    country: node.country,
                    uuid: node.uuid,
                    title: node.title,
                    companyName: node.companyName,
                    userProgramProfile: node.userProgramProfiles,
                    spokenLanguages: node.spokenLanguages,
                    meetingsCount: node.meetingsCount,
                    anyPastMeetings: node.anyPastMeetings,
                    soonestAvailabilities: node.soonestAvailabilities,
                  });
                }
                return profiles;
              },
              []
            );
          }
          findTop3MeetingsExperts(profiles.value);
          hasMoreExperts.value =
            newValue?.getCompanyExperts?.pageInfo?.hasNextPage;
        }
      },
      { immediate: true }
    );

    watch(
      () => queryExpertsError.value,
      (newError) => {
        if (newError) {
          console.log("Error happened ", newError);
          errorMessage.value = newError.message;
        }
      }
    );

    const loadMoreExperts = () => {
      numberOfExperts.value += EXPERTS_DIRECTORY_PAGE_SIZE;
      refetchExperts({
        companyId: companyId.value,
        programUuid: programUuid.value,
        first: numberOfExperts.value, // Pass the updated numberOfExperts
        origin: origin,
        searchQuery: searchQuery.value,
        searchBy: searchBy.value,
        attendeeTimezone: getCurrentTimeZoneInfo().timeZone,
      });
    };

    const findTop3MeetingsExperts = (profiles: Profile[]) => {
      top3MeetingsExperts.value = profiles
        .filter((profile) => profile.meetingsCount >= 1)
        .sort((a, b) => b.meetingsCount - a.meetingsCount)
        .slice(0, 3);
    };

    const applyCardStyles = (profileCosmetic: profileCardCosmetics) => {
      fontFamily.value = profileCosmetic.fontFamily;
      boldness.value = profileCosmetic.bold;
      textColor.value = profileCosmetic.textColor;
      backgroundColor.value = profileCosmetic.backgroundColor;
      fontSize.value = profileCosmetic.fontSize;
    };

    const applyCardTemplate = (template: string) => {
      updateProfileImageClass(template);
      updateProfileCardsGap(template);
      updateProfileImageAndNameLayoutClass(template);
      updateProfileCenterAlignment(template);
      updateGridLayout(template);
    };

    const updateProfileImageClass = (template: string) => {
      if (template === "template-1" || template === "template-4") {
        profileImageClass.value = "rounded-full";
      } else {
        profileImageClass.value = "rounded-md";
      }
    };

    const updateProfileCardsGap = (template: string) => {
      if (template === "template-3") {
        profileCardsGap.value = "gap-0.5";
      } else {
        profileCardsGap.value = "gap-3";
      }
    };

    const updateProfileImageAndNameLayoutClass = (template: string) => {
      if (template === "template-3" || template === "template-5") {
        profileImageAndNameLayoutClass.value = "flex-col";
      } else {
        profileImageAndNameLayoutClass.value = "flex-row";
      }
    };

    const updateProfileCenterAlignment = (template: string) => {
      if (template === "template-5") {
        profileCenterAligned.value = true;
      } else {
        profileCenterAligned.value = false;
      }
    };

    const updateGridLayout = (template: string) => {
      if (template === "template-4") {
        isGridUneven.value = true;
      } else {
        isGridUneven.value = false;
      }
    };

    const breakpoints = {
      sm: 640, // Small screens
      md: 768, // Medium screens
      lg: 1080, // Large screens
      xl: 1420, // Extra-large screens
      // 2xl: 1536, // 2X Large screens
    };

    const getColumnCount = () => {
      const width = window.innerWidth;

      if (width < breakpoints.lg) {
        return 1;
      } else if (width < breakpoints.xl) {
        return 2;
      } else {
        return 3;
      }
    };

    const handleResize = () => {
      columns.value = getColumnCount();
    };

    const callUpdateDirectoryVisit = () => {
      updateDirectoryVisit({
        input: {
          directoryVisitParams: {
            programId: programUuid.value,
          },
        },
      });

      updateDirectoryVisitDone((response) => {
        if (response) {
          if (response.data.updateDirectoryVisit.success === false) {
            errorMessage.value = "Failed to update booking system visit";
          }
        }
      });

      updateDirectoryVisitError((error: ApolloError) => {
        if (error) {
          console.log("Error is ", error);
          errorMessage.value = error.message;
        }
      });
    };

    const clearAllFilters = () => {
      filterBySoonestAvailability.value = false;
      searchBy.value = "";
      searchBars.forEach((bar) => {
        bar.modelValue.value = "";
      });
      refetchExperts({
        companyId: companyId.value,
        programUuid: programUuid.value,
        first: EXPERTS_DIRECTORY_PAGE_SIZE,
        origin: origin,
        searchQuery: "",
        searchBy: "",
        attendeeTimezone: getCurrentTimeZoneInfo().timeZone,
      });
    };

    const filterBySearch = (searchBarId: string) => {
      errorMessage.value = "";
      closeSuggestions();
      numberOfExperts.value = EXPERTS_DIRECTORY_PAGE_SIZE;
      setSearchQueries(searchBarId);
      refetchExperts({
        companyId: companyId.value,
        programUuid: programUuid.value,
        first: numberOfExperts.value,
        origin: origin,
        searchQuery: searchQuery.value,
        searchBy: searchBarId,
        attendeeTimezone: getCurrentTimeZoneInfo().timeZone,
      });
    };

    const setSearchQueries = (searchBarId: string) => {
      searchBars.forEach((bar) => {
        if (bar.id === searchBarId) {
          searchQuery.value = bar.modelValue.value;
        } else {
          bar.modelValue.value = "";
        }
      });
      if (searchBarId === "soonestAvailability") {
        searchQuery.value = getCurrentTimeZoneInfo().timeZone;
        searchBy.value = "soonestAvailability";
      } else {
        filterBySoonestAvailability.value = false;
        searchBy.value = searchBarId;
      }
    };

    const navigateToExpertProgramOverviewPage = () => {
      const landingUrl = `${process.env.VUE_APP_EXPERT_APP_BASE_URL}/expert-program-overview?self_enrollment=true&new=true&program=${programUuid.value}`;
      window.open(landingUrl, "_blank");
    };

    const isNoExpertsAvailable = () => {
      return (
        profiles.value.length === 0 &&
        isLoading.value === false &&
        errorMessage.value === ""
      );
    };

    const appliedSearchFilters = () => {
      return searchQuery.value !== "" && searchBy.value !== "";
    };

    const noExpertAvailableText = () => {
      if (appliedSearchFilters()) {
        return "No results match your search, please clear your search and try again.";
      } else {
        return "No Active Experts, Please Check Back Soon.";
      }
    };

    const filterLanguages = (query: string): string[] => {
      return spokenLanguages.filter((language) =>
        language.toLowerCase().includes(query.toLowerCase())
      );
    };

    const selectLanguage = (
      searchConfig: SearchBarConfig,
      language: string
    ) => {
      searchConfig.modelValue.value = language;
      showLanguageSuggestions.value = false;
    };

    const filterCountries = (query: string): string[] => {
      return countriesList.value.filter((country) =>
        country.toLowerCase().includes(query.toLowerCase())
      );
    };

    const selectCountry = (searchConfig: SearchBarConfig, country: string) => {
      searchConfig.modelValue.value = country;
      showLocationSuggestions.value = false;
    };

    const handleInput = (searchConfig: SearchBarConfig) => {
      if (searchConfig.id === "language") {
        showLanguageSuggestions.value = true;
      }
      if (searchConfig.id === "location") {
        showLocationSuggestions.value = true;
      }
      clearSearchFields(searchConfig);
    };

    const clearSearchFields = (searchConfig: SearchBarConfig | undefined) => {
      if (searchConfig) {
        searchBars.forEach((bar) => {
          if (bar.id !== searchConfig.id) {
            bar.modelValue.value = "";
          }
        });
      } else {
        searchBars.forEach((bar) => {
          bar.modelValue.value = "";
        });
      }
    };

    const closeSuggestions = () => {
      showLocationSuggestions.value = false;
      showLanguageSuggestions.value = false;
    };

    const applySoonestAvailabilityFilter = () => {
      filterBySoonestAvailability.value = true;
      filterBySearch("soonestAvailability");
    };

    const observer = new MutationObserver(() => {
      const tinyChatBubble = document.querySelector("tiny-chat");

      if (tinyChatBubble) {
        (tinyChatBubble as HTMLElement).style.display = "none";
      }
    });

    observer.observe(document.body, { childList: true, subtree: true });

    handleResize();

    onMounted(() => {
      callUpdateDirectoryVisit();
      window.addEventListener("resize", () => {
        handleResize();
      });
    });

    onBeforeUnmount(() => {
      window.removeEventListener("resize", handleResize);
    });

    return {
      companyId,
      programUuid,
      profiles,
      errorMessage,
      hasMoreExperts,
      loadMoreExperts,
      fontFamily,
      boldness,
      textColor,
      backgroundColor,
      fontSize,
      profileImageClass,
      profileCardsGap,
      profileImageAndNameLayoutClass,
      profileCenterAligned,
      columns,
      isLoading,
      isGridUneven,
      programLogo,
      handleInput,
      COMPANY_LOGO,
      top3MeetingsExperts,
      searchBars,
      searchBy,
      clearAllFilters,
      filterBySearch,
      navigateToExpertProgramOverviewPage,
      isNoExpertsAvailable,
      showFilters,
      spokenLanguages,
      filterLanguages,
      selectLanguage,
      filterCountries,
      selectCountry,
      showLanguageSuggestions,
      showLocationSuggestions,
      LocationSvg,
      LanguageSvg,
      TitleSvg,
      filterBySoonestAvailability,
      applySoonestAvailabilityFilter,
      getCurrentTimeZoneInfo,
      noExpertAvailableText,
      JOIN_EXPERT_PROGRAM_BACKGROUND,
      SEARCH_ICON,
      DOLLAR_ICON,
      HAMBURGER_MENU_ICON,
      SECONDARY_CALENDAR_ICON,
    };
  },
});
</script>
