import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "flex flex-col gap-4 justify-between pl-4 pr-4 pb-4 w-full lg:pr-20 lg:w-[40vw] max-w-[500px]" }
const _hoisted_2 = { class: "flex flex-col order-2 sm:order-1" }
const _hoisted_3 = { class: "flex flex-col gap-8 justify-start" }
const _hoisted_4 = { class: "flex flex-col" }
const _hoisted_5 = { class: "text-gray-700 text-base font-normal leading-6 font-inter text-left" }
const _hoisted_6 = { class: "flex flex-col" }
const _hoisted_7 = { class: "text-gray-700 text-base font-normal leading-6 font-inter text-left" }
const _hoisted_8 = { class: "flex flex-col" }
const _hoisted_9 = { class: "text-gray-700 text-base font-normal leading-6 font-inter text-left" }
const _hoisted_10 = { class: "flex flex-col" }
const _hoisted_11 = { class: "text-gray-700 text-base font-normal leading-6 font-inter text-left" }
const _hoisted_12 = { class: "flex flex-col" }
const _hoisted_13 = { class: "text-gray-700 text-base font-normal leading-6 font-inter text-left" }
const _hoisted_14 = { class: "flex flex-col gap-1" }
const _hoisted_15 = { class: "text-gray-700 text-base rounded px-2 py-1 font-normal leading-6 font-inter max-h-[100px] overflow-y-auto text-left" }
const _hoisted_16 = { class: "flex flex-col justify-end items-start order-1 sm:order-2" }
const _hoisted_17 = { class: "flex flex-wrap gap-4" }
const _hoisted_18 = { key: 0 }
const _hoisted_19 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseButton = _resolveComponent("BaseButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[6] || (_cache[6] = _createElementVNode("div", { class: "flex flex-col" }, [
          _createElementVNode("span", { class: "text-gray-900 font-sans text-xl font-bold leading-7 text-left" }, " Meeting Details ")
        ], -1)),
        _createElementVNode("div", _hoisted_4, [
          _cache[0] || (_cache[0] = _createElementVNode("span", { class: "text-gray-800 text-base font-bold leading-6 font-inter opacity-60 text-left" }, " Name ", -1)),
          _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.attendeeStore.bookMeetingFirstName) + " " + _toDisplayString(_ctx.attendeeStore.bookMeetingLastName), 1)
        ]),
        _createElementVNode("div", _hoisted_6, [
          _cache[1] || (_cache[1] = _createElementVNode("span", { class: "text-gray-800 text-base font-bold leading-6 font-inter opacity-60 text-left" }, " Email ", -1)),
          _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.attendeeStore.bookMeetingEmail), 1)
        ]),
        _createElementVNode("div", _hoisted_8, [
          _cache[2] || (_cache[2] = _createElementVNode("span", { class: "text-gray-800 text-base font-bold leading-6 font-inter opacity-60 text-left" }, " Linkedin URL ", -1)),
          _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.attendeeStore.bookMeetingLinkedinUrl), 1)
        ]),
        _createElementVNode("div", _hoisted_10, [
          _cache[3] || (_cache[3] = _createElementVNode("span", { class: "text-gray-800 text-base font-bold leading-6 font-inter opacity-60 text-left" }, " Title ", -1)),
          _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.attendeeStore.bookMeetingTitle), 1)
        ]),
        _createElementVNode("div", _hoisted_12, [
          _cache[4] || (_cache[4] = _createElementVNode("span", { class: "text-gray-800 text-base font-bold leading-6 font-inter opacity-60 text-left" }, " Company ", -1)),
          _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.attendeeStore.bookMeetingCompany), 1)
        ]),
        _createElementVNode("div", _hoisted_14, [
          _cache[5] || (_cache[5] = _createElementVNode("span", { class: "text-gray-800 text-base font-bold leading-6 font-inter opacity-60 text-left" }, " Note ", -1)),
          _createElementVNode("span", _hoisted_15, _toDisplayString(_ctx.attendeeStore.bookMeetingNotes), 1)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_16, [
      _createElementVNode("div", _hoisted_17, [
        _createVNode(_component_BaseButton, {
          type: "button",
          disabled: _ctx.isCreatingBooking,
          onClick: _ctx.handleConfirmMeeting,
          class: "text-white bg-primary-purple max-w-max text-sm px-4 py-2 w-full rounded-md border border-gray-200 font-sans text-base font-normal leading-6 whitespace-nowrap"
        }, {
          default: _withCtx(() => [
            (!_ctx.isCreatingBooking)
              ? (_openBlock(), _createElementBlock("span", _hoisted_18, "Confirm Meeting"))
              : (_openBlock(), _createElementBlock("span", _hoisted_19, "Confirming..."))
          ]),
          _: 1
        }, 8, ["disabled", "onClick"]),
        _createVNode(_component_BaseButton, {
          type: "button",
          onClick: _ctx.handleStartOver,
          class: "text-gray-700 bg-gray-300 max-w-max text-sm px-4 py-2 w-full rounded-md border border-gray-200 font-sans text-base font-normal leading-6 whitespace-nowrap"
        }, {
          default: _withCtx(() => _cache[7] || (_cache[7] = [
            _createTextVNode(" Start Over ")
          ])),
          _: 1
        }, 8, ["onClick"])
      ])
    ])
  ]))
}