<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="12" cy="12" r="10" :stroke="primaryColor" stroke-width="2" />
    <path
      d="M12 2C9.34784 2 6.8043 3.05357 4.92893 4.92893C3.05357 6.8043 2 9.34784 2 12C2 14.6522 3.05357 17.1957 4.92893 19.0711C6.8043 20.9464 9.34784 22 12 22C14.6522 22 17.1957 20.9464 19.0711 19.0711C20.9464 17.1957 22 14.6522 22 12C22 9.34784 20.9464 6.8043 19.0711 4.92893C17.1957 3.05357 14.6522 2 12 2Z"
      :stroke="primaryColor"
      stroke-width="2"
    />
    <path d="M2 12H22" :stroke="primaryColor" stroke-width="2" />
    <path
      d="M12 2C14.25 4.75 15.5 8.5 15.5 12C15.5 15.5 14.25 19.25 12 22"
      :stroke="primaryColor"
      stroke-width="2"
    />
    <path
      d="M12 2C9.75 4.75 8.5 8.5 8.5 12C8.5 15.5 9.75 19.25 12 22"
      :stroke="primaryColor"
      stroke-width="2"
    />
  </svg>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "DynamicIcon",
  props: {
    primaryColor: {
      type: String,
      default: "#B2B1FF",
    },
  },
});
</script>
