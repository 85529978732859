import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"
import _imports_0 from '../../assets/shared/failure.svg'
import _imports_1 from '../../assets/shared/hubspot-logo.svg'
import _imports_2 from '../../assets/shared/salesforce-logo.svg'


const _hoisted_1 = { class: "bg-white min-h-screen flex flex-col" }
const _hoisted_2 = { class: "flex flex-grow max-md:flex-col" }
const _hoisted_3 = { class: "flex flex-col w-1/5 max-md:ml-0 max-md:w-full bg-primary-purple" }
const _hoisted_4 = { class: "flex flex-col mt-14 flex-grow" }
const _hoisted_5 = { class: "flex flex-col justify-start w-4/5 max-md:ml-0 max-md:w-full" }
const _hoisted_6 = { class: "flex flex-col mt-20 w-full max-md:mt-10 max-md:max-w-full" }
const _hoisted_7 = { class: "flex justify-center mb-4" }
const _hoisted_8 = {
  key: 0,
  role: "alert",
  class: "flex gap-2 w-1/2 justify-center p-2.5 mt-6 text-sm leading-5 text-center text-red-900 bg-red-50 rounded-lg max-md:flex-wrap",
  style: {"max-height":"80px","overflow-y":"auto"}
}
const _hoisted_9 = { class: "self-center mt-8 w-full px-10" }
const _hoisted_10 = { class: "flex gap-5 max-md:flex-col" }
const _hoisted_11 = { class: "flex flex-col gap-4" }
const _hoisted_12 = { class: "flex flex-row gap-3" }
const _hoisted_13 = { class: "flex flex-col text-left gap-3 border border-gray-300 border-solid px-4 py-6 rounded-md" }
const _hoisted_14 = { class: "flex flex-col w-full" }
const _hoisted_15 = { class: "flex flex-col text-left gap-3 border border-gray-300 border-solid px-4 py-6 rounded-md" }
const _hoisted_16 = { class: "flex flex-col w-full" }
const _hoisted_17 = { class: "flex flex-wrap justify-center mt-auto items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_StepsList = _resolveComponent("StepsList")!
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_FooterSection = _resolveComponent("FooterSection")!

  return (_openBlock(), _createElementBlock("main", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("aside", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _cache[2] || (_cache[2] = _createElementVNode("div", { class: "flex flex-col ml-10 text-left" }, [
            _createElementVNode("span", { class: "text-white text-3xl font-semibold leading-9" }, [
              _createTextVNode(" Get Started "),
              _createElementVNode("br")
            ])
          ], -1)),
          _createVNode(_component_StepsList, { currentStep: 4 })
        ])
      ]),
      _createElementVNode("section", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _cache[10] || (_cache[10] = _createElementVNode("div", { class: "flex flex-col px-10 max-w-full text-left" }, [
            _createElementVNode("span", { class: "self-start text-gray-800 font-semibold text-xl leading-9 break-words" }, " Track Results by integrating your CRM "),
            _createElementVNode("span", { class: "self-start text-black font-normal text-base leading-6 mt-2 break-words" }, " Integrate your SlashExperts program with CRM. It only takes 5 minutes and no admin access required. It's easy! ")
          ], -1)),
          _createElementVNode("div", _hoisted_7, [
            (_ctx.errorMessage)
              ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                  _cache[3] || (_cache[3] = _createElementVNode("div", { class: "flex-shrink-0 w-6 h-6 flex items-center justify-center rounded-full" }, [
                    _createElementVNode("img", {
                      src: _imports_0,
                      alt: "Error Icon",
                      class: "w-4 h-4"
                    })
                  ], -1)),
                  _createElementVNode("p", null, _toDisplayString(_ctx.errorMessage), 1)
                ]))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("div", _hoisted_11, [
                _createElementVNode("div", _hoisted_12, [
                  _createElementVNode("div", _hoisted_13, [
                    _cache[5] || (_cache[5] = _createElementVNode("img", {
                      src: _imports_1,
                      alt: "Hubspot Logo",
                      class: "w-20 h-auto mb-2"
                    }, null, -1)),
                    _cache[6] || (_cache[6] = _createElementVNode("div", { class: "flex flex-col gap-1" }, [
                      _createElementVNode("span", { class: "text-gray-900 text-xl font-semibold leading-7 tracking-wide" }, "Connect Hubspot"),
                      _createElementVNode("span", { class: "text-gray-500 text-sm font-normal leading-5 tracking-wide" }, [
                        _createTextVNode("Connect Slash Expert to a Hubspot admin"),
                        _createElementVNode("br"),
                        _createTextVNode(" and begin enriching your records.")
                      ])
                    ], -1)),
                    _createElementVNode("div", _hoisted_14, [
                      _createVNode(_component_BaseButton, {
                        type: "button",
                        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.connectHubspot())),
                        class: "flex flex-col justify-center items-center p-2 w-full rounded-lg text-sm font-medium leading-none text-white bg-primary-purple text-center"
                      }, {
                        default: _withCtx(() => _cache[4] || (_cache[4] = [
                          _createElementVNode("span", null, "Connect Now", -1)
                        ])),
                        _: 1
                      })
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_15, [
                    _cache[8] || (_cache[8] = _createElementVNode("img", {
                      src: _imports_2,
                      alt: "Salesforce Logo",
                      class: "w-12 h-auto"
                    }, null, -1)),
                    _cache[9] || (_cache[9] = _createElementVNode("div", { class: "flex flex-col gap-1" }, [
                      _createElementVNode("span", { class: "text-gray-900 text-xl font-semibold leading-7 tracking-wide" }, "Connect Salesforce"),
                      _createElementVNode("span", { class: "text-gray-500 text-sm font-normal leading-5 tracking-wide" }, [
                        _createTextVNode("Connect Slash Expert to a Salesforce admin "),
                        _createElementVNode("br"),
                        _createTextVNode(" and begin enriching your records.")
                      ])
                    ], -1)),
                    _createElementVNode("div", _hoisted_16, [
                      _createVNode(_component_BaseButton, {
                        type: "button",
                        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.connectSalesforce())),
                        class: "flex flex-col justify-center items-center p-2 w-full text-white bg-primary-purple rounded-lg text-sm font-medium leading-none text-center"
                      }, {
                        default: _withCtx(() => _cache[7] || (_cache[7] = [
                          _createElementVNode("span", null, "Connect Now", -1)
                        ])),
                        _: 1
                      })
                    ])
                  ])
                ])
              ])
            ])
          ])
        ]),
        _createElementVNode("footer", _hoisted_17, [
          _createVNode(_component_FooterSection, {
            isDisabled: _ctx.isDisabled,
            onNext: _ctx.goNext
          }, null, 8, ["isDisabled", "onNext"])
        ])
      ])
    ])
  ]))
}