import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode } from "vue"
import _imports_0 from '../../../../assets/shared/hubspot-logo.svg'


const _hoisted_1 = { class: "flex flex-col text-left gap-3 border border-gray-300 border-solid px-4 py-6 rounded-md" }
const _hoisted_2 = { class: "flex flex-col w-full" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseButton = _resolveComponent("BaseButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[1] || (_cache[1] = _createElementVNode("img", {
      src: _imports_0,
      alt: "Hubspot Logo",
      class: "w-20 h-auto mb-2"
    }, null, -1)),
    _cache[2] || (_cache[2] = _createElementVNode("div", { class: "flex flex-col gap-1" }, [
      _createElementVNode("span", { class: "text-gray-900 text-xl font-semibold leading-7 tracking-wide" }, "Connect Hubspot"),
      _createElementVNode("span", { class: "text-gray-500 text-sm font-normal leading-5 tracking-wide" }, [
        _createTextVNode("Connect Slash Expert to a Hubspot admin"),
        _createElementVNode("br"),
        _createTextVNode(" and begin enriching your records.")
      ])
    ], -1)),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_BaseButton, {
        disabled: _ctx.loading,
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.connectHubspot())),
        type: "button",
        class: _normalizeClass(["flex flex-col justify-center items-center p-2 w-full rounded-lg text-sm font-medium leading-none text-center", {
          'text-primary-purple bg-violet-200': _ctx.hubspotSynced,
          'text-white bg-primary-purple': !_ctx.hubspotSynced,
        }])
      }, {
        default: _withCtx(() => [
          (_ctx.loading)
            ? (_openBlock(), _createElementBlock("span", _hoisted_3, "Loading..."))
            : (_openBlock(), _createElementBlock("span", _hoisted_4, [
                (!_ctx.hubspotSynced)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_5, "Connect Now"))
                  : (_openBlock(), _createElementBlock("span", _hoisted_6, "View Details"))
              ]))
        ]),
        _: 1
      }, 8, ["disabled", "class"])
    ])
  ]))
}