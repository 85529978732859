import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"
import _imports_0 from '@/assets/shared/failure.svg'


const _hoisted_1 = {
  key: 0,
  class: "fixed inset-0 flex bg-black bg-opacity-50 items-center justify-center z-50"
}
const _hoisted_2 = { class: "bg-white rounded-lg shadow-lg p-6 max-w-md w-full relative" }
const _hoisted_3 = { class: "flex flex-col gap-3" }
const _hoisted_4 = { class: "flex flex-col gap-1" }
const _hoisted_5 = { class: "text-2xl leading-8 font-medium text-gray-800" }
const _hoisted_6 = { class: "flex justify-center" }
const _hoisted_7 = {
  key: 0,
  role: "alert",
  class: "flex gap-2 w-1/2 mb-4 justify-center p-2.5 mt-6 text-sm leading-5 text-center text-red-900 bg-red-50 rounded-lg max-md:flex-wrap",
  style: {"max-height":"80px","overflow-y":"auto"}
}
const _hoisted_8 = { class: "flex flex-col w-full" }
const _hoisted_9 = { class: "flex flex-row gap-2 justify-end items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseInput = _resolveComponent("BaseInput")!
  const _component_BaseButton = _resolveComponent("BaseButton")!

  return (_ctx.isVisible)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("button", {
            class: "absolute top-2 right-2 text-gray-500 hover:text-gray-700",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:isVisible', false)))
          }, " ✕ "),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("span", _hoisted_5, "Create New " + _toDisplayString(_ctx.campaignOrListText(_ctx.type)), 1)
            ]),
            _createElementVNode("div", _hoisted_6, [
              (_ctx.errorMessage)
                ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                    _cache[3] || (_cache[3] = _createElementVNode("div", { class: "flex-shrink-0 w-6 h-6 flex items-center justify-center rounded-full" }, [
                      _createElementVNode("img", {
                        src: _imports_0,
                        alt: "Error Icon",
                        class: "w-4 h-4"
                      })
                    ], -1)),
                    _createElementVNode("p", null, _toDisplayString(_ctx.errorMessage), 1)
                  ]))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_8, [
              _createVNode(_component_BaseInput, {
                id: "campaignName",
                modelValue: _ctx.newCampaignOrListName,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.newCampaignOrListName) = $event)),
                type: "text",
                placeholder: `${_ctx.campaignOrListText(_ctx.type)} Name`,
                className: "px-3.5 py-2.5 w-full border border-gray-300 rounded-md text-sm font-normal leading-5 whitespace-nowrap",
                required: ""
              }, null, 8, ["modelValue", "placeholder"])
            ]),
            _createElementVNode("div", _hoisted_9, [
              _createVNode(_component_BaseButton, {
                type: "button",
                class: "flex max-w-max flex-col justify-center items-center px-2 py-3 w-full bg-white border border-gray-300 text-black rounded-md text-md font-medium leading-none text-center",
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('update:isVisible', false)))
              }, {
                default: _withCtx(() => _cache[4] || (_cache[4] = [
                  _createTextVNode(" Cancel ")
                ])),
                _: 1
              }),
              _createVNode(_component_BaseButton, {
                type: "button",
                class: "flex max-w-max flex-col justify-center items-center px-2 py-3 w-full bg-primary-purple text-white rounded-md text-md font-medium leading-none text-center",
                onClick: _ctx.createNewCampaignOrList,
                disabled: _ctx.isLoading
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.isLoading ? "Submitting..." : "Submit"), 1)
                ]),
                _: 1
              }, 8, ["onClick", "disabled"])
            ])
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}