<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M13.1242 5C13.1242 5.8288 12.7949 6.62366 12.2089 7.20971C11.6228 7.79576 10.828 8.125 9.99917 8.125C9.17037 8.125 8.37551 7.79576 7.78946 7.20971C7.20341 6.62366 6.87417 5.8288 6.87417 5C6.87417 4.1712 7.20341 3.37634 7.78946 2.79029C8.37551 2.20424 9.17037 1.875 9.99917 1.875C10.828 1.875 11.6228 2.20424 12.2089 2.79029C12.7949 3.37634 13.1242 4.1712 13.1242 5ZM3.75 16.765C3.77678 15.1253 4.44695 13.5618 5.61598 12.4117C6.78501 11.2616 8.35924 10.6171 9.99917 10.6171C11.6391 10.6171 13.2133 11.2616 14.3824 12.4117C15.5514 13.5618 16.2216 15.1253 16.2483 16.765C14.2878 17.664 12.156 18.1279 9.99917 18.125C7.76917 18.125 5.6525 17.6383 3.75 16.765Z"
      :stroke="primaryColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "DynamicIcon",
  props: {
    primaryColor: {
      type: String,
      default: "#B2B1FF",
    },
  },
});
</script>
