<template>
  <div class="flex flex-col min-h-screen bg-gray-100 overflow-hidden">
    <div
      v-if="false"
      class="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
    >
      <!-- Spinner -->
      <div
        class="animate-spin rounded-full h-32 w-32 border-t-4 border-b-4 border-primary-purple"
      ></div>
    </div>
    <div v-else class="flex flex-grow max-md:flex-col">
      <aside
        class="flex flex-col w-1/5 max-md:ml-0 max-md:w-full bg-primary-purple"
      >
        <div class="flex flex-col mt-14 flex-grow">
          <div class="flex flex-col ml-10 text-left">
            <span class="text-white text-3xl font-semibold leading-9">
              Get Started <br />
            </span>
          </div>

          <!-- Third Image, or Footer Image -->
          <StepsList :currentStep="4" />
        </div>
      </aside>
      <main class="flex flex-col w-full md:w-4/5 justify-start pt-12">
        <div
          class="flex flex-col items-start w-full gap-3 px-6 sm:px-24 text-left"
        >
          <div class="flex flex-col mb-6">
            <span
              class="text-3xl leading-9 font-semibold text-neutral-800 font-inter"
              >Track Results in Hubspot</span
            >
          </div>

          <!-- Error div -->
          <div class="flex justify-center mb-4">
            <div
              v-if="errorMessage"
              role="alert"
              class="flex gap-2 w-full justify-center p-2.5 mt-6 text-sm leading-5 text-center text-red-900 bg-red-50 rounded-lg max-md:flex-wrap"
              style="max-height: 80px; overflow-y: auto"
            >
              <!-- Icon Container -->
              <div
                class="flex-shrink-0 w-6 h-6 flex items-center justify-center rounded-full"
              >
                <!-- Custom Image Icon -->
                <img
                  src="../../assets/shared/failure.svg"
                  alt="Error Icon"
                  class="w-4 h-4"
                />
              </div>
              <p>{{ errorMessage }}</p>
            </div>
          </div>

          <div class="flex flex-col">
            <span class="text-sm leading-5 font-medium text-neutral-800"
              >Sync'd Hubspot Account</span
            >
          </div>

          <!-- user info -->
          <ProviderAccountCard
            :accountName="providerAccountName"
            :accountEmail="providerAccountEmail"
          />

          <div
            class="flex flex-col gap-4 mb-20 max-w-[80%] md:max-w-[70%] lg:max-w-[60%]"
          >
            <!-- Contact fields mapping -->
            <div>
              <ContactFieldMapping
                :contactMappingFields="hubspotMappingFields"
                :contactFirstName="hubspotData.contactFirstName.value"
                :contactLastName="hubspotData.contactLastName.value"
                :contactTitle="hubspotData.contactTitle.value"
                @update:contactFirstName="
                  hubspotData.contactFirstName.value = $event
                "
                @update:contactLastName="
                  hubspotData.contactLastName.value = $event
                "
                @update:contactTitle="hubspotData.contactTitle.value = $event"
              />
            </div>

            <!-- create new activity toggle -->
            <div>
              <CreateNewActivityToggle
                :createNewActivity="createActivity"
                @update:createNewActivity="createActivity = $event"
              />
            </div>

            <!-- create new lead or contact toggle -->
            <div>
              <CreateNewLeadOrContactToggle
                type="hubspot"
                :createNewLeadOrContact="createLeadOrContact"
                @update:createNewLeadOrContact="createLeadOrContact = $event"
              />
            </div>

            <!-- add attendee to campaign or list -->
            <div>
              <AddAttendeeToCampaignOrListToggle
                ref="addAttendeeToCampaignOrListToggleRef"
                type="hubspot"
                :programId="programId"
                :campaignOrList="hubspotList"
                :addAttendeeToCampaignOrList="addAttendeeToCampaignOrList"
                :handleErrorMessage="handleErrorMessage"
                @update:addAttendeeToCampaignOrList="
                  addAttendeeToCampaignOrList = $event
                "
              />
            </div>
          </div>
        </div>
        <!-- footer section -->
        <footer class="flex flex-wrap justify-center mt-auto items-center">
          <FooterSection :isDisabled="isDisabled" @next="goNext" />
        </footer>
      </main>
    </div>
  </div>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  getCurrentInstance,
  onMounted,
  ref,
  watch,
} from "vue";
import StepsList from "@/components/expert-program/StepsList.vue";
import FooterSection from "@/components/expert-program/FooterSection.vue";
import ContactFieldMapping from "@/components/expert-program/integration/ContactFieldMapping.vue";
import CreateNewActivityToggle from "@/components/expert-program/integration/CreateNewActivityToggle.vue";
import CreateNewLeadOrContactToggle from "@/components/expert-program/integration/CreateNewLeadOrContactToggle.vue";
import { ApolloError, gql } from "@apollo/client/core";
import { useMutation, useQuery } from "@vue/apollo-composable";
import router from "@/router";
import { useUserStore } from "@/stores/user";
import { PostHog } from "posthog-js";
import AddAttendeeToCampaignOrListToggle from "@/components/expert-program/integration/AddAttendeeToCampaignOrListToggle.vue";
import { CampaignOrList } from "@/types/integration-types";
import ProviderAccountCard from "@/components/expert-program/integration/ProviderAccountCard.vue";

interface MappingField {
  name: string;
  label: string;
}

interface MappingInput {
  hubspotField: string;
  hubspotObject: string;
  mappedField: string;
}

const GET_PROVIDER_ACCOUNT = gql`
  query getProviderAccount($programId: ID!, $provider: [String!]!) {
    getProviderAccount(programId: $programId, provider: $provider) {
      name
      email
    }
  }
`;

const GET_HUBSPOT_MAPPING_FIELDS = gql`
  query getHubspotMappingFields($programId: ID!) {
    hubspotMappingFields(programId: $programId) {
      name
      label
    }
  }
`;

const CREATE_HUBSPOT_MAPPING_AND_SETTING = gql`
  mutation createHubspotMappingAndSetting(
    $input: CreateHubspotMappingAndSettingInput!
  ) {
    createHubspotMappingAndSetting(input: $input) {
      hubspotSetting {
        createActivity
      }
      errors
    }
  }
`;

export default defineComponent({
  name: "SalesforceIntegrationView",
  components: {
    StepsList,
    FooterSection,
    ContactFieldMapping,
    CreateNewActivityToggle,
    CreateNewLeadOrContactToggle,
    AddAttendeeToCampaignOrListToggle,
    ProviderAccountCard,
  },
  setup() {
    const selectedValue = ref<string | number>("");
    const createActivity = ref<boolean>(false);
    const createLeadOrContact = ref<boolean>(false);
    const addAttendeeToCampaignOrList = ref<boolean>(false);
    const isDisabled = ref<boolean>(false);
    const isLoadingProviderAccount = ref(true);
    const isLoadingHubspotMappingFields = ref(true);
    const hubspotMappingFields = ref<MappingField[]>([]);
    const providerAccountEmail = ref<string>("");
    const providerAccountName = ref<string>("");
    const addAttendeeToCampaignOrListToggleRef = ref<InstanceType<
      typeof AddAttendeeToCampaignOrListToggle
    > | null>(null);
    const hubspotList = ref<CampaignOrList>({
      id: "",
      name: "",
    });
    const errorMessage = ref<string>("");
    const leadMappingFields = ref<MappingField[]>([]);
    const contactMappingFields = ref<MappingField[]>([]);
    const hubspotCampaignsList = ref<CampaignOrList[]>([]);
    const userStore = useUserStore();
    const programId = ref<string>(userStore.createExpertProgram?.id || "");
    const posthog = (getCurrentInstance()?.proxy as { $posthog: PostHog })
      ?.$posthog;
    const hubspotData = {
      contactFirstName: ref(""),
      contactLastName: ref(""),
      contactTitle: ref(""),
    };

    onMounted(() => {
      getHubspotMappingFields();
      getProviderAccountData();
      notifyPosthog();
    });

    const {
      mutate: createHubspotMappingAndSetting,
      onDone: createHubspotMappingAndSettingDone,
      onError: createHubspotMappingAndSettingError,
    } = useMutation(CREATE_HUBSPOT_MAPPING_AND_SETTING);

    const getProviderAccountData = () => {
      const {
        result: providerAccountQueryResult,
        error: providerAccountQueryError,
      } = useQuery(GET_PROVIDER_ACCOUNT, {
        programId: userStore.createExpertProgram?.id,
        provider: ["hubspot"],
      });

      watch(
        () => providerAccountQueryResult.value,
        (newValue) => {
          if (newValue) {
            isLoadingProviderAccount.value = false;
            if (newValue?.getProviderAccount?.email) {
              providerAccountEmail.value = newValue.getProviderAccount.email;
              providerAccountName.value = newValue.getProviderAccount.name;
            }
          }
        },
        { immediate: true }
      );

      watch(
        () => providerAccountQueryError.value,
        (newError) => {
          if (newError) {
            errorMessage.value = newError.message;
          }
        },
        { immediate: true }
      );
    };

    const getHubspotMappingFields = () => {
      const {
        result: hubspotMappingFieldsQueryResult,
        error: hubspotMappingFieldsQueryError,
      } = useQuery(GET_HUBSPOT_MAPPING_FIELDS, {
        programId: userStore.createExpertProgram?.id,
      });

      watch(
        () => hubspotMappingFieldsQueryResult.value,
        (newValue) => {
          if (newValue) {
            isLoadingHubspotMappingFields.value = false;
            if (newValue?.hubspotMappingFields?.length > 0) {
              hubspotMappingFields.value = newValue.hubspotMappingFields;
            }
          }
        },
        { immediate: true }
      );

      watch(
        () => hubspotMappingFieldsQueryError.value,
        (newError) => {
          if (newError) {
            errorMessage.value = newError.message;
          }
        },
        { immediate: true }
      );
    };

    const handleCreateHubspotMappingAndSetting = () => {
      isDisabled.value = true;
      const params = payload();
      createHubspotMappingAndSetting({
        input: {
          params: params,
          createActivity: createActivity.value,
          createContact: createLeadOrContact.value,
          programId: userStore.createExpertProgram?.id,
          addAttendeeToList: addAttendeeToCampaignOrList.value,
          listSettings: hubspotCampaignValues(),
        },
      });

      createHubspotMappingAndSettingDone((response) => {
        if (response) {
          isDisabled.value = false;
          const data = response.data.createHubspotMappingAndSetting;
          if (response.errors && response.errors.length > 0) {
            errorMessage.value = response.errors[0].message;
            scrollToTop();
          } else {
            if (
              data.hubspotSetting.errors &&
              data.hubspotSetting.errors.length > 0
            ) {
              errorMessage.value = data.hubspotSetting.errors.join(", ");
              scrollToTop();
            } else {
              userStore.updateCrmSynced(true);
              router.push({ name: "InviteExperts" });
            }
          }
        }
      });

      createHubspotMappingAndSettingError((error: ApolloError) => {
        errorMessage.value = error.message;
        isDisabled.value = false;
        scrollToTop();
      });
    };

    const hubspotCampaignValues = (): CampaignOrList => {
      return {
        id: hubspotList.value?.id || "",
        name: hubspotList.value?.name || "",
      };
    };

    const invalidHubspotData = (): boolean => {
      return Object.values(hubspotData).some(
        (field) => field.value.trim() === ""
      );
    };

    const notifyPosthog = () => {
      if (posthog) {
        posthog.capture("Company_User_Syncs_CRM", {
          email: userStore.email,
          CRM: "Hubspot",
        });
      }
    };

    const payload = (): MappingInput[] => {
      const mappingInput: MappingInput[] = [];

      mappingInput.push({
        hubspotField: "first_name",
        hubspotObject: "contact",
        mappedField: hubspotData.contactFirstName.value,
      });
      mappingInput.push({
        hubspotField: "last_name",
        hubspotObject: "contact",
        mappedField: hubspotData.contactLastName.value,
      });
      mappingInput.push({
        hubspotField: "title",
        hubspotObject: "contact",
        mappedField: hubspotData.contactTitle.value,
      });

      return mappingInput;
    };

    const handleErrorMessage = (message: string) => {
      errorMessage.value = message;
    };

    const scrollToTop = () => {
      const c = document.documentElement.scrollTop || document.body.scrollTop;
      if (c > 0) {
        window.requestAnimationFrame(scrollToTop);
        window.scrollTo(0, c - c / 8);
      }
    };

    const goNext = () => {
      if (invalidHubspotData()) {
        errorMessage.value = "Please select all fields.";
        scrollToTop();
        return;
      }
      if (invalidHubspotCampaign()) {
        errorMessage.value = "Please select a campaign.";
        scrollToTop();
        return;
      }
      handleCreateHubspotMappingAndSetting();
    };

    const invalidHubspotCampaign = (): boolean => {
      hubspotList.value =
        addAttendeeToCampaignOrListToggleRef?.value?.localCampaignOrList ||
        ({
          id: "",
          name: "",
        } as CampaignOrList);

      if (addAttendeeToCampaignOrList.value) {
        if (hubspotList.value === null) {
          return true;
        }
        if (hubspotList.value?.id === "" || hubspotList.value?.name === "") {
          return true;
        }
      }
      return false;
    };

    const isLoading = computed(() => {
      return (
        isLoadingHubspotMappingFields.value || isLoadingProviderAccount.value
      );
    });

    return {
      selectedValue,
      leadMappingFields,
      contactMappingFields,
      createActivity,
      createLeadOrContact,
      addAttendeeToCampaignOrList,
      hubspotCampaignsList,
      hubspotList,
      goNext,
      isDisabled,
      hubspotData,
      isLoadingProviderAccount,
      providerAccountEmail,
      providerAccountName,
      errorMessage,
      isLoading,
      handleErrorMessage,
      userStore,
      programId,
      hubspotMappingFields,
      addAttendeeToCampaignOrListToggleRef,
    };
  },
});
</script>
