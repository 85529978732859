<!-- ModalComponent.vue -->
<template>
  <div
    v-if="isVisible"
    class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
  >
    <div
      v-if="isLoadingData"
      class="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
    >
      <!-- Spinner -->
      <div
        class="animate-spin rounded-full h-32 w-32 border-t-4 border-b-4 border-primary-purple"
      ></div>
    </div>
    <div
      id="sync-hubspot-modal"
      class="bg-white p-6 rounded-lg shadow-lg w-full sm:w-full md:w-4/5 lg:w-1/2 overflow-x-auto max-h-[90vh] overflow-y-auto"
    >
      <div class="flex flex-wrap justify-between text-left py-3">
        <span class="text-sm leading-5 font-medium text-neutral-800"
          >Sync'd Salesforce Account</span
        >
        <BaseButton
          type="button"
          class="text-lg leading-5 font-medium text-neutral-800"
          :disabled="createHubspotMappingAndSettingLoading"
          @click="closeModal"
          >X</BaseButton
        >
      </div>
      <!-- provider account details -->
      <ProviderAccountCard
        :accountName="providerAccountName"
        :accountEmail="providerAccountEmail"
      />

      <!-- Error div -->
      <div class="flex justify-center mb-4">
        <div
          v-if="errorMessage"
          role="alert"
          class="flex gap-2 w-full justify-center p-2.5 mt-6 text-sm leading-5 text-center text-red-900 bg-red-50 rounded-lg max-md:flex-wrap"
          style="max-height: 80px; overflow-y: auto"
        >
          <!-- Icon Container -->
          <div
            class="flex-shrink-0 w-6 h-6 flex items-center justify-center rounded-full"
          >
            <!-- Custom Image Icon -->
            <img
              src="../../../../assets/shared/failure.svg"
              alt="Error Icon"
              class="w-4 h-4"
            />
          </div>
          <p>{{ errorMessage }}</p>
        </div>
      </div>

      <!-- mapping fields (lead and contact) -->
      <div
        class="flex flex-col text-left mb-24 gap-4 py-4 max-w-[60%] md:max-w-[70%] lg:max-w-[80%]"
      >
        <!-- lead or contact fields mapping -->
        <div>
          <ContactFieldMapping
            :contactMappingFields="hubspotMappingFields"
            :contactFirstName="hubspotData.contactFirstName.value.value"
            :contactLastName="hubspotData.contactLastName.value.value"
            :contactTitle="hubspotData.contactTitle.value.value"
            @update:contactFirstName="
              hubspotData.contactFirstName.value.value = $event
            "
            @update:contactLastName="
              hubspotData.contactLastName.value.value = $event
            "
            @update:contactTitle="hubspotData.contactTitle.value.value = $event"
          />
        </div>

        <!-- create new activity toggle -->
        <div>
          <CreateNewActivityToggle
            :createNewActivity="createActivity"
            @update:createNewActivity="createActivity = $event"
          />
        </div>

        <!-- create new lead or contact toggle -->
        <div>
          <CreateNewLeadOrContactToggle
            type="hubspot"
            :createNewLeadOrContact="createContact"
            @update:createNewLeadOrContact="createContact = $event"
          />
        </div>

        <!-- add attendee to campaign or list -->
        <div>
          <AddAttendeeToCampaignOrListToggle
            ref="addAttendeeToCampaignOrListToggleRef"
            type="hubspot"
            :programId="programId"
            :campaignOrList="hubspotList"
            :addAttendeeToCampaignOrList="addAttendeeToCampaignOrList"
            :handleErrorMessage="handleErrorMessage"
            @update:addAttendeeToCampaignOrList="
              addAttendeeToCampaignOrList = $event
            "
          />
        </div>
      </div>
      <div class="flex justify-end gap-3 mt-4">
        <BaseButton
          type="button"
          @click="closeModal"
          :disabled="createHubspotMappingAndSettingLoading"
          class="text-black border border-gray-300 px-4 py-2 rounded"
        >
          Close
        </BaseButton>
        <BaseButton
          type="button"
          @click="handleCreateOrUpdateHubspotMappingAndSetting"
          :disabled="createHubspotMappingAndSettingLoading"
          class="bg-primary-purple text-white px-4 py-2 rounded"
        >
          <span v-if="!createHubspotMappingAndSettingLoading">Update</span>
          <span v-else>Updating...</span>
        </BaseButton>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { ApolloError, gql } from "@apollo/client/core";
import { useMutation, useQuery } from "@vue/apollo-composable";
import { computed, defineComponent, nextTick, ref, watch } from "vue";
import ContactFieldMapping from "@/components/expert-program/integration/ContactFieldMapping.vue";
import CreateNewActivityToggle from "@/components/expert-program/integration/CreateNewActivityToggle.vue";
import CreateNewLeadOrContactToggle from "@/components/expert-program/integration/CreateNewLeadOrContactToggle.vue";
import BaseButton from "@/components/shared/BaseButton.vue";
import AddAttendeeToCampaignOrListToggle from "@/components/expert-program/integration/AddAttendeeToCampaignOrListToggle.vue";
import { CampaignOrList } from "@/types/integration-types";
import ProviderAccountCard from "@/components/expert-program/integration/ProviderAccountCard.vue";

interface MappingField {
  name: string;
  label: string;
}

interface MappingInput {
  id: string;
  hubspotField: string;
  hubspotObject: string;
  mappedField: string;
}

interface HubspotRecord {
  id: string;
  mappedField: string;
  hubspotField: "first_name" | "last_name" | "title"; // limiting to specific fields
  hubspotObject: "contact"; // limiting to specific objects
  value: string; // the value to assign
}

const GET_HUBSPOT_MAPPING_FIELDS = gql`
  query getHubspotMappingFields($programId: ID!) {
    hubspotMappingFields(programId: $programId) {
      name
      label
    }
  }
`;

const GET_HUBSPOT_PROGRAM_PROGRAM_MAPPING_FIELDS = gql`
  query getHubspotProgramMappingFields($programId: ID!) {
    hubspotProgramMappingFields(programId: $programId) {
      id
      hubspotField
      mappedField
    }
  }
`;

const GET_HUBSPOT_PROGRAM_SETTINGS = gql`
  query getHubspotProgramSettings($programId: ID!) {
    hubspotProgramSetting(programId: $programId) {
      id
      createActivity
      createContact
      addAttendeeToList
      listId
      listName
    }
  }
`;

const CREATE_HUBSPOT_MAPPING_AND_SETTING = gql`
  mutation createHubspotMappingAndSetting(
    $input: CreateHubspotMappingAndSettingInput!
  ) {
    createHubspotMappingAndSetting(input: $input) {
      hubspotSetting {
        createActivity
      }
      errors
    }
  }
`;

export default defineComponent({
  name: "ModalComponent",
  components: {
    BaseButton,
    ContactFieldMapping,
    CreateNewActivityToggle,
    CreateNewLeadOrContactToggle,
    AddAttendeeToCampaignOrListToggle,
    ProviderAccountCard,
  },
  props: {
    isVisible: {
      type: Boolean,
      required: true,
    },
    providerAccountName: {
      type: String,
      required: true,
    },
    providerAccountEmail: {
      type: String,
      required: true,
    },
    programId: {
      type: String,
      required: true,
    },
  },
  emits: ["close", "updatedSettings"],
  setup(props, { emit }) {
    const isLoadingHubspotMappingFields = ref(false);
    const isLoadingHubspotProgramSettings = ref(false);
    const createHubspotMappingAndSettingLoading = ref(false);
    const hubspotMappingFields = ref<MappingField[]>([]);
    const loadingCurrentSetting = ref(false);
    const errorMessage = ref("");
    const createActivity = ref(false);
    const createContact = ref(false);
    const addAttendeeToCampaignOrList = ref<boolean>(false);
    const addAttendeeToCampaignOrListToggleRef = ref<InstanceType<
      typeof AddAttendeeToCampaignOrListToggle
    > | null>(null);
    const hubspotList = ref<CampaignOrList>({
      id: "",
      name: "",
    });
    const hubspotData = {
      contactFirstName: {
        id: "",
        value: ref(""),
      },
      contactLastName: {
        id: "",
        value: ref(""),
      },
      contactTitle: {
        id: "",
        value: ref(""),
      },
    };

    const isModalVisible = computed(() => props.isVisible);

    watch(
      isModalVisible,
      (newVal) => {
        if (newVal) {
          errorMessage.value = "";
          getHubspotMappingFields();
          getHubspotProgramSettings();
          getHubspotProgramMappingFields();
        }
      },
      { immediate: true }
    );

    const {
      mutate: createHubspotMappingAndSetting,
      onDone: createHubspotMappingAndSettingDone,
      onError: createHubspotMappingAndSettingError,
    } = useMutation(CREATE_HUBSPOT_MAPPING_AND_SETTING);

    const getHubspotProgramSettings = () => {
      isLoadingHubspotProgramSettings.value = true;

      const {
        result: hubspotProgramSettingsQueryResult,
        error: hubspotProgramSettingsQueryError,
        refetch: refetchHubspotProgramSettings,
      } = useQuery(GET_HUBSPOT_PROGRAM_SETTINGS, {
        programId: props.programId,
      });

      refetchHubspotProgramSettings();

      watch(
        () => hubspotProgramSettingsQueryResult.value,
        (newValue) => {
          if (newValue) {
            if (newValue.hubspotProgramSetting) {
              hubspotList.value = {
                id: newValue.hubspotProgramSetting.listId,
                name: newValue.hubspotProgramSetting.listName,
              };
              addAttendeeToCampaignOrList.value =
                newValue.hubspotProgramSetting.addAttendeeToList;
              createActivity.value =
                newValue.hubspotProgramSetting.createActivity;
              createContact.value =
                newValue.hubspotProgramSetting.createContact;
              isLoadingHubspotProgramSettings.value = false;
            } else {
              isLoadingHubspotProgramSettings.value = false;
            }
          }
        },
        { immediate: true }
      );

      watch(
        () => hubspotProgramSettingsQueryError.value,
        (newError) => {
          if (newError) {
            handleErrorMessage(newError.message);
            scrollToTop();
          }
        },
        { immediate: true }
      );
    };

    const getHubspotProgramMappingFields = () => {
      loadingCurrentSetting.value = true;

      const {
        result: hubspotProgramMappingFieldsQueryResult,
        error: hubspotProgramMappingFieldsQueryError,
        refetch: refetchHubspotProgramMappingFields,
      } = useQuery(GET_HUBSPOT_PROGRAM_PROGRAM_MAPPING_FIELDS, {
        programId: props.programId,
      });

      refetchHubspotProgramMappingFields();

      watch(
        () => hubspotProgramMappingFieldsQueryResult.value,
        (newValue) => {
          if (newValue) {
            populateHubspotData(newValue.hubspotProgramMappingFields);
          }
        },
        { immediate: true }
      );

      watch(
        () => hubspotProgramMappingFieldsQueryError.value,
        (newError) => {
          if (newError) {
            handleErrorMessage(newError.message);
            scrollToTop();
          }
        },
        { immediate: true }
      );
    };

    const getHubspotMappingFields = () => {
      isLoadingHubspotMappingFields.value = true;
      const {
        result: mappingFieldsQueryResult,
        error: mappingFieldsQueryError,
        refetch: refetchMappingFields,
      } = useQuery(GET_HUBSPOT_MAPPING_FIELDS, {
        programId: props.programId,
      });

      refetchMappingFields();

      watch(
        () => mappingFieldsQueryResult.value,
        (newValue) => {
          if (newValue) {
            if (newValue?.hubspotMappingFields?.length > 0) {
              handleMappingFields(newValue.hubspotMappingFields);
            }
          }
        },
        { immediate: true }
      );

      watch(
        () => mappingFieldsQueryError.value,
        (newError) => {
          if (newError) {
            handleErrorMessage(newError.message);
            scrollToTop();
          }
        },
        { immediate: true }
      );
    };

    const populateHubspotData = async (records: HubspotRecord[]) => {
      records.forEach((record) => {
        const { hubspotField, mappedField, id } = record;
        switch (hubspotField) {
          case "first_name":
            hubspotData.contactFirstName.value.value = mappedField;
            hubspotData.contactFirstName.id = id;
            break;
          case "last_name":
            hubspotData.contactLastName.value.value = mappedField;
            hubspotData.contactLastName.id = id;
            break;
          case "title":
            hubspotData.contactTitle.value.value = mappedField;
            hubspotData.contactTitle.id = id;
            break;
        }
      });
      await nextTick();
      loadingCurrentSetting.value = false;
    };

    const handleMappingFields = (mappingFields: MappingField[]) => {
      hubspotMappingFields.value = mappingFields;
      isLoadingHubspotMappingFields.value = false;
    };

    const invalidHubspotData = (): boolean => {
      return Object.values(hubspotData).some(
        (field) => field.value.value.trim() === ""
      );
    };

    const invalidHubspotList = (): boolean => {
      hubspotList.value =
        addAttendeeToCampaignOrListToggleRef?.value?.localCampaignOrList ||
        ({
          id: "",
          name: "",
        } as CampaignOrList);

      if (addAttendeeToCampaignOrList.value) {
        if (hubspotList.value === null) {
          return true;
        }
        if (hubspotList.value?.id === "" || hubspotList.value?.name === "") {
          return true;
        }
      }
      return false;
    };

    const payload = (): MappingInput[] => {
      const mappingInput: MappingInput[] = [];

      interface HubspotDataField {
        id?: string;
        value: {
          value: string;
        };
      }

      const addMapping = (
        data: HubspotDataField,
        hubspotField: string,
        hubspotObject: string
      ) => {
        const mapping: Partial<MappingInput> = {
          hubspotField,
          hubspotObject,
          mappedField: data.value.value,
        };
        if (data.id) {
          mapping.id = data.id;
        }
        mappingInput.push(mapping as MappingInput);
      };

      addMapping(hubspotData.contactFirstName, "first_name", "contact");
      addMapping(hubspotData.contactLastName, "last_name", "contact");
      addMapping(hubspotData.contactTitle, "title", "contact");

      return mappingInput;
    };

    const hubspotListValues = (): CampaignOrList => {
      return {
        id: hubspotList.value?.id || "",
        name: hubspotList.value?.name || "",
      };
    };

    const createOrUpdateHubspotMappingAndSetting = () => {
      errorMessage.value = "";
      createHubspotMappingAndSettingLoading.value = true;
      const params = payload();
      createHubspotMappingAndSetting({
        input: {
          params: params,
          createActivity: createActivity.value,
          createContact: createContact.value,
          programId: props.programId,
          addAttendeeToList: addAttendeeToCampaignOrList.value,
          listSettings: hubspotListValues(),
        },
      });

      createHubspotMappingAndSettingDone((response) => {
        if (response) {
          createHubspotMappingAndSettingLoading.value = false;
          const data = response.data.createHubspotMappingAndSetting;
          if (response.errors && response.errors.length > 0) {
            errorMessage.value = response.errors[0].message;
            scrollToTop();
          } else {
            if (
              data.hubspotSetting.errors &&
              data.hubspotSetting.errors.length > 0
            ) {
              errorMessage.value = data.hubspotSetting.errors.join(", ");
              scrollToTop();
            } else {
              emit("updatedSettings");
            }
          }
        }
      });

      createHubspotMappingAndSettingError((error: ApolloError) => {
        errorMessage.value = error.message;
        createHubspotMappingAndSettingLoading.value = false;
        scrollToTop();
      });
    };

    const handleCreateOrUpdateHubspotMappingAndSetting = () => {
      if (invalidHubspotData()) {
        errorMessage.value = "Please select all fields.";
        scrollToTop();
        return;
      }
      if (invalidHubspotList()) {
        errorMessage.value = "Please select a list.";
        scrollToTop();
        return;
      }
      createOrUpdateHubspotMappingAndSetting();
    };

    const handleErrorMessage = (message: string) => {
      loadingCurrentSetting.value = false;
      errorMessage.value = message;
      setTimeout(() => {
        errorMessage.value = "";
      }, 8500);
    };

    const scrollToTop = () => {
      const element = document.getElementById("sync-hubspot-modal"); // Directly target the modal by ID
      if (!element) return; // Exit if the modal is not found

      const scrollTop = element.scrollTop;
      if (scrollTop > 0) {
        window.requestAnimationFrame(scrollToTop); // Recursively call until at the top
        element.scrollTop = scrollTop - scrollTop / 8;
      }
    };

    const closeModal = () => {
      emit("close");
    };

    const isLoadingData = computed(() => {
      return loadingCurrentSetting.value || isLoadingHubspotMappingFields.value;
    });

    return {
      closeModal,
      isLoadingData,
      createActivity,
      createContact,
      errorMessage,
      hubspotData,
      hubspotMappingFields,
      handleCreateOrUpdateHubspotMappingAndSetting,
      createHubspotMappingAndSettingLoading,
      addAttendeeToCampaignOrList,
      hubspotList,
      handleErrorMessage,
      addAttendeeToCampaignOrListToggleRef,
    };
  },
});
</script>
