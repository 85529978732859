import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "flex flex-col p-4 gap-4 border border-solid border-gray-300 bg-white rounded-lg" }
const _hoisted_2 = {
  key: 0,
  class: "fixed inset-0 bg-black bg-opacity-25 flex justify-center items-center z-50"
}
const _hoisted_3 = { class: "flex flex-row justify-between items-center gap-4" }
const _hoisted_4 = { class: "flex flex-col gap-3" }
const _hoisted_5 = { class: "text-gray-800 font-sans text-lg font-semibold leading-6 tracking-tight" }
const _hoisted_6 = { class: "flex flex-col" }
const _hoisted_7 = { class: "flex flex-col justify-center" }
const _hoisted_8 = { class: "flex flex-row gap-2 justify-between items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseSearchableDropdown = _resolveComponent("BaseSearchableDropdown")!
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_CreateNewCampaignOrListModal = _resolveComponent("CreateNewCampaignOrListModal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isLoadingSalesforce)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _cache[3] || (_cache[3] = [
          _createElementVNode("div", { class: "animate-spin rounded-full h-32 w-32 border-t-4 border-b-4 border-primary-purple" }, null, -1)
        ])))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.title(_ctx.type)), 1)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("button", {
            class: _normalizeClass(["flex items-center w-12 h-6 rounded-xl transition-colors duration-300", {
              'bg-violet-600': _ctx.addAttendeeToCampaignOrList,
              'bg-gray-200': !_ctx.addAttendeeToCampaignOrList,
            }]),
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleAddAttendeeToCampaignOrList && _ctx.toggleAddAttendeeToCampaignOrList(...args))),
            "aria-label": "Toggle button"
          }, [
            _createElementVNode("span", {
              class: _normalizeClass(["flex self-stretch my-auto w-5 h-5 bg-white rounded-full transition-transform duration-300 transform", {
                'translate-x-6': _ctx.addAttendeeToCampaignOrList,
                'translate-x-0.5': !_ctx.addAttendeeToCampaignOrList,
              }])
            }, null, 2)
          ], 2)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createVNode(_component_BaseSearchableDropdown, {
        modelValue: _ctx.selectedCampaignOrListName,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedCampaignOrListName) = $event)),
        options: _ctx.campaignOrListNames,
        placeholder: _ctx.addAttendeeToCampaignOrListPlaceholderText(_ctx.type),
        class: "w-32 md:w-64",
        onChange: _ctx.handleCampaignOrListSelection
      }, null, 8, ["modelValue", "options", "placeholder", "onChange"]),
      _cache[4] || (_cache[4] = _createElementVNode("span", { class: "text-gray-600 font-sans text-sm font-medium leading-5" }, "Or", -1)),
      _createVNode(_component_BaseButton, {
        type: "button",
        class: "flex max-w-max flex-col justify-center items-center px-2 py-3 w-full bg-indigo-50 text-primary-purple rounded-md text-md font-medium leading-none text-center",
        onClick: _ctx.createNewCampaignOrList
      }, {
        default: _withCtx(() => [
          _createElementVNode("span", null, _toDisplayString(_ctx.buttonText(_ctx.type)), 1)
        ]),
        _: 1
      }, 8, ["onClick"])
    ]),
    _createVNode(_component_CreateNewCampaignOrListModal, {
      programId: _ctx.programId,
      type: _ctx.type,
      isVisible: _ctx.isCreateNewCampaignOrListModalVisible,
      handleNewCampaignOrListCreated: _ctx.handleNewCampaignOrListCreated,
      "onUpdate:isVisible": _cache[2] || (_cache[2] = ($event: any) => (_ctx.isCreateNewCampaignOrListModalVisible = $event))
    }, null, 8, ["programId", "type", "isVisible", "handleNewCampaignOrListCreated"])
  ]))
}