export const fontFamilies = [
  "Albert Sans",
  "Bebas Neue",
  "DM Sans",
  "Fira Sans",
  "IBM Plex Sans",
  "Inter",
  "Lato",
  "Manrope",
  "Montserrat",
  "Mulish",
  "Nunito Sans",
  "Open Sans",
  "Outfit",
  "Poppins",
  "Public Sans",
  "Raleway",
  "Roboto",
  "Roboto Condensed",
  "Roboto Mono",
  "Source Sans Pro",
  "Ubuntu",
  "Work Sans",
];

export const googleFontFamiliesLink =
  "https://fonts.googleapis.com/css2?family=Albert+Sans&family=Bebas+Neue&family=DM+Sans&family=Fira+Sans&family=IBM+Plex+Sans&family=Inter&family=Lato&family=Manrope&family=Montserrat&family=Mulish&family=Nunito+Sans&family=Open+Sans&family=Outfit&family=Poppins&family=Public+Sans&family=Raleway&family=Roboto&family=Roboto+Condensed&family=Roboto+Mono&family=Source+Sans+Pro&family=Ubuntu&family=Work+Sans&display=swap";
