<template>
  <div class="flex flex-wrap justify-between p-4">
    <div class="flex flex-col text-left">
      <span class="text-gray-900 font-inter text-base font-medium leading-6"
        >Widgets</span
      >
      <span class="text-gray-500 font-inter text-sm font-normal leading-8"
        >You can update the widget components</span
      >
    </div>
    <div class="flex flex-col" v-if="!showEditControls">
      <div class="flex flex-wrap gap-6">
        <BaseButton
          v-if="
            (topHeaderBarId && topHeaderBarVisible) ||
            (popupModalId && popupModalVisible)
          "
          type="button"
          class="gap-2 text-primary-purple font-inter text-base font-medium leading-6"
          @click="showCodeModal = true"
          >Setup/Install Code
        </BaseButton>
        <BaseButton
          type="button"
          class="gap-2 text-primary-purple font-inter text-base font-medium leading-6"
          @click="handleEditWidget"
        >
          <div class="flex flex-wrap gap-2">
            <div v-html="EDIT_ICON"></div>
            Edit
          </div></BaseButton
        >
      </div>
    </div>
    <div v-else class="flex flex-col">
      <div class="flex flex-wrap gap-2 items-center">
        <span v-if="updateWidgetLoading" class="text-primary-purple"
          >Updating...</span
        >
        <BaseButton
          type="button"
          class="text-black border p-2 rounded-lg border-gray-300 font-inter text-base font-medium leading-6"
          @click="cancelEditWidget"
          :disabled="updateWidgetLoading"
        >
          Cancel
        </BaseButton>
        <BaseButton
          type="button"
          class="bg-primary-purple text-white p-2 rounded-lg font-inter text-base font-medium leading-6"
          @click="handleUpdateWidget"
          :disabled="updateWidgetLoading"
        >
          Update
        </BaseButton>
      </div>
    </div>
  </div>
  <div class="flex justify-center mb-4">
    <div
      v-if="errorMessage"
      role="alert"
      class="flex gap-2 w-full justify-center p-2.5 mt-6 text-sm leading-5 text-center text-red-900 bg-red-50 rounded-lg max-md:flex-wrap"
      style="max-height: 80px; overflow-y: auto"
    >
      <!-- Icon Container -->
      <div
        class="flex-shrink-0 w-6 h-6 flex items-center justify-center rounded-full"
      >
        <!-- Custom Image Icon -->
        <img
          src="../../../../assets/shared/failure.svg"
          alt="Error Icon"
          class="w-4 h-4"
        />
      </div>
      <p>{{ errorMessage }}</p>
    </div>
  </div>
  <div class="flex gap-5 max-md:flex-col">
    <section
      class="flex flex-col mx-auto bg-gray-200"
      :style="{
        height: !showEditControls ? widgetPreviewHeight + 'px' : 'auto',
      }"
      :class="{
        'w-[63%]': showEditControls,
        'w-full': !showEditControls,
      }"
    >
      <div v-if="topHeaderBarVisible" class="flex flex-col">
        <TopHeaderBar
          :topheaderText="topheaderText"
          :buttonText="buttonText"
          :buttonTextColor="buttonTextColor"
          :fontSize="fontSize"
          :fontFamily="fontFamily"
          :fontBoldness="boldness"
          :headerBackgroundColor="headerBackgroundColor"
          :headerTextColor="headerTextColor"
          :buttonBackgrounColor="headerButtonBackgroundColor"
          :expertsDirectoryUrl="topHeaderExpertsDirectoryUrl"
          :disableCloseButton="false"
        />
      </div>

      <div
        v-if="popupModalVisible"
        class="flex flex-col"
        :class="{
          'items-end': !popupModalLeftOrientation,
          'items-start': popupModalLeftOrientation,
          'mt-auto': topHeaderBarVisible,
        }"
      >
        <PopupModal
          :fontFamily="popupModalfontFamily"
          :fontSize="popupModalfontSize"
          :fontBoldness="popupModalBoldness"
          :expertsDirectoryUrl="popupModalExpertsDirectoryUrl"
          :disableCloseButton="false"
          :buttonTextColor="popupModalButtonTextColor"
          :buttonText="popupModalButtonText"
          :bodyText="popupModalBodyText"
          :buttonBackgroundColor="popupModalButtonBackgroundColor"
          :textColor="popupModalTextColor"
          :bannerBackgroundColor="popupModalBannerBackgroundColor"
        />
      </div>
    </section>
    <section
      v-if="showEditControls"
      class="flex flex-col h-full ml-5 w-[37%] max-md:ml-0 max-md:w-full"
    >
      <div class="flex flex-col max-md:mt-8">
        <div class="flex flex-col">
          <TopHeaderBarDesign
            :isVisible="topHeaderBarVisible"
            :fontFamily="fontFamily"
            :fontSize="fontSize"
            :buttonTextColor="buttonTextColor"
            :boldness="boldness"
            :topHeaderBackgroundColor="headerBackgroundColor"
            :topHeaderbuttonBackgroundColor="headerButtonBackgroundColor"
            :topHeaderTextColor="headerTextColor"
            :headerText="topheaderText"
            :buttonText="buttonText"
            :topHeaderExpertsDirectoryUrl="topHeaderExpertsDirectoryUrl"
            @expertsDirectoryUrlChanged="
              handleTopHeaderExpertsDirectoryUrlChange
            "
            @toggleVisibility="handleTopHeaderBarVisibilityChange"
            @buttonTextChanged="handleButtonTextChange"
            @fontFamilyChanged="handleFontChange"
            @boldnessChanged="handleBoldnessChange"
            @buttonTextColorChanged="handleButtonTextColorChange"
            @headerBackgroundColorChanged="handleHeaderBackgroundColorChange"
            @buttonBackgroundColorChanged="handleButtonBackgroundColorChange"
            @headerTextColorChanged="handleHeaderTextColorChange"
            @fontSizeChanged="handleFontSizeChange"
            @headerTextChanged="handleTopHeaderTextChange"
          />

          <PopupModalDesign
            :isVisible="popupModalVisible"
            :fontFamily="popupModalfontFamily"
            :fontSize="popupModalfontSize"
            :boldness="popupModalBoldness"
            :popupButtonTextColor="popupModalButtonTextColor"
            :popupButtonText="popupModalButtonText"
            :popupBodyText="popupModalBodyText"
            :popupExpertsDirectoryUrl="popupModalExpertsDirectoryUrl"
            :popupLeftOrientation="popupModalLeftOrientation"
            :popupButtonBackgroundColor="popupModalButtonBackgroundColor"
            :popupTextColor="popupModalTextColor"
            :popupBannerBackgroundColor="popupModalBannerBackgroundColor"
            :popupMobileVisibility="popupModalMobileVisibility"
            @toggleVisibility="handlePopupModalVisibilityChange"
            @fontFamilyChanged="handlePopupModalFontChange"
            @boldnessChanged="handlePopupModalBoldnessChange"
            @fontSizeChanged="handlePopupModalFontSizeChange"
            @leftOrientationChanged="handlePopupModalLeftOrientationChange"
            @buttonTextColorChanged="handlePopupModalButtonTextColorChange"
            @buttonTextChanged="handlePopupModalButtonTextChange"
            @bodyTextChanged="handlePopupModalBodyTextChange"
            @expertsDirectoryUrlChanged="
              handlePopupModalExpertsDirectoryUrlChange
            "
            @buttonBackgroundColorChanged="
              handlePopupModalButtonBackgroundColorChange
            "
            @textColorChanged="handlePopupModalTextColorChanged"
            @bannerBackgroundColorChanged="
              handlePopupModalBannerBackgroundColorChange
            "
            @mobileVisibilityChanged="handlePopupModalMobileVisibilityChange"
          />
        </div>
      </div>
    </section>
    <WidgetCodeModal
      v-show="topHeaderBarId || popupModalId"
      :isVisible="showCodeModal"
      :topHeaderBarId="topHeaderBarId"
      :popupModalId="popupModalId"
      :topHeaderBarVisible="topHeaderBarVisible"
      :popupModalVisible="popupModalVisible"
      @closeCodeModal="showCodeModal = false"
    />
  </div>
</template>

<script lang="ts">
import { gql } from "@apollo/client/core";
import WidgetCodeModal from "./WidgetCodeModal.vue";
import { useMutation, useQuery } from "@vue/apollo-composable";
import { defineComponent, onMounted, ref } from "vue";
import { WidgetConfig, WidgetPayload } from "@/types/dashboard-types";
import { googleFontFamiliesLink } from "@/data/expert-program/font-families";
import TopHeaderBar from "@/components/expert-program/widget/TopHeaderBar.vue";
import PopupModal from "@/components/expert-program/widget/PopupModal.vue";
import BaseButton from "@/components/shared/BaseButton.vue";
import { EDIT_ICON } from "@/assets/svg/shared/svg-constants";
import TopHeaderBarDesign from "@/components/expert-program/widget/TopHeaderBarDesign.vue";
import PopupModalDesign from "@/components/expert-program/widget/PopupModalDesign.vue";
import { useUserStore } from "@/stores/user";
import { checkUrlProtocol } from "@/composables/useCommon";

const GET_WIDGET_DATA = gql`
  query GetProgramWidgetData($programId: ID!) {
    getProgramWidgetDetail(programId: $programId) {
      id
      errors
      widgetCosmetic {
        headerBar
        buttonText
        buttonColor
        popUp
        bodyText
        fontFamily
        fontSize
        textColor
        backgroundColor
        popUpOrientationLeft
        bold
        expertsDirectoryUrl
        buttonTextColor
        mobileVisibility
      }
    }
  }
`;

const UPDATE_WIDGET = gql`
  mutation updateWidget($input: UpdateWidgetInput!) {
    updateWidget(input: $input) {
      widgetIds
      success
      errors
    }
  }
`;

export default defineComponent({
  name: "ViewWidget",
  components: {
    TopHeaderBar,
    PopupModal,
    BaseButton,
    TopHeaderBarDesign,
    PopupModalDesign,
    WidgetCodeModal,
  },

  mounted() {
    const link = document.createElement("link");
    link.href = googleFontFamiliesLink;
    link.rel = "stylesheet";
    document.head.appendChild(link);
  },

  emits: ["hideDirectory"],
  setup(_, { emit }) {
    const userStore = useUserStore();
    const showCodeModal = ref(false);
    const widgetData = ref<WidgetConfig>();
    const topHeaderBarId = ref("");
    const popupModalId = ref("");
    const topHeaderBarWidgetData = ref<WidgetConfig>();
    const popupModalWidgetData = ref<WidgetConfig>();
    const showEditControls = ref(false);
    const errorMessage = ref("");
    const topHeaderBarVisible = ref(true);
    const popupModalVisible = ref(true);
    const fontFamily = ref("Lato");
    const fontSize = ref("14");
    const boldness = ref("400");
    const buttonTextColor = ref("#121111");
    const headerBackgroundColor = ref("#121111");
    const headerButtonBackgroundColor = ref("#FFFFFF");
    const headerTextColor = ref("#FFFFFF");
    const topheaderText = ref("Booking System");
    const buttonText = ref("Button");
    const topHeaderExpertsDirectoryUrl = ref("https://yourwebsite.com/experts");
    const popupModalExpertsDirectoryUrl = ref(
      "https://yourwebsite.com/experts"
    );
    const popupModalfontFamily = ref("Lato");
    const popupModalfontSize = ref("14");
    const popupModalBoldness = ref("400");
    const popupModalButtonTextColor = ref("#121111");
    const popupModalButtonText = ref("Button");
    const popupModalBodyText = ref("Body");
    const popupModalLeftOrientation = ref(false);
    const popupModalButtonBackgroundColor = ref("#FFFFFF");
    const popupModalTextColor = ref("#FFFFFF");
    const popupModalBannerBackgroundColor = ref("#6a48f3");
    const popupModalMobileVisibility = ref(true);
    const widgetPreviewHeight = ref(0);

    const {
      onResult: widgetFetchResult,
      onError: widgetFetchError,
      refetch: refetchWidgetData,
    } = useQuery(GET_WIDGET_DATA, {
      programId: userStore.selectedProgram?.id,
      fetchPolicy: "network-only",
    });

    const {
      mutate: updateWidget,
      onDone: updateWidgetDone,
      onError: updateWidgetError,
      loading: updateWidgetLoading,
    } = useMutation(UPDATE_WIDGET);

    const handleEditWidget = () => {
      showEditControls.value = true;
      emit("hideDirectory", true);
    };

    const cancelEditWidget = async () => {
      errorMessage.value = "";
      showEditControls.value = false;
      refetchWidgetData();
      emit("hideDirectory", false);
    };

    const handleTopHeaderExpertsDirectoryUrlChange = (value: string) => {
      topHeaderExpertsDirectoryUrl.value = value;
    };

    const handleTopHeaderBarVisibilityChange = (value: boolean) => {
      topHeaderBarVisible.value = value;
    };

    const handleButtonTextChange = (value: string) => {
      buttonText.value = value;
    };

    const handleFontChange = (value: string) => {
      fontFamily.value = value;
    };

    const handleBoldnessChange = (value: string) => {
      boldness.value = value;
    };

    const handleButtonTextColorChange = (value: string) => {
      buttonTextColor.value = value;
    };

    const handleHeaderBackgroundColorChange = (value: string) => {
      headerBackgroundColor.value = value;
    };

    const handleButtonBackgroundColorChange = (value: string) => {
      headerButtonBackgroundColor.value = value;
    };

    const handleHeaderTextColorChange = (value: string) => {
      headerTextColor.value = value;
    };

    const handleFontSizeChange = (value: string) => {
      fontSize.value = value;
    };

    const handleTopHeaderTextChange = (value: string) => {
      topheaderText.value = value;
    };

    const handlePopupModalVisibilityChange = (value: boolean) => {
      popupModalVisible.value = value;
    };

    const handlePopupModalFontChange = (value: string) => {
      popupModalfontFamily.value = value;
    };

    const handlePopupModalBoldnessChange = (value: string) => {
      popupModalBoldness.value = value;
    };

    const handlePopupModalFontSizeChange = (value: string) => {
      popupModalfontSize.value = value;
    };

    const handlePopupModalLeftOrientationChange = (value: boolean) => {
      popupModalLeftOrientation.value = value;
    };

    const handlePopupModalButtonTextColorChange = (value: string) => {
      popupModalButtonTextColor.value = value;
    };

    const handlePopupModalButtonTextChange = (value: string) => {
      popupModalButtonText.value = value;
    };

    const handlePopupModalBodyTextChange = (value: string) => {
      popupModalBodyText.value = value;
    };

    const handlePopupModalExpertsDirectoryUrlChange = (value: string) => {
      popupModalExpertsDirectoryUrl.value = value;
    };

    const handlePopupModalButtonBackgroundColorChange = (value: string) => {
      popupModalButtonBackgroundColor.value = value;
    };

    const handlePopupModalTextColorChanged = (value: string) => {
      popupModalTextColor.value = value;
    };

    const handlePopupModalBannerBackgroundColorChange = (value: string) => {
      popupModalBannerBackgroundColor.value = value;
    };

    const handlePopupModalMobileVisibilityChange = (value: boolean) => {
      popupModalMobileVisibility.value = value;
    };

    const popupIsMobileVisibile = (value: boolean | undefined) => {
      return value !== undefined ? !value : true;
    };

    const updateTopHeaderBarWidgetData = (widgetData: WidgetConfig) => {
      topheaderText.value = widgetData.bodyText || "Booking System";
      topHeaderBarVisible.value = widgetData?.headerBar ?? true;
      fontFamily.value = widgetData.fontFamily || "Lato";
      fontSize.value = widgetData.fontSize || "14";
      boldness.value = widgetData.bold || "400";
      buttonTextColor.value = widgetData.buttonTextColor || "#121111";
      headerBackgroundColor.value = widgetData.backgroundColor || "#121111";
      headerButtonBackgroundColor.value = widgetData.buttonColor || "#FFFFFF";
      headerTextColor.value = widgetData.textColor || "#FFFFFF";
      buttonText.value = widgetData.buttonText || "Button";
      topHeaderExpertsDirectoryUrl.value = widgetData.expertsDirectoryUrl || "";
    };

    const updatePopupModalWidgetData = (widgetData: WidgetConfig) => {
      popupModalVisible.value = widgetData.popUp ?? true;
      popupModalfontFamily.value = widgetData.fontFamily || "Lato";
      popupModalfontSize.value = widgetData.fontSize || "14";
      popupModalBoldness.value = widgetData.bold || "400";
      popupModalButtonTextColor.value = widgetData.buttonTextColor || "#121111";
      popupModalButtonText.value = widgetData.buttonText || "";
      popupModalBodyText.value = widgetData.bodyText || "";
      popupModalExpertsDirectoryUrl.value =
        widgetData.expertsDirectoryUrl || "";
      popupModalLeftOrientation.value =
        widgetData.popUpOrientationLeft || false;
      popupModalButtonBackgroundColor.value =
        widgetData.buttonColor || "#121111";
      popupModalTextColor.value = widgetData.textColor || "#FFFFFF";
      popupModalBannerBackgroundColor.value =
        widgetData.backgroundColor || "#121111";
      popupModalMobileVisibility.value = popupIsMobileVisibile(
        widgetData.mobileVisibility
      );
    };

    const handleUpdateWidget = () => {
      updateWidgetData();
    };

    const prepareTopHeaderBarPayload = (widgetParams: WidgetConfig[]) => {
      widgetParams.push({
        id: topHeaderBarId.value,
        programId: userStore.selectedProgram?.id,
        headerBar: topHeaderBarVisible.value,
        bodyText: topheaderText.value,
        buttonText: buttonText.value,
        buttonTextColor: buttonTextColor.value,
        buttonColor: headerButtonBackgroundColor.value,
        style: "top header bar",
        fontFamily: fontFamily.value,
        fontSize: fontSize.value,
        textColor: headerTextColor.value,
        backgroundColor: headerBackgroundColor.value,
        bold: boldness.value,
        expertsDirectoryUrl: checkUrlProtocol(topHeaderExpertsDirectoryUrl),
      });
    };

    const preparePopupModalPayload = (widgetParams: WidgetConfig[]) => {
      widgetParams.push({
        id: popupModalId.value,
        programId: userStore.selectedProgram?.id,
        popUp: popupModalVisible.value,
        bodyText: popupModalBodyText.value,
        style: "popup modal",
        fontFamily: popupModalfontFamily.value,
        fontSize: popupModalfontSize.value,
        textColor: popupModalTextColor.value,
        buttonTextColor: popupModalButtonTextColor.value,
        buttonColor: popupModalButtonBackgroundColor.value,
        backgroundColor: popupModalBannerBackgroundColor.value,
        buttonText: popupModalButtonText.value,
        bold: popupModalBoldness.value,
        expertsDirectoryUrl: checkUrlProtocol(popupModalExpertsDirectoryUrl),
        popUpOrientationLeft: popupModalLeftOrientation.value,
        mobileVisibility: !popupModalMobileVisibility.value,
      });
    };

    const updateWidgetData = () => {
      let widgetParams: WidgetConfig[] = [];

      if (topHeaderBarVisible.value || topHeaderBarId.value) {
        prepareTopHeaderBarPayload(widgetParams);
      }

      if (popupModalVisible.value || popupModalId.value) {
        preparePopupModalPayload(widgetParams);
      }

      updateWidget({
        input: {
          widgetParams,
        },
      });

      updateWidgetDone((response) => {
        if (response) {
          if (response.data.updateWidget.errors.length > 0) {
            handleError(response.data.updateWidget.errors.join(", "));
          } else {
            hideEditControls();
            refetchWidgetData();
          }
        }
      });

      updateWidgetError((error) => {
        if (error) {
          handleError(error.message);
        }
      });
    };

    const handleError = (message: string) => {
      errorMessage.value = message;
      setTimeout(() => {
        errorMessage.value = "";
      }, 4000);
    };

    const hideEditControls = () => {
      showEditControls.value = false;
      emit("hideDirectory", false);
    };

    const handleResize = () => {
      const directoryPreview = document.querySelector(
        "#directoryPreview"
      ) as HTMLElement;
      const height = directoryPreview?.clientHeight;
      widgetPreviewHeight.value = height;
    };

    widgetFetchResult((result) => {
      handleResize();
      if (result) {
        if (result?.data?.getProgramWidgetDetail) {
          const widgetDetails = result?.data?.getProgramWidgetDetail || [];
          handleWidgetData(widgetDetails);
        }
      }
    });

    widgetFetchError((error) => {
      if (error) {
        handleResize();
        if (error.message.includes("No widget settings found.")) {
          topHeaderBarVisible.value = false;
          popupModalVisible.value = false;
        } else {
          handleError(error.message);
        }
      }
    });

    const handleWidgetData = (widgetDetails: WidgetPayload[]) => {
      for (const detail of widgetDetails) {
        const widgetData = detail?.widgetCosmetic;
        if (widgetData) {
          if (widgetData.headerBar === true || widgetData.headerBar === false) {
            topHeaderBarId.value = detail?.id;
            updateTopHeaderBarWidgetData(widgetData);
          } else if (widgetData.popUp === true || widgetData.popUp === false) {
            popupModalId.value = detail?.id;
            popupModalWidgetData.value = widgetData;
            updatePopupModalWidgetData(widgetData);
          }
        }
      }

      if (!topHeaderBarId.value) {
        topHeaderBarVisible.value = false;
      }
      if (!popupModalId.value) {
        popupModalVisible.value = false;
      }
    };

    onMounted(() => {
      setTimeout(() => {
        handleResize();
      }, 300);

      window.addEventListener("resize", () => {
        handleResize();
      });
    });

    return {
      cancelEditWidget,
      widgetData,
      topHeaderBarWidgetData,
      popupModalWidgetData,
      handleEditWidget,
      popupModalId,
      topHeaderBarId,
      showEditControls,
      showCodeModal,
      handleUpdateWidget,
      EDIT_ICON,
      errorMessage,
      updateWidgetLoading,
      widgetPreviewHeight,
      topHeaderBarVisible,
      popupModalVisible,
      fontFamily,
      fontSize,
      boldness,
      buttonTextColor,
      headerBackgroundColor,
      headerButtonBackgroundColor,
      headerTextColor,
      topheaderText,
      buttonText,
      topHeaderExpertsDirectoryUrl,
      popupModalfontFamily,
      popupModalfontSize,
      popupModalBoldness,
      popupModalButtonTextColor,
      popupModalExpertsDirectoryUrl,
      popupModalButtonText,
      popupModalBodyText,
      popupModalLeftOrientation,
      popupModalButtonBackgroundColor,
      popupModalTextColor,
      popupModalBannerBackgroundColor,
      popupModalMobileVisibility,
      handleTopHeaderExpertsDirectoryUrlChange,
      handleTopHeaderBarVisibilityChange,
      handleButtonTextChange,
      handleFontChange,
      handleBoldnessChange,
      handleButtonTextColorChange,
      handleHeaderBackgroundColorChange,
      handleButtonBackgroundColorChange,
      handleHeaderTextColorChange,
      handleFontSizeChange,
      handlePopupModalVisibilityChange,
      handlePopupModalFontChange,
      handlePopupModalBoldnessChange,
      handlePopupModalFontSizeChange,
      handlePopupModalLeftOrientationChange,
      handlePopupModalButtonTextColorChange,
      handlePopupModalButtonTextChange,
      handlePopupModalBodyTextChange,
      handlePopupModalExpertsDirectoryUrlChange,
      handlePopupModalButtonBackgroundColorChange,
      handlePopupModalTextColorChanged,
      handleTopHeaderTextChange,
      handlePopupModalBannerBackgroundColorChange,
      handlePopupModalMobileVisibilityChange,
    };
  },
});
</script>
