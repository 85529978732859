<template>
  <div
    class="flex flex-col gap-4 justify-between pl-4 pr-4 pb-4 w-full lg:pr-20 lg:w-[40vw] max-w-[500px]"
  >
    <div class="flex flex-col order-2 sm:order-1">
      <div class="flex flex-col gap-8 justify-start">
        <div class="flex flex-col">
          <span
            class="text-gray-900 font-sans text-xl font-bold leading-7 text-left"
          >
            Meeting Details
          </span>
        </div>
        <!-- name -->
        <div class="flex flex-col">
          <span
            class="text-gray-800 text-base font-bold leading-6 font-inter opacity-60 text-left"
          >
            Name
          </span>
          <span
            class="text-gray-700 text-base font-normal leading-6 font-inter text-left"
          >
            {{ attendeeStore.bookMeetingFirstName }}
            {{ attendeeStore.bookMeetingLastName }}
          </span>
        </div>
        <!-- email -->
        <div class="flex flex-col">
          <span
            class="text-gray-800 text-base font-bold leading-6 font-inter opacity-60 text-left"
          >
            Email
          </span>
          <span
            class="text-gray-700 text-base font-normal leading-6 font-inter text-left"
          >
            {{ attendeeStore.bookMeetingEmail }}
          </span>
        </div>
        <!-- linkedin url -->
        <div class="flex flex-col">
          <span
            class="text-gray-800 text-base font-bold leading-6 font-inter opacity-60 text-left"
          >
            Linkedin URL
          </span>
          <span
            class="text-gray-700 text-base font-normal leading-6 font-inter text-left"
          >
            {{ attendeeStore.bookMeetingLinkedinUrl }}
          </span>
        </div>
        <!-- title -->
        <div class="flex flex-col">
          <span
            class="text-gray-800 text-base font-bold leading-6 font-inter opacity-60 text-left"
          >
            Title
          </span>
          <span
            class="text-gray-700 text-base font-normal leading-6 font-inter text-left"
          >
            {{ attendeeStore.bookMeetingTitle }}
          </span>
        </div>
        <!-- company -->
        <div class="flex flex-col">
          <span
            class="text-gray-800 text-base font-bold leading-6 font-inter opacity-60 text-left"
          >
            Company
          </span>
          <span
            class="text-gray-700 text-base font-normal leading-6 font-inter text-left"
          >
            {{ attendeeStore.bookMeetingCompany }}
          </span>
        </div>
        <!-- note -->
        <div class="flex flex-col gap-1">
          <span
            class="text-gray-800 text-base font-bold leading-6 font-inter opacity-60 text-left"
          >
            Note
          </span>
          <span
            class="text-gray-700 text-base rounded px-2 py-1 font-normal leading-6 font-inter max-h-[100px] overflow-y-auto text-left"
          >
            {{ attendeeStore.bookMeetingNotes }}
          </span>
        </div>
      </div>
    </div>
    <div class="flex flex-col justify-end items-start order-1 sm:order-2">
      <div class="flex flex-wrap gap-4">
        <BaseButton
          type="button"
          :disabled="isCreatingBooking"
          @click="handleConfirmMeeting"
          class="text-white bg-primary-purple max-w-max text-sm px-4 py-2 w-full rounded-md border border-gray-200 font-sans text-base font-normal leading-6 whitespace-nowrap"
        >
          <span v-if="!isCreatingBooking">Confirm Meeting</span>
          <span v-else>Confirming...</span>
        </BaseButton>
        <BaseButton
          type="button"
          @click="handleStartOver"
          class="text-gray-700 bg-gray-300 max-w-max text-sm px-4 py-2 w-full rounded-md border border-gray-200 font-sans text-base font-normal leading-6 whitespace-nowrap"
        >
          Start Over
        </BaseButton>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { useAttendeeStore } from "@/stores/attendee";
import { defineComponent, getCurrentInstance, onMounted } from "vue";
import BaseButton from "@/components/shared/BaseButton.vue";
import { useRoute } from "vue-router";
import { ApolloError, gql } from "@apollo/client/core";
import { useMutation } from "@vue/apollo-composable";
import { getCurrentTimeZoneInfo } from "@/utils/dateAndTimeUtils";
import { useRouter } from "vue-router";
import { PostHog } from "posthog-js";

const CREATE_BOOKING = gql`
  mutation createBooking($input: CreateBookingInput!) {
    createBooking(input: $input) {
      success
      errors
      attendee {
        passcode {
          value
        }
      }
    }
  }
`;

export default defineComponent({
  components: {
    BaseButton,
  },
  props: {
    errorMessage: {
      type: Function,
      required: false,
    },
  },
  setup(props) {
    const attendeeStore = useAttendeeStore();
    const route = useRoute();
    const router = useRouter();
    const posthog = (getCurrentInstance()?.proxy as { $posthog: PostHog })
      ?.$posthog;

    const {
      mutate: createBooking,
      loading: isCreatingBooking,
      onDone: createBookingDone,
      onError: createBookingError,
    } = useMutation(CREATE_BOOKING);

    const getDay = (dateStr: string) => {
      const date = new Date(dateStr);
      const options: Intl.DateTimeFormatOptions = { weekday: "long" };
      const dayName = date.toLocaleDateString("en-US", options);
      return dayName;
    };

    const getEndTime = (timeStr: string) => {
      // Split the time string into hours and minutes
      const [hours, minutes] = timeStr.split(":").map(Number);

      // Create a Date object with the given time, defaulting to today's date
      const date = new Date();
      date.setHours(hours);
      date.setMinutes(minutes + 30); // Add 30 minutes

      // Format the new time back into HH:mm format
      const newHours = String(date.getHours()).padStart(2, "0");
      const newMinutes = String(date.getMinutes()).padStart(2, "0");

      return `${newHours}:${newMinutes}`;
    };

    const handleConfirmMeeting = () => {
      // bookMeeting();  call this function to create the meeting
      if (posthog) {
        try {
          identifyPosthog();
          capturePosthogEvent();
        } catch (error) {
          props.errorMessage?.("Error identifying posthog: ", error);
        }
        bookMeeting();
      } else {
        props.errorMessage?.("PostHog is not initialized");
      }
    };

    const identifyPosthog = () => {
      posthog.identify(attendeeStore.bookMeetingEmail, {
        attendee_email: attendeeStore.bookMeetingEmail,
        attendee_name: `${attendeeStore.bookMeetingFirstName} ${attendeeStore.bookMeetingLastName}`,
        attendee_last: "",
        expert_user: false,
        company_user: false,
        attendee_user: true,
        attendee_company: attendeeStore.bookMeetingCompany,
        attendee_title: attendeeStore.bookMeetingTitle,
      });
    };

    const capturePosthogEvent = () => {
      posthog.capture("Expert_Meeting_Booked", {
        attendee_email: attendeeStore.bookMeetingEmail,
        attendee_name: `${attendeeStore.bookMeetingFirstName} ${attendeeStore.bookMeetingLastName}`,
        attendee_company: attendeeStore.bookMeetingCompany,
        attendee_title: attendeeStore.bookMeetingTitle,
        expert_uuid: route.query.expert as string,
        program_uuid: route.query.program as string,
        date: route.query.date as string,
        start_time: route.query.startTime as string,
        timezone: getCurrentTimeZoneInfo().timeZone,
      });
    };

    const bookMeeting = () => {
      const day = getDay(route.query.date as string);
      const date = route.query.date as string;
      const start = route.query.startTime as string;
      const end = getEndTime(start);
      const timeZoneInfo = getCurrentTimeZoneInfo();
      const timezone = timeZoneInfo.timeZone;

      createBooking({
        input: {
          bookingParams: {
            eventId: route.query.event as string,
            day: day,
            date: date,
            start: start,
            end: end,
            timezone: timezone,
            attendee: {
              firstName: attendeeStore.bookMeetingFirstName,
              lastName: attendeeStore.bookMeetingLastName,
              email: attendeeStore.bookMeetingEmail,
              linkedinUrl: attendeeStore.bookMeetingLinkedinUrl,
              title: attendeeStore.bookMeetingTitle,
              company: attendeeStore.bookMeetingCompany,
              topic: attendeeStore.bookMeetingNotes,
            },
          },
          expertUuid: route.query.expert as string,
          programUuid: route.query.program as string,
        },
      });

      createBookingDone((result) => {
        if (result) {
          if (result.data.createBooking.success) {
            console.log("createBookingDone: ", result);
            handleBookingSuccess(
              result.data.createBooking.attendee.passcode.value
            );
          } else {
            props.errorMessage?.(result?.data?.createBooking?.errors || "");
          }
        }
      });

      createBookingError((error: ApolloError) => {
        if (error) {
          props.errorMessage?.(error.message || "");
        }
      });
    };

    const handleBookingSuccess = (passcode: string) => {
      const newQuery = { ...route.query };

      delete newQuery.expert;
      delete newQuery.program;

      newQuery.meeting_code = passcode;

      router.replace({ query: newQuery });

      attendeeStore.clearStore();
    };

    const handleStartOver = () => {
      attendeeStore.clearStore();
      const newQuery = { ...route.query };
      delete newQuery.startTime;
      delete newQuery.date;
      router.replace({ query: newQuery });
    };

    const scrollToTop = () => {
      const c = document.documentElement.scrollTop || document.body.scrollTop;
      if (c > 0) {
        window.requestAnimationFrame(scrollToTop);
        window.scrollTo(0, c - c / 8);
      }
    };

    onMounted(() => {
      scrollToTop();
    });

    return {
      attendeeStore,
      handleConfirmMeeting,
      isCreatingBooking,
      handleStartOver,
    };
  },
});
</script>
